import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TemporaryAcceptanceActionTypes } from "./TemporaryAcceptance.type";

export function* fetchAllTemporaryAcceptances(action: any): any {
  try {
    let query = "";

    if (action.payload) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/temporary-acceptance${query}`
    );
    yield put({
      type: TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTemporaryAcceptances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/temporary-acceptance/${action.payload}`
    );
    yield put({
      type: TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTemporaryAcceptances() {
  yield takeLatest(
    TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE,
    fetchAllTemporaryAcceptances
  );
}

export function* watcherFetchOneTemporaryAcceptances() {
  yield takeLatest(
    TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE,
    fetchOneTemporaryAcceptances
  );
}
