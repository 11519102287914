import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureWeeklyScheduleActionTypes } from "./AllureWeeklySchedule.type";

export function* fetchAllAllureWeeklySchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-weekly-schedule?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureWeeklySchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-weekly-schedule/${action.payload}`
    );
    yield put({
      type: AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureWeeklySchedules() {
  yield takeLatest(AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE, fetchAllAllureWeeklySchedules);
}

export function* watcherFetchOneAllureWeeklySchedules() {
  yield takeLatest(AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE, fetchOneAllureWeeklySchedules);
}
