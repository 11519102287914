import { AllurePaymentSummaryStateTypes, AllurePaymentSummaryActionTypes } from "./AllurePaymentSummary.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AllurePaymentSummaryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AllurePaymentSummaryReducer = (
  state: AllurePaymentSummaryStateTypes = INITIAL_STATE,
  action: any
): AllurePaymentSummaryStateTypes => {
  switch (action.type) {
    case AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AllurePaymentSummaryReducer;
