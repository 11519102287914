import { AllureResourceApprovalStateTypes, AllureResourceApprovalActionTypes } from "./AllureResourceApproval.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AllureResourceApprovalStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AllureResourceApprovalReducer = (
  state: AllureResourceApprovalStateTypes = INITIAL_STATE,
  action: any
): AllureResourceApprovalStateTypes => {
  switch (action.type) {
    case AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AllureResourceApprovalReducer;
