import { ReportMaterialItem } from "../FinanceDailyReport/FinanceDailyReport.type";
import { GoodReceivedItem } from "../GoodReceived/GoodReceived.type";
import { MaterialRequisitionItem } from "../MaterialRequisition/MaterialRequisition.type";
import { ApiCallState } from "../Utils";

export type MaterialConsumptionReport= {
  id: number;
  project_id:number;
  date:string;
  material_consumption_report_items:MaterialConsumptionReportItem[];
  material_consumption_report_statuses:MaterialConsumptionReportStatus[];
  user_id:number;
  createdAt: any;
  updatedAt: any;
};

export type MaterialConsumptionReportStatus = {
    material_consumption_report_id:number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
  };

export type MaterialConsumptionReportItem ={
    id: number;
    material_consumption_report_id:number;
    report_material_item_id: number;
    material_requisition_item_id:number;
    good_received_item_id: number;
    beginning_balance: number;
    requested_date: string;
    delivered_date: string;
    remark:string;
    material_requisition_item:MaterialRequisitionItem;
    report_material_item: ReportMaterialItem;
    good_received_item: GoodReceivedItem;
}

export type MaterialConsumptionReportStateTypes = {
  fetchAll: ApiCallState<MaterialConsumptionReport[]>;
  fetchOne: ApiCallState<MaterialConsumptionReport | {}>;
};

export const MaterialConsumptionReportActionTypes = {
  FETCH_ALL_MATERIAL_CONSUMPTION_REPORT: "FETCH_ALL_MATERIAL_CONSUMPTION_REPORT",
  FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_RESET: "FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_RESET",
  FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_FAILURE: "FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_FAILURE",
  FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_SUCCESS: "FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_SUCCESS",

  FETCH_ONE_MATERIAL_CONSUMPTION_REPORT: "FETCH_ONE_MATERIAL_CONSUMPTION_REPORT",
  FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_RESET: "FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_RESET",
  FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_FAILURE: "FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_FAILURE",
  FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_SUCCESS: "FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_SUCCESS",
};
