import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterScheduleManpowerActionTypes } from "./MasterScheduleManpower.type";

export function* fetchAllMasterScheduleManpowers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/master-schedule-manpower?master_schedule_updated_id=${action.payload?.master_schedule_updated_id}`);
    yield put({
      type: MasterScheduleManpowerActionTypes.FETCH_ALL_MASTER_SCHEDULE_MANPOWER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleManpowerActionTypes.FETCH_ALL_MASTER_SCHEDULE_MANPOWER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterScheduleManpowers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-manpower/${action.payload}`
    );
    yield put({
      type: MasterScheduleManpowerActionTypes.FETCH_ONE_MASTER_SCHEDULE_MANPOWER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleManpowerActionTypes.FETCH_ONE_MASTER_SCHEDULE_MANPOWER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterScheduleManpowers() {
  yield takeLatest(MasterScheduleManpowerActionTypes.FETCH_ALL_MASTER_SCHEDULE_MANPOWER, fetchAllMasterScheduleManpowers);
}

export function* watcherFetchOneMasterScheduleManpowers() {
  yield takeLatest(MasterScheduleManpowerActionTypes.FETCH_ONE_MASTER_SCHEDULE_MANPOWER, fetchOneMasterScheduleManpowers);
}
