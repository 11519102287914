import { ApiCallState } from "../Utils";

export type EquipmentMasterList = {
  id: number;
  user_id: number;

  location: string;

  equipment_type: string;
  plate_number: string;
  make: string;
  model: string;
  engine_number: string;
  serial_chassis_number: string;
  hr_power: string;
  capacity: string;
  manufacture_year: string;
  purchase_year: string;
  purchase_price: number;
  purchase_currency: string;
  depreciation_percent: number;
  status: string;
  driver_name: string;
  tel_phone_number: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EquipmentMasterListStateTypes = {
  fetchAll: ApiCallState<EquipmentMasterList[]>;
  fetchOne: ApiCallState<EquipmentMasterList | {}>;
};

export const EquipmentMasterListActionTypes = {
  FETCH_ALL_EQUIPMENT_MASTER_LIST: "FETCH_ALL_EQUIPMENT_MASTER_LIST",
  FETCH_ALL_EQUIPMENT_MASTER_LIST_RESET:
    "FETCH_ALL_EQUIPMENT_MASTER_LIST_RESET",
  FETCH_ALL_EQUIPMENT_MASTER_LIST_FAILURE:
    "FETCH_ALL_EQUIPMENT_MASTER_LIST_FAILURE",
  FETCH_ALL_EQUIPMENT_MASTER_LIST_SUCCESS:
    "FETCH_ALL_EQUIPMENT_MASTER_LIST_SUCCESS",

  FETCH_ONE_EQUIPMENT_MASTER_LIST: "FETCH_ONE_EQUIPMENT_MASTER_LIST",
  FETCH_ONE_EQUIPMENT_MASTER_LIST_RESET:
    "FETCH_ONE_EQUIPMENT_MASTER_LIST_RESET",
  FETCH_ONE_EQUIPMENT_MASTER_LIST_FAILURE:
    "FETCH_ONE_EQUIPMENT_MASTER_LIST_FAILURE",
  FETCH_ONE_EQUIPMENT_MASTER_LIST_SUCCESS:
    "FETCH_ONE_EQUIPMENT_MASTER_LIST_SUCCESS",
};
