import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SiteVisitScheduleActionTypes } from "./SiteVisitSchedule.type";

export function* fetchAllSiteVisitSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/site-visit-schedule`);
    yield put({
      type: SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSiteVisitSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/site-visit-schedule/${action.payload}`
    );
    yield put({
      type: SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSiteVisitSchedules() {
  yield takeLatest(SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE, fetchAllSiteVisitSchedules);
}

export function* watcherFetchOneSiteVisitSchedules() {
  yield takeLatest(SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE, fetchOneSiteVisitSchedules);
}
