import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllBoq,
  watcherFetchBoqTotal,
  watcherFetchDetailBoq,
  watcherFetchOneBoq,
  watcherFetchPagedBoq,
  watcherFetchSheetNames,
  watcherFetchUnitBoq,
} from "./Boq/Boq.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllDepartments } from "./Department/Department.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchAllLabour } from "./Labour/Labour.saga";
import { watcherFetchAllLabourPlans } from "./LabourPlan/LabourPlan.saga";
import { watcherFetchAllLabourUsage } from "./LabourUsage/LabourUsage.saga";
import { watcherFetchMaterials } from "./Material/Material.saga";
import {
  watcherFetchMaterialInventories,
  watcherFetchMaterialInventoriesAnalysis,
} from "./MaterialInventory/MaterialInventory.saga";
import { watcherFetchMaterialTransfer } from "./MaterialTransfer/MaterialTransfer.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
  watcherFetchAllProjectsDetail,
  watcherFetchAllProjectReport,
} from "./Project/Project.saga";
import { watcherFetchAllProjectStaffs } from "./ProjectStaff/ProjectStaff.saga";
import {
  watcherFetchAllStaffs,
  watcherFetchOneStaffs,
  watcherFetchAllStaffDetails,
  watcherFetchAllStaffsAlongTermination,
} from "./Staff/Staff.saga";
import { watcherFetchAllStaffPlans } from "./StaffPlan/StaffPlan.saga";
import {
  watcherFetchAllSubContracts,
  watcherFetchOneSubContracts,
} from "./SubContract/SubContract.saga";

import { watcherFetchSupplies } from "./Supplier/Supplier.saga";
import { watcherFetchAllUsage } from "./Usage/Usage.saga";
import { watcherFetchAllManpowerOvertime } from "./ManpowerOvertime/ManpowerOvertime.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import { watcherFetchAllEmployeeExtras } from "./EmployeeExtra/EmployeeExtra.saga";
import {
  watcherFetchAllUser,
  watcherFetchOneUser,
  watcherFetchLoggedInUser,
} from "./User/User.saga";
import { watcherFetchAllAttendances } from "./Attendance/Attendance.saga";
import {
  watcherFetchAllEmployeeRequests,
  watcherFetchEmployeeLeaveRequestState,
  watcherFetchEmployeeHealthBenefitRequestState,
} from "./EmployeeRequest/EmployeeRequest.saga";
import {
  watcherFetchAllMaterialRequisitions,
  watcherFetchOneMaterialRequisitions,
} from "./MaterialRequisition/MaterialRequisition.saga";
import {
  watcherFetchAllPurchaseRequisitions,
  watcherFetchOnePurchaseRequisitions,
} from "./PurchaseRequisition/PurchaseRequisition.saga";
import {
  watcherFetchAllPurchaseOrders,
  watcherFetchOnePurchaseOrders,
} from "./PurchaseOrder/PurchaseOrder.saga";
import { watcherFetchAllPurchaseBillings } from "./PurchaseBilling/PurchaseBilling.saga";
import { watcherFetchAllGoodReceived } from "./GoodReceived/GoodReceived.saga";
import { watcherFetchAllRenters } from "./Renter/Renter.saga";
import { watcherFetchAllGoodOut } from "./GoodOut/GoodOut.saga";
import { watcherFetchAllGoodReturn } from "./GoodReturn/GoodReturn.saga";
import { watcherFetchEmployeeHistory } from "./EmployeeHistory/EmployeeHistory.saga";
import { watcherFetchAllDocumentWorkOrders } from "./DocumentWorkOrder/DocumentWorkOrder.saga";
import { watcherFetchAllHRPolicy } from "./HRPolicy/HRPolicy.saga";
import { watcherFetchAllAbsences } from "./Absence/Absence.saga";
import { watcherFetchAllEmployeeDocuments } from "./EmployeeDocument/EmployeeDocument.saga";
import {
  watcherFetchAllLabourPayrolls,
  watcherFetchAllStaffPayrolls,
  watcherFetchAllPayrolls,
  watcherFetchAllPayrollReport,
  watcherFetchAllPayrollStaffHistory,
} from "./Payroll/Payroll.saga";
import { watcherFetchAllClients } from "./Client/Client.saga";
import { watcherFetchAllAllowances } from "./Allowance/Allowance.saga";
import { watcherFetchAllAllowancePolicy } from "./AllowancePolicy/AllowancePolicy.saga";
import {
  watcherFetchAllStandard,
  watcherFetchOneStandard,
} from "./Standard/Standard.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";
import {
  watcherFetchAllBoqStandard,
  watcherFetchOneBoqStandard,
} from "./BoqStandard/BoqStandard.saga";
import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";

import {
  watcherFetchAllPaymentCertificateRoads,
  watcherFetchOnePaymentCertificateRoads,
} from "./PaymentCertificateRoad/PaymentCertificateRoad.saga";

import { watcherFetchAllCastings } from "./Casting/Casting.saga";
import {
  watcherFetchAllMaterialRequestApprovals,
  watcherFetchOneMaterialRequestApprovals,
} from "./MaterialRequestApproval/MaterialRequestApproval.saga";
import {
  watcherFetchAllTestResult,
  watcherFetchOneTestResult,
} from "./TestResult/TestResult.saga";
import {
  watcherFetchAllTestRequest,
  watcherFetchOneTestRequest,
} from "./TestRequest/TestRequest.saga";

import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import { watcherFetchAllEmployeeReview } from "./EmployeeReview/EmployeeReview.saga";
import { watcherFetchAllBonuses } from "./Bonus/Bonus.saga";

import {
  watcherFetchAllVacancies,
  watcherFetchVacanciesByAttributes,
  watcherFetchVacanciesByJobId,
} from "./Vacancy/Vacancy.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllSharedDocuments } from "./SharedDocument/SharedDocument.saga";

import {
  watcherFetchAllBankAccounts,
  watcherFetchOneBankAccounts,
} from "./BankAccount/BankAccount.saga";

import { watcherFetchAllAccountTransactions } from "./AccountTransaction/AccountTransaction.saga";

import {
  watcherFetchAllTestEvaluations,
  watcherFetchOneTestEvaluations,
} from "./TestEvaluation/TestEvaluation.saga";

import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";

import { watcherFetchAllEstimatedBuildingCosts } from "./EstimatedBuildingCost/EstimatedBuildingCost.saga";
import {
  watcherFetchAllProformas,
  watcherFetchOneProformas,
} from "./Proforma/Proforma.saga";
import { watcherFetchAllCurrencies } from "./Currency/Currency.saga";
import {
  watcherFetchAllProcurementPlans,
  watcherFetchOneProcurementPlans,
} from "./ProcurementPlan/ProcurementPlan.saga";

import {
  watcherFetchAllMonthSubcontractorSchedules,
  watcherFetchOneMonthSubcontractorSchedules,
} from "./MonthSubcontractorSchedule/MonthSubcontractorSchedule.saga";

import {
  watcherFetchAllEquipmentDemobiliations,
  watcherFetchOneEquipmentDemobiliations,
} from "./EquipmentDemobiliation/EquipmentDemobiliation.saga";

import {
  watcherFetchAllBudgetRequests,
  watcherFetchOneBudgetRequests,
} from "./BudgetRequest/BudgetRequest.saga";

import {
  watcherFetchAllBudgetUtilizations,
  watcherFetchOneBudgetUtilizations,
} from "./BudgetUtilization/BudgetUtilization.saga";

import {
  watcherFetchAllFinanceDailyReports,
  watcherFetchFinanceDailyReports,
} from "./FinanceDailyReport/FinanceDailyReport.saga";

import { watcherFetchAllSupplyFixContracts } from "./SupplyFixContract/SupplyFixContract.saga";
import { watcherFetchAllSupplyFixWorkProgress } from "./SupplyFixWorkProgress/SupplyFixWorkProgress.saga";

import {
  watcherFetchAllStores,
  watcherFetchOneStores,
} from "./Store/Store.saga";
import { watcherFetchAllSupportLetters } from "./SupportLetter/SupportLetter.saga";
import {
  watcherFetchAllProformaComparisons,
  watcherFetchOneProformaComparisons,
} from "./ProformaComparison/ProformaComparison.saga";

import { watcherFetchAllWeeklyProgressSummarys } from "./WeeklyProgressSummary/WeeklyProgressSummary.saga";

import {
  watcherFetchAllCastingTestReports,
  watcherFetchOneCastingTestReports,
} from "./CastingTestReport/CastingTestReport.saga";

import { watcherFetchAllFuelRequestVouchers } from "./FuelRequestVoucher/FuelRequestVoucher.saga";
import { watcherFetchAllFuelRecievingVouchers } from "./FuelRecievingVoucher/FuelRecievingVoucher.saga";
import { watcherFetchAllFuelIssueVouchers } from "./FuelIssueVoucher/FuelIssueVoucher.saga";

import {
  watcherFetchAllUploadDocuments,
  watcherFetchOneUploadDocuments,
  watcherFetchUploadDocumentReferenceNumber,
} from "./UploadDocument/UploadDocument.saga";

import {
  watcherFetchAllAdditionBoqs,
  watcherFetchOneAdditionBoqs,
} from "./AdditionBoq/AdditionBoq.saga";

import {
  watcherFetchAllOmissionBoqs,
  watcherFetchOneOmissionBoqs,
} from "./OmissionBoq/OmissionBoq.saga";

import {
  watcherFetchAllSupplementaryBoqs,
  watcherFetchOneSupplementaryBoqs,
} from "./SupplementaryBoq/SupplementaryBoq.saga";

import {
  watcherFetchAllMasterScheduleUpdateds,
  watcherFetchOneMasterScheduleUpdateds,
} from "./MasterScheduleUpdated/MasterScheduleUpdated.saga";

import {
  watcherFetchAllBillSummarys,
  watcherFetchOneBillSummarys,
} from "./BillSummary/BillSummary.saga";

import {
  watcherFetchAllSummarys,
  watcherFetchOneSummarys,
} from "./Summary/Summary.saga";

import {
  watcherFetchAllSubContractRegistrys,
  watcherFetchOneSubContractRegistrys,
} from "./SubContractRegistry/SubContractRegistry.saga";

import {
  watcherFetchAllPlanSummaryOfNextDays,
  watcherFetchOnePlanSummaryOfNextDays,
} from "./PlanSummaryOfNextDay/PlanSummaryOfNextDay.saga";

import {
  watcherFetchAllTenders,
  watcherFetchOneTenders,
} from "./Tender/Tender.saga";

import {
  watcherFetchAllTenderSubmissionChecklists,
  watcherFetchOneTenderSubmissionChecklists,
} from "./TenderSubmissionChecklist/TenderSubmissionChecklist.saga";

import {
  watcherFetchAllTenderDocumentss,
  watcherFetchOneTenderDocumentss,
} from "./TenderDocuments/TenderDocuments.saga";

import {
  watcherFetchAllSiteHandovers,
  watcherFetchOneSiteHandovers,
} from "./SiteHandover/SiteHandover.saga";

import {
  watcherFetchAllAllureMonthlyPlans,
  watcherFetchOneAllureMonthlyPlans,
} from "./AllureMonthlyPlan/AllureMonthlyPlan.saga";

import {
  watcherFetchAllAllureWeeklyPlans,
  watcherFetchOneAllureWeeklyPlans,
} from "./AllureWeeklyPlan/AllureWeeklyPlan.saga";

import {
  watcherFetchAllResourceMobilizations,
  watcherFetchOneResourceMobilizations,
} from "./ResourceMobilization/ResourceMobilization.saga";

import {
  watcherFetchAllAllureEquipmentRequests,
  watcherFetchOneAllureEquipmentRequests,
} from "./AllureEquipmentRequest/AllureEquipmentRequest.saga";

import {
  watcherFetchAllAllureMaterialRequests,
  watcherFetchOneAllureMaterialRequests,
} from "./AllureMaterialRequest/AllureMaterialRequest.saga";

import {
  watcherFetchAllAllureResourceApprovals,
  watcherFetchOneAllureResourceApprovals,
} from "./AllureResourceApproval/AllureResourceApproval.saga";

import {
  watcherFetchAllAllureSupportingDocuments,
  watcherFetchOneAllureSupportingDocuments,
} from "./AllureSupportingDocument/AllureSupportingDocument.saga";

import {
  watcherFetchAllTenderOpenings,
  watcherFetchOneTenderOpenings,
} from "./TenderOpening/TenderOpening.saga";

import {
  watcherFetchAllContractualIssues,
  watcherFetchOneContractualIssues,
} from "./ContractualIssue/ContractualIssue.saga";

import {
  watcherFetchAllSubcontractorRequests,
  watcherFetchOneSubcontractorRequests,
} from "./SubcontractorRequest/SubcontractorRequest.saga";

import {
  watcherFetchAllMasterScheduleSummaryss,
  watcherFetchOneMasterScheduleSummaryss,
} from "./MasterScheduleSummarys/MasterScheduleSummarys.saga";

import {
  watcherFetchAllMasterScheduleCashflows,
  watcherFetchOneMasterScheduleCashflows,
} from "./MasterScheduleCashflow/MasterScheduleCashflow.saga";

import {
  watcherFetchAllMasterScheduleMaterialss,
  watcherFetchOneMasterScheduleMaterialss,
} from "./MasterScheduleMaterials/MasterScheduleMaterials.saga";

import {
  watcherFetchAllMasterScheduleMachinerys,
  watcherFetchOneMasterScheduleMachinerys,
} from "./MasterScheduleMachinery/MasterScheduleMachinery.saga";

import {
  watcherFetchAllMasterScheduleManpowers,
  watcherFetchOneMasterScheduleManpowers,
} from "./MasterScheduleManpower/MasterScheduleManpower.saga";

import {
  watcherFetchAllSiteVisitSchedules,
  watcherFetchOneSiteVisitSchedules,
} from "./SiteVisitSchedule/SiteVisitSchedule.saga";

import {
  watcherFetchAllSiteVisitChecklists,
  watcherFetchOneSiteVisitChecklists,
} from "./SiteVisitChecklist/SiteVisitChecklist.saga";

import {
  watcherFetchAllTemporaryAcceptances,
  watcherFetchOneTemporaryAcceptances,
} from "./TemporaryAcceptance/TemporaryAcceptance.saga";

import {
  watcherFetchAllAllureWeeklySchedules,
  watcherFetchOneAllureWeeklySchedules,
} from "./AllureWeeklySchedule/AllureWeeklySchedule.saga";

import {
  watcherFetchAllMaterialConsumptions,
  watcherFetchOneMaterialConsumptions,
} from "./MaterialConsumption/MaterialConsumption.saga";

import {
  watcherFetchAllIndirectCosts,
  watcherFetchOneIndirectCosts,
} from "./IndirectCost/IndirectCost.saga";

import {
  watcherFetchAllAllurePaymentSummarys,
  watcherFetchOneAllurePaymentSummarys,
} from "./AllurePaymentSummary/AllurePaymentSummary.saga";

import {
  watcherFetchAllAllureLabourUtilizations,
  watcherFetchOneAllureLabourUtilizations,
} from "./AllureLabourUtilization/AllureLabourUtilization.saga";

import {
  watcherFetchAllSubcontractorSelections,
  watcherFetchOneSubcontractorSelections,
} from "./SubcontractorSelection/SubcontractorSelection.saga";

import {
  watcherFetchAllSubContractPaymentUpdates,
  watcherFetchOneSubContractPaymentUpdates,
} from "./SubContractPaymentUpdate/SubContractPaymentUpdate.saga";

import {
  watcherFetchAllInventories,
  watcherFetchAllQueriedInventories,
  watcherFetchOneInventory,
} from "./Inventory/Inventory.saga";

import {
  watcherFetchAllComments,
  watcherFetchOneComments,
} from "./Comment/Comment.saga";

import { watcherFetchAllFurnitureMasterLists } from "./FurnitureMasterList/FurnitureMasterList.saga";

import { watcherFetchAllEquipmentMasterLists } from "./EquipmentMasterList/EquipmentMasterList.saga";

import {
  watcherFetchAllMaterialConsumptionReports,
  watcherFetchOneMaterialConsumptionReports,
} from "./MaterialConsumptionReport/MaterialConsumptionReport.saga";

import {
  watcherFetchAllSubContractorSiteHandovers,
  watcherFetchOneSubContractorSiteHandovers,
} from "./SubContractorSiteHandover/SubContractorSiteHandover.saga";

import {
  watcherFetchAllEvaluationFormss,
  watcherFetchOneEvaluationFormss,
} from "./EvaluationForms/EvaluationForms.saga";

import {
  watcherFetchAllSubContractEvaluations,
  watcherFetchOneSubContractEvaluations,
} from "./SubContractEvaluation/SubContractEvaluation.saga";

import { watcherFetchAllMaterialCatagorys } from "./MaterialCatagory/MaterialCatagory.saga";
import { watcherFetchAllMDMaterials } from "./MDMaterial/MDMaterial.saga";
import { watcherFetchAllMaterialDatabaseItems } from "./MaterialDatabaseItem/MaterialDatabaseItem.saga";
import {
  watcherFetchAllProjectTeams,
  watcherFetchOneProjectTeams,
} from "./ProjectTeam/ProjectTeam.saga";

import { watcherFetchAllStaffBios } from "./StaffBio/StaffBio.saga";

import {
  watcherFetchAllStaffMeals,
  watcherFetchOneStaffMeals,
} from "./StaffMeal/StaffMeal.saga";

import {
  watcherFetchAllStaffPenalties,
  watcherFetchOneStaffPenalties,
} from "./StaffPenalty/StaffPenalty.saga";

import {
  watcherFetchAllHRAnalytics,
  watcherFetchOneHRAnalytics,
} from "./HRAnalytics/HRAnalytics.saga";

import {
  watcherFetchAllFamilyAllotments,
  watcherFetchOneFamilyAllotments,
} from "./FamilyAllotment/FamilyAllotment.saga";

import {
  watcherFetchAllLoans,
  watcherFetchLoanStatuses,
} from "./Loan/Loan.saga";

import {
  watcherFetchAllLoanExtensions,
  watcherFetchOneLoanExtensions,
} from "./LoanExtension/LoanExtension.saga";

import {
  watcherFetchAllLoanRequests,
  watcherFetchOneLoanRequests,
} from "./LoanRequest/LoanRequest.saga";

import { watcherFetchAllPromotions } from "./Promotion/Promotion.saga";

import {
  watcherFetchAllTrainings,
  watcherFetchOneTrainings,
} from "./Training/Training.saga";

import { watcherFetchAllTravelRegistry } from "./TravelRegistry/TravelRegistry.saga";
import { watcherFetchAllHolidays } from "./Holiday/Holiday.saga";
import {
  watcherFetchAllStaffAttendances,
  watcherFetchAllMonthlyStaffAttendances,
} from "./StaffAttendance/StaffAttendance.saga";

import { watcherFetchAllAttendanceLocations } from "./AttendanceLocation/AttendanceLocation.saga";
import { watcherFetchAllAttendanceLocationUsers } from "./AttendanceLocationUser/AttendanceLocationUser.saga";
import { watcherFetchAllEarlyLeaveRequests } from "./EarlyLeaveRequest/EarlyLeaveRequest.saga";
import { watcherFetchAllOvertimeRequest2s } from "./OvertimeRequest2/OvertimeRequest2.saga";
import {
  watcherFetchAllSelfAttendingUsers,
  watcherFetchOneSelfAttendingUsers,
} from "./SelfAttendingUser/SelfAttendingUser.saga";

import {
  watcherFetchAllPayroll2s,
  watcherFetchOnePayroll2s,
  watcherFetchPayroll2sAggregate,
} from "./Payroll2/Payroll2.saga";

import { watcherFetchAllPerformancePointPolicies } from "./PerformancePointPolicy/PerformancePointPolicy.saga";

import {
  watcherFetchAllEmployeePerformanceForms,
  watcherFetchOneEmployeePerformanceForms,
} from "./EmployeePerformanceForm/EmployeePerformanceForm.saga";

import {
  watcherFetchAllEmployeePerformances,
  watcherFetchOneEmployeePerformances,
} from "./EmployeePerformance/EmployeePerformance.saga";

import { watcherFetchAllResignations } from "./Resignation/Resignation.saga";
import { watcherFetchAllDisciplines } from "./Discipline/Discipline.saga";

import {
  watcherFetchAllDailyTasks,
  watcherFetchOneDailyTasks,
} from "./DailyTask/DailyTask.saga";

import {
  watcherFetchAllAutomaticMessages,
  watcherFetchOneAutomaticMessages,
} from "./AutomaticMessage/AutomaticMessage.saga";

import { watcherFetchAllDrafts } from "./Draft/Draft.saga";

import {
  watcherFetchAllFeedBackForms,
  watcherFetchOneFeedBackForms,
} from "./FeedBackForm/FeedBackForm.saga";

import {
  watcherFetchAllFeedBackResponses,
  watcherFetchOneFeedBackResponses,
} from "./FeedBackResponse/FeedBackResponse.saga";

import {
  watcherFetchAllNewsAndMotivations,
  watcherFetchOneNewsAndMotivations,
} from "./NewsAndMotivation/NewsAndMotivation.saga";

import { watcherFetchAllPartners } from "./Partner/Partner.saga";
import { watcherFetchAllPartnerMessages } from "./PartnerMessage/PartnerMessage.saga";
import { watcherFetchAllSMSMessages } from "./SMSMessage/SMSMessage.saga";
import {
  watcherFetchAllOffers,
  watcherFetchOneOffers,
} from "./Offer/Offer.saga";

import { watcherFetchAllProjectTypes } from "./ProjectType/ProjectType.saga";

import {
  watcherFetchAllUnitBreakDownRates,
  watcherFetchOneUnitBreakDownRates,
} from "./UnitBreakDownRate/UnitBreakDownRate.saga";

import {
  watcherFetchAllCheckLists,
  watcherFetchOneCheckLists,
  watcherFetchChecklistNumbers,
} from "./CheckList/CheckList.saga";

import { watcherFetchAllCompanyLoans } from "./CompanyLoan/CompanyLoan.saga";
import { watcherFetchAllChartsOfAccounts } from "./ChartsOfAccount/ChartsOfAccount.saga";

export default function* RootSaga() {
  yield all([
    /**
     * Project Sagas
     */
    call(watcherFetchAllStaffBios),
    call(watcherFetchAllMaterialCatagorys),
    call(watcherFetchAllMDMaterials),
    call(watcherFetchAllMaterialDatabaseItems),
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllProjectsDetail),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchAllBoq),
    call(watcherFetchOneBoq),
    call(watcherFetchMaterials),
    call(watcherFetchSupplies),
    call(watcherFetchMaterialInventories),
    call(watcherFetchMaterialTransfer),
    call(watcherFetchAllUsage),
    call(watcherFetchAllLabourUsage),
    call(watcherFetchAllLabour),
    call(watcherFetchAllLabourPlans),
    call(watcherFetchDetailBoq),
    call(watcherFetchAllDepartments),
    call(watcherFetchAllStaffs),
    call(watcherFetchAllStaffDetails),
    call(watcherFetchAllStaffPlans),
    call(watcherFetchAllSubContracts),
    call(watcherFetchOneSubContracts),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllProjectStaffs),
    call(watcherFetchAllManpowerOvertime),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchAllLabourPayrolls),
    call(watcherFetchAllSchedules),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllEmployeeExtras),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchOneUser),
    call(watcherFetchAllAttendances),
    call(watcherFetchAllEmployeeRequests),
    call(watcherFetchEmployeeLeaveRequestState),
    call(watcherFetchEmployeeHealthBenefitRequestState),
    call(watcherFetchAllMaterialRequisitions),
    call(watcherFetchAllPurchaseRequisitions),
    call(watcherFetchAllPurchaseOrders),
    call(watcherFetchOnePurchaseOrders),
    call(watcherFetchAllPurchaseBillings),
    call(watcherFetchAllGoodReceived),
    call(watcherFetchAllRenters),
    call(watcherFetchAllGoodOut),
    call(watcherFetchAllGoodReturn),
    call(watcherFetchEmployeeHistory),
    call(watcherFetchAllDocumentWorkOrders),
    call(watcherFetchAllHRPolicy),
    call(watcherFetchAllAbsences),
    call(watcherFetchOneStaffs),
    call(watcherFetchAllStaffsAlongTermination),
    call(watcherFetchAllEmployeeDocuments),
    call(watcherFetchAllClients),
    call(watcherFetchAllAllowances),
    call(watcherFetchAllAllowancePolicy),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllStandard),
    call(watcherFetchOneStandard),
    call(watcherFetchAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllBoqStandard),
    call(watcherFetchOneBoqStandard),
    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchAllPaymentCertificateRoads),
    call(watcherFetchOnePaymentCertificateRoads),
    call(watcherFetchAllCastings),
    call(watcherFetchAllTestResult),
    call(watcherFetchOneTestResult),
    call(watcherFetchAllTestRequest),
    call(watcherFetchOneTestRequest),
    call(watcherFetchAllMaterialRequestApprovals),
    call(watcherFetchOneMaterialRequestApprovals),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllEmployeeReview),
    call(watcherFetchAllBonuses),
    call(watcherFetchAllVacancies),
    call(watcherFetchAllApplications),
    call(watcherFetchVacanciesByAttributes),
    call(watcherFetchVacanciesByJobId),
    call(watcherFetchAllPayrollReport),
    call(watcherFetchAllPayrollStaffHistory),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllBankAccounts),
    call(watcherFetchOneBankAccounts),
    call(watcherFetchAllAccountTransactions),
    call(watcherFetchMaterialInventoriesAnalysis),
    call(watcherFetchAllTestEvaluations),
    call(watcherFetchOneTestEvaluations),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllEstimatedBuildingCosts),
    call(watcherFetchOneMaterialRequisitions),
    call(watcherFetchOnePurchaseRequisitions),
    call(watcherFetchAllProformas),
    call(watcherFetchOneProformas),
    call(watcherFetchLoggedInUser),
    call(watcherFetchAllCurrencies),
    call(watcherFetchAllProcurementPlans),
    call(watcherFetchOneProcurementPlans),
    call(watcherFetchAllMonthSubcontractorSchedules),
    call(watcherFetchOneMonthSubcontractorSchedules),
    call(watcherFetchAllEquipmentDemobiliations),
    call(watcherFetchOneEquipmentDemobiliations),
    call(watcherFetchAllBudgetRequests),
    call(watcherFetchOneBudgetRequests),
    call(watcherFetchAllBudgetUtilizations),
    call(watcherFetchOneBudgetUtilizations),
    call(watcherFetchAllFinanceDailyReports),
    call(watcherFetchAllSupplyFixContracts),
    call(watcherFetchAllSupplyFixWorkProgress),
    call(watcherFetchAllStores),
    call(watcherFetchOneStores),
    call(watcherFetchAllSupportLetters),
    call(watcherFetchAllProformaComparisons),
    call(watcherFetchOneProformaComparisons),
    call(watcherFetchAllWeeklyProgressSummarys),
    call(watcherFetchAllCastingTestReports),
    call(watcherFetchOneCastingTestReports),
    call(watcherFetchFinanceDailyReports),
    call(watcherFetchAllProjectReport),
    call(watcherFetchAllFuelRequestVouchers),
    call(watcherFetchAllFuelRecievingVouchers),
    call(watcherFetchAllFuelIssueVouchers),
    call(watcherFetchAllUploadDocuments),
    call(watcherFetchOneUploadDocuments),
    call(watcherFetchUploadDocumentReferenceNumber),
    call(watcherFetchAllAdditionBoqs),
    call(watcherFetchOneAdditionBoqs),
    call(watcherFetchAllOmissionBoqs),
    call(watcherFetchOneOmissionBoqs),
    call(watcherFetchAllSupplementaryBoqs),
    call(watcherFetchOneSupplementaryBoqs),
    call(watcherFetchAllMasterScheduleUpdateds),
    call(watcherFetchOneMasterScheduleUpdateds),
    call(watcherFetchAllBillSummarys),
    call(watcherFetchOneBillSummarys),
    call(watcherFetchAllSummarys),
    call(watcherFetchOneSummarys),
    call(watcherFetchAllSubContractRegistrys),
    call(watcherFetchOneSubContractRegistrys),
    call(watcherFetchAllPlanSummaryOfNextDays),
    call(watcherFetchOnePlanSummaryOfNextDays),
    call(watcherFetchAllTenders),
    call(watcherFetchOneTenders),
    call(watcherFetchAllTenderSubmissionChecklists),
    call(watcherFetchOneTenderSubmissionChecklists),
    call(watcherFetchAllTenderDocumentss),
    call(watcherFetchOneTenderDocumentss),
    call(watcherFetchAllSiteHandovers),
    call(watcherFetchOneSiteHandovers),
    call(watcherFetchAllAllureMonthlyPlans),
    call(watcherFetchOneAllureMonthlyPlans),
    call(watcherFetchAllAllureWeeklyPlans),
    call(watcherFetchOneAllureWeeklyPlans),
    call(watcherFetchAllResourceMobilizations),
    call(watcherFetchOneResourceMobilizations),
    call(watcherFetchAllAllureEquipmentRequests),
    call(watcherFetchOneAllureEquipmentRequests),
    call(watcherFetchAllAllureMaterialRequests),
    call(watcherFetchOneAllureMaterialRequests),
    call(watcherFetchAllAllureResourceApprovals),
    call(watcherFetchOneAllureResourceApprovals),
    call(watcherFetchAllAllureSupportingDocuments),
    call(watcherFetchOneAllureSupportingDocuments),
    call(watcherFetchAllTenderOpenings),
    call(watcherFetchOneTenderOpenings),
    call(watcherFetchAllContractualIssues),
    call(watcherFetchOneContractualIssues),
    call(watcherFetchAllSubcontractorRequests),
    call(watcherFetchOneSubcontractorRequests),
    call(watcherFetchAllMasterScheduleSummaryss),
    call(watcherFetchOneMasterScheduleSummaryss),
    call(watcherFetchAllMasterScheduleCashflows),
    call(watcherFetchOneMasterScheduleCashflows),
    call(watcherFetchAllMasterScheduleMaterialss),
    call(watcherFetchOneMasterScheduleMaterialss),
    call(watcherFetchAllMasterScheduleMachinerys),
    call(watcherFetchOneMasterScheduleMachinerys),
    call(watcherFetchAllMasterScheduleManpowers),
    call(watcherFetchOneMasterScheduleManpowers),
    call(watcherFetchAllSiteVisitSchedules),
    call(watcherFetchOneSiteVisitSchedules),
    call(watcherFetchAllSiteVisitChecklists),
    call(watcherFetchOneSiteVisitChecklists),
    call(watcherFetchAllTemporaryAcceptances),
    call(watcherFetchOneTemporaryAcceptances),
    call(watcherFetchAllAllureWeeklySchedules),
    call(watcherFetchOneAllureWeeklySchedules),
    call(watcherFetchAllMaterialConsumptions),
    call(watcherFetchOneMaterialConsumptions),
    call(watcherFetchAllIndirectCosts),
    call(watcherFetchOneIndirectCosts),
    call(watcherFetchAllAllurePaymentSummarys),
    call(watcherFetchOneAllurePaymentSummarys),
    call(watcherFetchAllAllureLabourUtilizations),
    call(watcherFetchOneAllureLabourUtilizations),
    call(watcherFetchAllSubcontractorSelections),
    call(watcherFetchOneSubcontractorSelections),
    call(watcherFetchAllSubContractPaymentUpdates),
    call(watcherFetchOneSubContractPaymentUpdates),
    call(watcherFetchAllFurnitureMasterLists),
    call(watcherFetchAllEquipmentMasterLists),
    call(watcherFetchAllComments),
    call(watcherFetchOneComments),
    call(watcherFetchOneInventory),
    call(watcherFetchAllInventories),
    call(watcherFetchAllQueriedInventories),
    call(watcherFetchAllMaterialConsumptionReports),
    call(watcherFetchOneMaterialConsumptionReports),
    call(watcherFetchAllSubContractorSiteHandovers),
    call(watcherFetchOneSubContractorSiteHandovers),
    call(watcherFetchAllEvaluationFormss),
    call(watcherFetchOneEvaluationFormss),
    call(watcherFetchAllSubContractEvaluations),
    call(watcherFetchOneSubContractEvaluations),
    call(watcherFetchAllProjectTeams),
    call(watcherFetchOneProjectTeams),
    call(watcherFetchAllStaffMeals),
    call(watcherFetchOneStaffMeals),
    call(watcherFetchAllStaffPenalties),
    call(watcherFetchOneStaffPenalties),
    call(watcherFetchAllHRAnalytics),
    call(watcherFetchOneHRAnalytics),
    call(watcherFetchAllFamilyAllotments),
    call(watcherFetchOneFamilyAllotments),
    call(watcherFetchAllLoans),
    call(watcherFetchLoanStatuses),
    call(watcherFetchAllLoanExtensions),
    call(watcherFetchOneLoanExtensions),
    call(watcherFetchAllLoanRequests),
    call(watcherFetchOneLoanRequests),
    call(watcherFetchAllPromotions),
    call(watcherFetchAllTrainings),
    call(watcherFetchOneTrainings),
    call(watcherFetchAllTravelRegistry),
    call(watcherFetchAllHolidays),
    call(watcherFetchAllStaffAttendances),
    call(watcherFetchAllMonthlyStaffAttendances),
    call(watcherFetchAllAttendanceLocations),
    call(watcherFetchAllAttendanceLocationUsers),
    call(watcherFetchAllEarlyLeaveRequests),
    call(watcherFetchAllOvertimeRequest2s),
    call(watcherFetchAllSelfAttendingUsers),
    call(watcherFetchOneSelfAttendingUsers),
    call(watcherFetchAllPayroll2s),
    call(watcherFetchOnePayroll2s),
    call(watcherFetchPayroll2sAggregate),
    call(watcherFetchAllPerformancePointPolicies),
    call(watcherFetchAllEmployeePerformanceForms),
    call(watcherFetchOneEmployeePerformanceForms),
    call(watcherFetchAllEmployeePerformances),
    call(watcherFetchOneEmployeePerformances),
    call(watcherFetchAllResignations),
    call(watcherFetchAllDisciplines),
    call(watcherFetchAllDailyTasks),
    call(watcherFetchOneDailyTasks),
    call(watcherFetchAllAutomaticMessages),
    call(watcherFetchOneAutomaticMessages),
    call(watcherFetchAllDrafts),
    call(watcherFetchAllFeedBackForms),
    call(watcherFetchOneFeedBackForms),
    call(watcherFetchAllFeedBackResponses),
    call(watcherFetchOneFeedBackResponses),
    call(watcherFetchAllNewsAndMotivations),
    call(watcherFetchOneNewsAndMotivations),
    call(watcherFetchAllPartners),
    call(watcherFetchAllPartnerMessages),
    call(watcherFetchAllSMSMessages),
    call(watcherFetchAllOffers),
    call(watcherFetchOneOffers),
    call(watcherFetchAllProjectTypes),
    call(watcherFetchAllUnitBreakDownRates),
    call(watcherFetchOneUnitBreakDownRates),
    call(watcherFetchBoqTotal),
    call(watcherFetchPagedBoq),
    call(watcherFetchSheetNames),
    call(watcherFetchUnitBoq),
    call(watcherFetchAllCheckLists),
    call(watcherFetchOneCheckLists),
    call(watcherFetchChecklistNumbers),
    call(watcherFetchAllCompanyLoans),
    call(watcherFetchAllChartsOfAccounts),
  ]);
}
