import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureLabourUtilizationActionTypes } from "./AllureLabourUtilization.type";

export function* fetchAllAllureLabourUtilizations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-labour-utilization?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllureLabourUtilizationActionTypes.FETCH_ALL_ALLURE_LABOUR_UTILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureLabourUtilizationActionTypes.FETCH_ALL_ALLURE_LABOUR_UTILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureLabourUtilizations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-labour-utilization/${action.payload}`
    );
    yield put({
      type: AllureLabourUtilizationActionTypes.FETCH_ONE_ALLURE_LABOUR_UTILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureLabourUtilizationActionTypes.FETCH_ONE_ALLURE_LABOUR_UTILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureLabourUtilizations() {
  yield takeLatest(AllureLabourUtilizationActionTypes.FETCH_ALL_ALLURE_LABOUR_UTILIZATION, fetchAllAllureLabourUtilizations);
}

export function* watcherFetchOneAllureLabourUtilizations() {
  yield takeLatest(AllureLabourUtilizationActionTypes.FETCH_ONE_ALLURE_LABOUR_UTILIZATION, fetchOneAllureLabourUtilizations);
}
