import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureWeeklyPlanActionTypes } from "./AllureWeeklyPlan.type";

export function* fetchAllAllureWeeklyPlans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-weekly-plan?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllureWeeklyPlanActionTypes.FETCH_ALL_ALLURE_WEEKLY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureWeeklyPlanActionTypes.FETCH_ALL_ALLURE_WEEKLY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureWeeklyPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-weekly-plan/one?project_id=${action.payload?.project_id}&week_start=${action.payload?.week_start}&week_end=${action.payload?.week_end}`
    );
    yield put({
      type: AllureWeeklyPlanActionTypes.FETCH_ONE_ALLURE_WEEKLY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureWeeklyPlanActionTypes.FETCH_ONE_ALLURE_WEEKLY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureWeeklyPlans() {
  yield takeLatest(AllureWeeklyPlanActionTypes.FETCH_ALL_ALLURE_WEEKLY_PLAN, fetchAllAllureWeeklyPlans);
}

export function* watcherFetchOneAllureWeeklyPlans() {
  yield takeLatest(AllureWeeklyPlanActionTypes.FETCH_ONE_ALLURE_WEEKLY_PLAN, fetchOneAllureWeeklyPlans);
}
