import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingIndicator from "../components/common/Loading";
import EmployeeApplicationSuccess from "../components/HR/EmployeeApplication/employee-application-success";
import EmployeeApplication from "../components/HR/EmployeeApplication/employeeApplication";
import Welcome from "../components/HR/EmployeeApplication/welcome";
import CheckerComponent from "../components/Signup/Checker";
import GoogleAuth from "../components/Signup/Checker/google-auth";
import { EmailConfirmation } from "../components/Signup/EmailConfirmation";
import GoogleAuthForm from "../components/Signup/GoogleAuth/googleAuthForm.component";
import ResetEmail from "../components/Signup/ResetEmail/resetEmail";
import ErrorBoundary from "../containers/Errors/ErrorBoundary/ErrorBoundary.component";
import Layout from "../containers/Layouts/Layout/Layout.component";
import { checkAuthorization, isLoggedIn } from "../utilities/utilities";
import { RouteConstants } from "./Constants";

const ClientSummaryComponent = lazy(
  () => import("../containers/ClientSummary/ClientSummary.component")
);

const ProjectListComponent = lazy(
  () => import("../containers/Project/List.component")
);
const EnterpriseLoginComponent = lazy(() => import("../components/Login"));

const SignupComponent = lazy(() => import("../components/Signup/index"));
const HomepageComponent = lazy(
  () => import("../components/Homepage/Homepage.component")
);

const RegisterProjectComponent = lazy(
  () => import("../components/Project/ProjectRegistration")
);

const InventoryComponent = lazy(
  () => import("../containers/Inventory/Inventory.component")
);
const EquipmentComponent = lazy(
  () => import("../containers/Equipment/Equipment.component")
);
const HRComponent = lazy(() => import("../containers/HR/HR.component"));
const FinanceComponent = lazy(
  () => import("../containers/Finance/Finance.component")
);

const TaskComponent = lazy(
  () => import("../containers/Task/Task.component")
);

const TendersComponent = lazy(
  () => import("../containers/Tenders/Tenders.component")
);

const SubContractComponent = lazy(
  () => import("../containers/SubContract/SubContract.component")
);

const DocumentComponent = lazy(
  () => import("../containers/Documents/Documents.component")
);
const DashboardComponent = lazy(
  () => import("../containers/DashBoard/Dashboard.component")
);
const ProcurementComponent = lazy(
  () => import("../containers/Procurement/Procurement.component")
);

const TenderSubmissionComponent = lazy(
  () => import("../containers/TenderSubmission/TenderSubmission.component")
);

const MasterScheduleSummaryComponent = lazy(
  () => import("../containers/MasterScheduleSummary/MasterScheduleSummary.component")
);

const SiteVisitsComponent = lazy(
  () => import("../containers/SiteVisits/SiteVisits.component")
);

const ResourceSupportingDocumentsComponent = lazy(
  () => import("../containers/ResourceSupportingDocuments/ResourceSupportingDocuments.component")
);

const SubContractorSiteHandoversComponent = lazy(
  () => import("../containers/SubContractorSiteHandovers/SubContractorSiteHandovers.component")
);

const DatabaseComponent = lazy(
  () => import("../containers/Database/DatabaseComponent")
);

// import TestComponent from "../test";

// Re-Initialize the onbeforeunload event listener

const Routes = () => {
  const PrivateRoute = ({ ...props }: any) => {
    const { children, ...rest } = props;
    const isAuthenticated = isLoggedIn();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = checkAuthorization(rest.path);
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated && isAuthorized ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  };

  return (
    <>
      <ErrorBoundary>
        <Switch>
          <Route exact path={RouteConstants.LOGIN}>
            <Suspense fallback={<LoadingIndicator />}>
              <EnterpriseLoginComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.SIGN_UP}>
            <Suspense fallback={<LoadingIndicator />}>
              <SignupComponent />
            </Suspense>
          </Route>

          <Route path={RouteConstants.EMAIL_CONFIRMATION}>
            <EmailConfirmation />
          </Route>

          <Route path={RouteConstants.GOOGLE_AUTH_FORM}>
            <GoogleAuthForm />
          </Route>

          <Route path={RouteConstants.EMPLOYEE_APPLICATION}>
            <Welcome />
          </Route>

          <Route path={RouteConstants.EMPLOYEE_APPLICATION2}>
            <EmployeeApplication />
          </Route>

          <Route path={RouteConstants.EMPLOYEE_APPLICATION_SUCCESS}>
            <EmployeeApplicationSuccess />
          </Route>

          <Route path={RouteConstants.GOOGLE_AUTH}>
            <GoogleAuth />
          </Route>
          <Route path={RouteConstants.CHECKER}>
            <CheckerComponent />
          </Route>
          <Route path={RouteConstants.RESET}>
            <ResetEmail />
          </Route>
          <Route exact path={RouteConstants.HOME_PAGE}>
            <Suspense fallback={<LoadingIndicator />}>
              <HomepageComponent />
            </Suspense>
          </Route>
          <PrivateRoute exact path={RouteConstants.DASHBOARD}>
            <Redirect to={{ pathname: RouteConstants.PROJECTS }} />
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.DASHBOARDS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DashboardComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          
          {/* HR */}
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE_INDEX}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* FINANCE */}
          <PrivateRoute exact path={RouteConstants.FINANCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FinanceComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>    

          {/* Project Manager */}
          <PrivateRoute exact path={RouteConstants.DASHBOARDS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DashboardComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.DASHBOARDS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DashboardComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.SUMMARY}>
            <Layout></Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROJECT_LIST}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="1" project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CLIENT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ClientSummaryComponent key="15" location="" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.TENDER_SUBMISSION}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TenderSubmissionComponent key="1875" location="" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.MASTER_SCHEDULE_SUMMARIES}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <MasterScheduleSummaryComponent key="1876" location="" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROJECTS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="9" project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_PROJECT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PRE_CONTRACT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="1" project_type="pre-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_PRE_CONTRACT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="pre-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.SUB_CONTRACT_LIST}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <SubContractComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
           <PrivateRoute exact path={RouteConstants.TASK}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TaskComponent key="500" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          {/** Tender */}
          <PrivateRoute exact path={RouteConstants.TENDER}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TendersComponent key="600" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.SITE_VISIT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <SiteVisitsComponent key="6009464" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.DOCUMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.INVENTORY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <InventoryComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROCUREMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProcurementComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.EQUIPMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <EquipmentComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE_INDEX}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.RESOURCE_APPROVAL}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ResourceSupportingDocumentsComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.SITE_HANDOVER}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <SubContractorSiteHandoversComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute path={RouteConstants.DATABASE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DatabaseComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path={RouteConstants.DATABASE_TAB}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DatabaseComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Routes);
