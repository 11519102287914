import { SubContractEvaluationStateTypes, SubContractEvaluationActionTypes } from "./SubContractEvaluation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubContractEvaluationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubContractEvaluationReducer = (
  state: SubContractEvaluationStateTypes = INITIAL_STATE,
  action: any
): SubContractEvaluationStateTypes => {
  switch (action.type) {
    case SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubContractEvaluationReducer;
