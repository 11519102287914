import { GoodOut } from "../GoodOut/GoodOut.type";
import { GoodReceived } from "../GoodReceived/GoodReceived.type";
import { Material } from "../Material/Material.type";
import { MaterialUsageItem } from "../MaterialUsage/MaterialUsage.type";
import { Project, Store } from "../Project/Project.type";
import { Supplier } from "../Supplier/Supplier.type";
import { ApiCallState } from "../Utils";

export const MaterialInventoryActions = {
  FETCH_MATERIAL_INVENTORY: "FETCH_MATERIAL_INVENTORY",
  FETCH_MATERIAL_INVENTORY_SUCCESS: "FETCH_MATERIAL_INVENTORY_SUCCESS",
  FETCH_MATERIAL_INVENTORY_ERROR: "FETCH_MATERIAL_INVENTORY_ERROR",

  FETCH_ONE_MATERIAL_INVENTORY: "FETCH_ONE_MATERIAL_INVENTORY",
  FETCH_ONE_MATERIAL_INVENTORY_SUCCESS: "FETCH_ONE_MATERIAL_INVENTORY_SUCCESS",
  FETCH_ONE_MATERIAL_INVENTORY_ERROR: "FETCH_ONE_MATERIAL_INVENTORY_ERROR",

  FETCH_MATERIAL_INVENTORY_ANALYSIS: "FETCH_MATERIAL_INVENTORY_ANALYSIS",
  FETCH_MATERIAL_INVENTORY_ANALYSIS_SUCCESS:
    "FETCH_MATERIAL_INVENTORY_ANALYSIS_SUCCESS",
  FETCH_MATERIAL_INVENTORY_ANALYSIS_ERROR:
    "FETCH_MATERIAL_INVENTORY_ANALYSIS_ERROR",
  FETCH_MATERIAL_INVENTORY_ANALYSIS_RESET:
    "FETCH_MATERIAL_INVENTORY_ANALYSIS_RESET",
};

export type MaterialInventory = {
  id: number;
  quantity: number;
  inventory_id: number;
  material: Material;
  material_inventory: {
    id: number;
    material_id: number;
    supplier_id: number;
    unit_price: number;
    material: Material;
    supplier: Supplier;
    inventory_id: number;
  };
  material_locations: MaterialLocation[];
  material_usage_items: MaterialUsageItem[];
  project: Project;
  project_id: number | null;
  store_id: number | null;
  store: Store;
};

export type MaterialLocation = {
  id: number;
  inventory_id: number;
  quantity: number;
  project_id: number | null;
  store_id: number;
};

export type MaterialInventoryStateTypes = {
  fetchAll: ApiCallState<MaterialInventory[]>;
  fetchAnalysis: ApiCallState<{
    materialPlan: any;
    materialInventory: MaterialInventory;
    goodReceived: GoodReceived[];
    goodOut: GoodOut[];
  }>;
};
