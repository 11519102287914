import { AllowancePolicy } from "../HRPolicy/HRPolicy.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Allowance = {
  id: number;
  description: string;
  amount: number;
  start_date: string;
  end_date: string;
  staff: Staff;
  staff_id: number;
  createdAt: Date;
  allowance_policy: AllowancePolicy;
  allowance_policy_id: number;
};

export type AllowanceStateTypes = {
  fetchAll: ApiCallState<Allowance[]>;
  fetchOne: ApiCallState<Allowance | {}>;
};

export const AllowanceActionTypes = {
  FETCH_ALL_ALLOWANCE: "FETCH_ALL_ALLOWANCE",
  FETCH_ALL_ALLOWANCE_RESET: "FETCH_ALL_ALLOWANCE_RESET",
  FETCH_ALL_ALLOWANCE_FAILURE: "FETCH_ALL_ALLOWANCE_FAILURE",
  FETCH_ALL_ALLOWANCE_SUCCESS: "FETCH_ALL_ALLOWANCE_SUCCESS",

  FETCH_ONE_ALLOWANCE: "FETCH_ONE_ALLOWANCE",
  FETCH_ONE_ALLOWANCE_RESET: "FETCH_ONE_ALLOWANCE_RESET",
  FETCH_ONE_ALLOWANCE_FAILURE: "FETCH_ONE_ALLOWANCE_FAILURE",
  FETCH_ONE_ALLOWANCE_SUCCESS: "FETCH_ONE_ALLOWANCE_SUCCESS",
};
