import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AttendanceReducer from "./Attendance/Attendance.reducer";
import BoqReducer from "./Boq/Boq.reducer";
import ConsultantReducer from "./Consultant/Consultant.reducer";
import ContractReducer from "./Contract/Contract.reducer";
import ContractorReducer from "./Contractor/Contractor.reducer";
import DepartmentReducer from "./Department/Department.reducer";
import DocumentReducer from "./Document/Document.reducer";
import EmployeeExtraReducer from "./EmployeeExtra/EmployeeExtra.reducer";
import EmployeeRequestReducer from "./EmployeeRequest/EmployeeRequest.reducer";
import LabourReducer from "./Labour/Labour.reducer";
import LabourPlanReducer from "./LabourPlan/LabourPlan.reducer";
import LabourUsageReducer from "./LabourUsage/LabourUsage.reducer";
import ManpowerOvertimeReducer from "./ManpowerOvertime/ManpowerOvertime.reducer";
import MaterialReducer from "./Material/Material.reducer";
import MaterialInventoryReducer from "./MaterialInventory/MaterialInventory.reducer";
import MaterialTransferReducer from "./MaterialTransfer/MaterialTransfer.reducer";
import PayrollReducer from "./Payroll/Payroll.reducer";

import ProjectReducer from "./Project/Project.reducer";
import ProjectStaffReducer from "./ProjectStaff/ProjectStaff.reducer";
import ScheduleReducer from "./Schedule/Schedule.reducer";
import StaffReducer from "./Staff/Staff.reducer";
import StaffPlanReducer from "./StaffPlan/StaffPlan.reducer";
import SubContractReducer from "./SubContract/SubContract.reducer";
import SupplierReducer from "./Supplier/Supplier.reducer";
import TourReducer from "./Tour/Tour.reducer";
import UsageReducer from "./Usage/Usage.reducer";
import UserReducer from "./User/User.reducer";
import IdReducer from "./ID/Id.reducer";
import MaterialRequisitionReducer from "./MaterialRequisition/MaterialRequisition.reducer";
import PurchaseRequisitionReducer from "./PurchaseRequisition/PurchaseRequisition.reducer";
import PurchaseOrderReducer from "./PurchaseOrder/PurchaseOrder.reducer";
import PurchaseBillingReducer from "./PurchaseBilling/PurchaseBilling.reducer";
import GoodReceivedReducer from "./GoodReceived/GoodReceived.reducer";
import RenterReducer from "./Renter/Renter.reducer";
import GoodOutReducer from "./GoodOut/GoodOut.reducer";
import GoodReturnReducer from "./GoodReturn/GoodReturn.reducer";
import EmployeeHistoryReducer from "./EmployeeHistory/EmployeeHistory.reducer";
import DocumentWorkOrderReducer from "./DocumentWorkOrder/DocumentWorkOrder.reducer";
import HRPolicyReducer from "./HRPolicy/HRPolicy.reducer";
import AbsenceReducer from "./Absence/Absence.reducer";
import EmployeeDocumentReducer from "./EmployeeDocument/EmployeeDocument.reducer";
import LogReducer from "./Log/Log.reducer";
import ClientReducer from "./Client/Client.reducer";
import AllowanceReducer from "./Allowance/Allowance.reducer";
import AllowancePolicyReducer from "./AllowancePolicy/AllowancePolicy.reducer";
import StandardReducer from "./Standard/Standard.reducer";
import BoqStandardReducer from "./BoqStandard/BoqStandard.reducer";
import ResourceReducer from "./Resource/Resource.reducer";
import PaymentCertificateRoadReducer from "./PaymentCertificateRoad/PaymentCertificateRoad.reducer";
import CastingReducer from "./Casting/Casting.reducer";
import TestResultReducer from "./TestResult/TestResult.reducer";
import TestRequestReducer from "./TestRequest/TestRequest.reducer";
import MaterialRequestApprovalReducer from "./MaterialRequestApproval/MaterialRequestApproval.reducer";
import StaffAttendanceReducer from "./StaffAttendance/StaffAttendance.reducer";
import EmployeeUserReducer from "./EmployeeUser/EmployeeUser.reducer";
import ReviewFormReducer from "./ReviewForm/ReviewForm.reducer";
import EmployeeReviewReducer from "./EmployeeReview/EmployeeReview.reducer";
import BonusReducer from "./Bonus/Bonus.reducer";
import VacancyReducer from "./Vacancy/vacancy.reducer";
import ApplicationReducer from "./Application/Application.reducer";

import SharedDocumentReducer from "./SharedDocument/SharedDocument.reducer";
import BankAccountReducer from "./BankAccount/BankAccount.reducer";
import AccountTransactionReducer from "./AccountTransaction/AccountTransaction.reducer";

import TestEvaluationReducer from "./TestEvaluation/TestEvaluation.reducer";
import MaterialUsageReducer from "./MaterialUsage/MaterialUsage.reducer";
import EstimatedBuildingCostReducer from "./EstimatedBuildingCost/EstimatedBuildingCost.reducer";
import ProformaReducer from "./Proforma/Proforma.reducer";
import CurrencyReducer from "./Currency/Currency.reducer";
import ProcurementPlanReducer from "./ProcurementPlan/ProcurementPlan.reducer";
import MonthSubcontractorScheduleReducer from "./MonthSubcontractorSchedule/MonthSubcontractorSchedule.reducer";
import EquipmentDemobiliationReducer from "./EquipmentDemobiliation/EquipmentDemobiliation.reducer";
import BudgetRequestReducer from "./BudgetRequest/BudgetRequest.reducer";
import BudgetUtilizationReducer from "./BudgetUtilization/BudgetUtilization.reducer";
import FinanceDailyReportReducer from "./FinanceDailyReport/FinanceDailyReport.reducer";
import SupplyFixWorkProgressReducer from "./SupplyFixWorkProgress/SupplyFixWorkProgress.reducer";
import SupplyFixContractReducer from "./SupplyFixContract/SupplyFixContract.reducer";
import StoreReducer from "./Store/Store.reducer";
import ProformaComparisonReducer from "./ProformaComparison/ProformaComparison.reducer";
import SupportLetterReducer from "./SupportLetter/SupportLetter.reducer";
import WeeklyProgressSummaryReducer from "./WeeklyProgressSummary/WeeklyProgressSummary.reducer";
import CastingTestReportReducer from "./CastingTestReport/CastingTestReport.reducer";
import FuelRequestVoucherReducer from "./FuelRequestVoucher/FuelRequestVoucher.reducer";
import FuelRecievingVoucherReducer from "./FuelRecievingVoucher/FuelRecievingVoucher.reducer";
import FuelIssueVoucherReducer from "./FuelIssueVoucher/FuelIssueVoucher.reducer";
import UploadDocumentReducer from "./UploadDocument/UploadDocument.reducer";
import AdditionBoqReducer from "./AdditionBoq/AdditionBoq.reducer";
import OmissionBoqReducer from "./OmissionBoq/OmissionBoq.reducer";
import SupplementaryBoqReducer from "./SupplementaryBoq/SupplementaryBoq.reducer";
import MasterScheduleUpdatedReducer from "./MasterScheduleUpdated/MasterScheduleUpdated.reducer";
import BillSummaryReducer from "./BillSummary/BillSummary.reducer";
import SummaryReducer from "./Summary/Summary.reducer";
import SubContractRegistryReducer from "./SubContractRegistry/SubContractRegistry.reducer";
import PlanSummaryOfNextDayReducer from "./PlanSummaryOfNextDay/PlanSummaryOfNextDay.reducer";
import TenderReducer from "./Tender/Tender.reducer";
import TenderSubmissionChecklistReducer from "./TenderSubmissionChecklist/TenderSubmissionChecklist.reducer";
import TenderDocumentsReducer from "./TenderDocuments/TenderDocuments.reducer";
import SiteHandoverReducer from "./SiteHandover/SiteHandover.reducer";
import AllureMonthlyPlanReducer from "./AllureMonthlyPlan/AllureMonthlyPlan.reducer";
import AllureWeeklyPlanReducer from "./AllureWeeklyPlan/AllureWeeklyPlan.reducer";
import ResourceMobilizationReducer from "./ResourceMobilization/ResourceMobilization.reducer";
import AllureEquipmentRequestReducer from "./AllureEquipmentRequest/AllureEquipmentRequest.reducer";
import AllureMaterialRequestReducer from "./AllureMaterialRequest/AllureMaterialRequest.reducer";
import AllureResourceApprovalReducer from "./AllureResourceApproval/AllureResourceApproval.reducer";
import AllureSupportingDocumentReducer from "./AllureSupportingDocument/AllureSupportingDocument.reducer";
import TenderOpeningReducer from "./TenderOpening/TenderOpening.reducer";
import ContractualIssueReducer from "./ContractualIssue/ContractualIssue.reducer";
import SubcontractorRequestReducer from "./SubcontractorRequest/SubcontractorRequest.reducer";
import MasterScheduleSummarysReducer from "./MasterScheduleSummarys/MasterScheduleSummarys.reducer";
import MasterScheduleCashflowReducer from "./MasterScheduleCashflow/MasterScheduleCashflow.reducer";
import MasterScheduleMaterialsReducer from "./MasterScheduleMaterials/MasterScheduleMaterials.reducer";
import MasterScheduleManpowerReducer from "./MasterScheduleManpower/MasterScheduleManpower.reducer";
import MasterScheduleMachineryReducer from "./MasterScheduleMachinery/MasterScheduleMachinery.reducer";
import SiteVisitScheduleReducer from "./SiteVisitSchedule/SiteVisitSchedule.reducer";
import SiteVisitChecklistReducer from "./SiteVisitChecklist/SiteVisitChecklist.reducer";

import TemporaryAcceptanceReducer from "./TemporaryAcceptance/TemporaryAcceptance.reducer";

import AllureWeeklyScheduleReducer from "./AllureWeeklySchedule/AllureWeeklySchedule.reducer";
import MaterialConsumptionReducer  from "./MaterialConsumption/MaterialConsumption.reducer";
import IndirectCostReducer from "./IndirectCost/IndirectCost.reducer";
import AllurePaymentSummaryReducer from "./AllurePaymentSummary/AllurePaymentSummary.reducer";
import AllureLabourUtilizationReducer from "./AllureLabourUtilization/AllureLabourUtilization.reducer";
import SubcontractorSelectionReducer from "./SubcontractorSelection/SubcontractorSelection.reducer";
import SubContractPaymentUpdateReducer from "./SubContractPaymentUpdate/SubContractPaymentUpdate.reducer";
import InventoryReducer from "./Inventory/Inventory.reducer";
import CommentReducer from "./Comment/Comment.reducer";
import FurnitureMasterListReducer from "./FurnitureMasterList/FurnitureMasterList.reducer";
import EquipmentMasterListReducer from "./EquipmentMasterList/EquipmentMasterList.reducer";
import MaterialConsumptionReportReducer from "./MaterialConsumptionReport/MaterialConsumptionReport.reducer";
import SubContractorSiteHandoverReducer from "./SubContractorSiteHandover/SubContractorSiteHandover.reducer";
import EvaluationFormsReducer from "./EvaluationForms/EvaluationForms.reducer";
import SubContractEvaluationReducer from "./SubContractEvaluation/SubContractEvaluation.reducer";
import MaterialCatagoryReducer from "./MaterialCatagory/MaterialCatagory.reducer";
import MDMaterialReducer from "./MDMaterial/MDMaterial.reducer";
import MaterialDatabaseItemReducer from "./MaterialDatabaseItem/MaterialDatabaseItem.reducer";
import ProjectTeamReducer from "./ProjectTeam/ProjectTeam.reducer";
import StaffBioReducer from "./StaffBio/StaffBio.reducer";
import StaffMealReducer from "./StaffMeal/StaffMeal.reducer";
import HRAnalyticsReducer from "./HRAnalytics/HRAnalytics.reducer";
import FamilyAllotmentReducer from "./FamilyAllotment/FamilyAllotment.reducer";
import LoanReducer from "./Loan/Loan.reducer";
import LoanExtensionReducer from "./LoanExtension/LoanExtension.reducer";
import LoanRequestReducer from "./LoanRequest/LoanRequest.reducer";
import PromotionReducer from "./Promotion/Promotion.reducer";
import TrainingReducer from "./Training/Training.reducer";
import TravelRegistryReducer from "./TravelRegistry/TravelRegistry.reducer";
import HolidayReducer from "./Holiday/Holiday.reducer";
import AttendanceLocation from "./AttendanceLocation/AttendanceLocation.reducer";
import AttendanceLocationUserReducer from "./AttendanceLocationUser/AttendanceLocationUser.reducer";
import EarlyLeaveRequestReducer from "./EarlyLeaveRequest/EarlyLeaveRequest.reducer";
import OvertimeRequest2Reducer from "./OvertimeRequest2/OvertimeRequest2.reducer";
import SelfAttendingUserReducer from "./SelfAttendingUser/SelfAttendingUser.reducer";
import Payroll2Reducer from "./Payroll2/Payroll2.redux";
import PerformancePointPolicyReducer from "./PerformancePointPolicy/PerformancePointPolicy.reducer";
import EmployeePerformanceFormReducer from "./EmployeePerformanceForm/EmployeePerformanceForm.reducer";
import EmployeePerformanceReducer from "./EmployeePerformance/EmployeePerformance.reducer";
import ResignationReducer from "./Resignation/Resignation.reducer";
import DisciplineReducer from "./Discipline/Discipline.reducer";
import DailyTaskReducer from "./DailyTask/DailyTask.reducer";
import AutomaticMessageReducer from "./AutomaticMessage/AutomaticMessage.reducer";
import DraftReducer from "./Draft/Draft.reducer";
import FeedBackFormReducer from "./FeedBackForm/FeedBackForm.reducer";
import FeedBackResponseReducer from "./FeedBackResponse/FeedBackResponse.reducer";
import NewsAndMotivationReducer from "./NewsAndMotivation/NewsAndMotivation.reducer";
import PartnerReducer from "./Partner/Partner.reducer";
import PartnerMessageReducer from "./PartnerMessage/PartnerMessage.reducer";
import SMSMessageReducer from "./SMSMessage/SMSMessage.reducer";
import OfferReducer from "./Offer/Offer.reducer";
import ProjectTypeReducer from "./ProjectType/ProjectType.reducer";
import UnitBreakDownRateReducer from "./UnitBreakDownRate/UnitBreakDownRate.reducer";
import CheckListReducer from "./CheckList/CheckList.reducer";
import CompanyLoanReducer from "./CompanyLoan/CompanyLoan.reducer";
import ChartsOfAccountReducer from "./ChartsOfAccount/ChartsOfAccount.reducer";

const PersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  project: ProjectReducer,
  contractor: ContractorReducer,
  consultant: ConsultantReducer,
  boq: BoqReducer,
  material: MaterialReducer,
  supplier: SupplierReducer,
  material_inventory: MaterialInventoryReducer,
  material_transfer: MaterialTransferReducer,
  usage: UsageReducer,
  labour: LabourReducer,
  labour_usage: LabourUsageReducer,
  labour_plan: LabourPlanReducer,
  department: DepartmentReducer,
  staff: StaffReducer,
  staff_plan: StaffPlanReducer,
  sub_contract: SubContractReducer,
  document: DocumentReducer,
  project_staff: ProjectStaffReducer,
  manpower_overtime: ManpowerOvertimeReducer,
  payroll: PayrollReducer,
  tour: TourReducer,
  schedule: ScheduleReducer,
  employee_extra: EmployeeExtraReducer,
  user: UserReducer,
  attendance: AttendanceReducer,
  contract: ContractReducer,
  employee_request: EmployeeRequestReducer,
  id: IdReducer,
  material_requisition: MaterialRequisitionReducer,
  purchase_requisition: PurchaseRequisitionReducer,
  purchase_order: PurchaseOrderReducer,
  purchase_billing: PurchaseBillingReducer,
  good_received: GoodReceivedReducer,
  renter: RenterReducer,
  good_out: GoodOutReducer,
  good_return: GoodReturnReducer,
  employee_history: EmployeeHistoryReducer,
  document_work_order: DocumentWorkOrderReducer,
  hr_policy: HRPolicyReducer,
  absence: AbsenceReducer,
  employee_document: EmployeeDocumentReducer,
  client: ClientReducer,
  allowance: AllowanceReducer,
  allowance_policy: AllowancePolicyReducer,
  standard: StandardReducer,
  log: LogReducer,
  boq_standard: BoqStandardReducer,
  resource: ResourceReducer,
  payment_certificate_road: PaymentCertificateRoadReducer,
  casting: CastingReducer,
  material_request_approval: MaterialRequestApprovalReducer,
  test_result: TestResultReducer,
  test_request: TestRequestReducer,
  staff_attendance: StaffAttendanceReducer,
  employee_user: EmployeeUserReducer,
  review_form: ReviewFormReducer,
  employee_review: EmployeeReviewReducer,
  bonus: BonusReducer,
  vacancies: VacancyReducer,
  applications: ApplicationReducer,
  sharedDocument: SharedDocumentReducer,
  bankAccount: BankAccountReducer,
  accountTransaction: AccountTransactionReducer,
  test_evaluation: TestEvaluationReducer,
  material_usage: MaterialUsageReducer,
  estimated_building_cost: EstimatedBuildingCostReducer,
  proforma: ProformaReducer,
  currency: CurrencyReducer,
  procurement_plan: ProcurementPlanReducer,
  month_subcontractor_schedule: MonthSubcontractorScheduleReducer,
  equipment_demobiliation: EquipmentDemobiliationReducer,
  budget_request: BudgetRequestReducer,
  budget_utilization: BudgetUtilizationReducer,
  finance_daily_report: FinanceDailyReportReducer,
  supply_fix_work_progress: SupplyFixWorkProgressReducer,
  supply_fix_contract: SupplyFixContractReducer,
  store: StoreReducer,
  support_letter: SupportLetterReducer,
  proforma_comparison: ProformaComparisonReducer,
  weekly_progress_summary: WeeklyProgressSummaryReducer,
  casting_test_report: CastingTestReportReducer,
  fuel_request_voucher: FuelRequestVoucherReducer,
  fuel_recieving_voucher: FuelRecievingVoucherReducer,
  fuel_issue_voucher: FuelIssueVoucherReducer,
  upload_document: UploadDocumentReducer,
  addition_boq:AdditionBoqReducer,
  omission_boq:OmissionBoqReducer,
  supplementary_boq:SupplementaryBoqReducer,
  master_schedule_updated:MasterScheduleUpdatedReducer,
  bill_summary:BillSummaryReducer,
  summary:SummaryReducer,
  sub_contract_registry:SubContractRegistryReducer,
  plan_summary_of_next_day:PlanSummaryOfNextDayReducer,
  tender:TenderReducer,
  tender_submission_checklist:TenderSubmissionChecklistReducer,
  tender_documents:TenderDocumentsReducer,
  site_handover:SiteHandoverReducer,
  allure_monthly_plan:AllureMonthlyPlanReducer,
  allure_weekly_plan:AllureWeeklyPlanReducer,
  resource_mobilization:ResourceMobilizationReducer,
  allure_equipment_request:AllureEquipmentRequestReducer,
  allure_material_request:AllureMaterialRequestReducer,
  allure_resource_approval:AllureResourceApprovalReducer,
  allure_supporting_document:AllureSupportingDocumentReducer,
  tender_opening:TenderOpeningReducer,
  contractual_issue:ContractualIssueReducer,
  subcontractor_request:SubcontractorRequestReducer,
  master_schedule_summarys:MasterScheduleSummarysReducer,
  master_schedule_cashflow:MasterScheduleCashflowReducer,
  master_schedule_materials:MasterScheduleMaterialsReducer,
  master_schedule_manpower:MasterScheduleManpowerReducer,
  master_schedule_machinery:MasterScheduleMachineryReducer,
  site_visit_schedule:SiteVisitScheduleReducer,
  site_visit_checklist:SiteVisitChecklistReducer,
  temporary_acceptance: TemporaryAcceptanceReducer,
  allure_weekly_schedule:AllureWeeklyScheduleReducer,
  material_consumption:MaterialConsumptionReducer,
  indirect_cost:IndirectCostReducer,
  allure_payment_summary:AllurePaymentSummaryReducer,
  allure_labour_utilization:AllureLabourUtilizationReducer,
  subcontractor_selection:SubcontractorSelectionReducer,
  sub_contract_payment_update:SubContractPaymentUpdateReducer,
  comment: CommentReducer,
  inventory: InventoryReducer,
  furniture_master_list: FurnitureMasterListReducer,
  equipment_master_list: EquipmentMasterListReducer,
  material_consumption_report:MaterialConsumptionReportReducer,
  subcontractor_site_handover:SubContractorSiteHandoverReducer,
  evaluation_forms:EvaluationFormsReducer,
  sub_contract_evaluation:SubContractEvaluationReducer,
  material_catagory: MaterialCatagoryReducer,
  md_material: MDMaterialReducer,
  material_database_item: MaterialDatabaseItemReducer,
  project_team: ProjectTeamReducer,
  staff_bio: StaffBioReducer,
  staff_meal: StaffMealReducer,
  hr_analytic: HRAnalyticsReducer,
  family_allotment: FamilyAllotmentReducer,
  loan: LoanReducer,
  loan_extension: LoanExtensionReducer,
  loan_request: LoanRequestReducer,
  promotion: PromotionReducer,
  training: TrainingReducer,
  travel_registry: TravelRegistryReducer,
  holiday: HolidayReducer,
  attendance_location: AttendanceLocation,
  attendance_location_user: AttendanceLocationUserReducer,
  early_leave_request: EarlyLeaveRequestReducer,
  overtime_request: OvertimeRequest2Reducer,
  self_attending_user: SelfAttendingUserReducer,
  payroll2: Payroll2Reducer,
  performance_point_policy: PerformancePointPolicyReducer,
  employee_performance_form: EmployeePerformanceFormReducer,
  employee_performance: EmployeePerformanceReducer,
  resignation: ResignationReducer,
  discipline: DisciplineReducer,
  daily_task: DailyTaskReducer,
  automatic_message: AutomaticMessageReducer,
  draft: DraftReducer,
  feed_back_form: FeedBackFormReducer,
  feed_back_response: FeedBackResponseReducer,
  news_and_motivation: NewsAndMotivationReducer,
  partner: PartnerReducer,
  partner_message: PartnerMessageReducer,
  sms_message: SMSMessageReducer,
  offer: OfferReducer,
  project_type: ProjectTypeReducer,
  unit_breakdown_rate: UnitBreakDownRateReducer,
  check_list: CheckListReducer,
  company_loan: CompanyLoanReducer,
  charts_of_account: ChartsOfAccountReducer,
});

export default persistReducer(PersistConfig, RootReducer);
