import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureMaterialRequestActionTypes } from "./AllureMaterialRequest.type";

export function* fetchAllAllureMaterialRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-material-request?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllureMaterialRequestActionTypes.FETCH_ALL_ALLURE_MATERIAL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureMaterialRequestActionTypes.FETCH_ALL_ALLURE_MATERIAL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureMaterialRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-material-request/${action.payload}`
    );
    yield put({
      type: AllureMaterialRequestActionTypes.FETCH_ONE_ALLURE_MATERIAL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureMaterialRequestActionTypes.FETCH_ONE_ALLURE_MATERIAL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureMaterialRequests() {
  yield takeLatest(AllureMaterialRequestActionTypes.FETCH_ALL_ALLURE_MATERIAL_REQUEST, fetchAllAllureMaterialRequests);
}

export function* watcherFetchOneAllureMaterialRequests() {
  yield takeLatest(AllureMaterialRequestActionTypes.FETCH_ONE_ALLURE_MATERIAL_REQUEST, fetchOneAllureMaterialRequests);
}
