import { EvaluationForms } from "../EvaluationForms/EvaluationForms.type";
import { SubcontractorSelection, SubcontractorSelectionItem } from "../SubcontractorSelection/SubcontractorSelection.type";
import { ApiCallState } from "../Utils";

export type SubContractEvaluation= {
  id: number;
  project_id:number;
  date:string;
  subcontractor_selection_id:number;
  subcontractor_selection_item_id: number;
  evaluation_type:string;
  evaluation_forms_id: number;
  user_id:number;
  subcontractor_selection:SubcontractorSelection;
  subcontractor_selection_item:SubcontractorSelectionItem;
  evaluation_forms:EvaluationForms;
  sub_contract_evaluation_items:SubContractEvaluationItem[];
};

export type SubContractEvaluationItem ={
    id: number;
    sub_contract_evaluation_id: number;
    description: string;
    expected_point: number;
    sub_contractor_point: number;
    remark:string
}

export type SubContractEvaluationStateTypes = {
  fetchAll: ApiCallState<SubContractEvaluation[]>;
  fetchOne: ApiCallState<SubContractEvaluation | {}>;
};

export const SubContractEvaluationActionTypes = {
  FETCH_ALL_SUB_CONTRACT_EVALUATION: "FETCH_ALL_SUB_CONTRACT_EVALUATION",
  FETCH_ALL_SUB_CONTRACT_EVALUATION_RESET: "FETCH_ALL_SUB_CONTRACT_EVALUATION_RESET",
  FETCH_ALL_SUB_CONTRACT_EVALUATION_FAILURE: "FETCH_ALL_SUB_CONTRACT_EVALUATION_FAILURE",
  FETCH_ALL_SUB_CONTRACT_EVALUATION_SUCCESS: "FETCH_ALL_SUB_CONTRACT_EVALUATION_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_EVALUATION: "FETCH_ONE_SUB_CONTRACT_EVALUATION",
  FETCH_ONE_SUB_CONTRACT_EVALUATION_RESET: "FETCH_ONE_SUB_CONTRACT_EVALUATION_RESET",
  FETCH_ONE_SUB_CONTRACT_EVALUATION_FAILURE: "FETCH_ONE_SUB_CONTRACT_EVALUATION_FAILURE",
  FETCH_ONE_SUB_CONTRACT_EVALUATION_SUCCESS: "FETCH_ONE_SUB_CONTRACT_EVALUATION_SUCCESS",
};
