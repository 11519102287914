import { ApiCallState } from "../Utils";

export type TenderSubmissionChecklist= {
  id: number;
  tender_id:number;
  description:string;
  checked_yes:boolean;
  checked_no:boolean;
  user_id: number;
};

export type TenderSubmissionChecklistStateTypes = {
  fetchAll: ApiCallState<TenderSubmissionChecklist[]>;
  fetchOne: ApiCallState<TenderSubmissionChecklist | {}>;
};

export const TenderSubmissionChecklistActionTypes = {
  FETCH_ALL_TENDER_SUBMISSION_CHECKLIST: "FETCH_ALL_TENDER_SUBMISSION_CHECKLIST",
  FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_RESET: "FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_RESET",
  FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_FAILURE: "FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_FAILURE",
  FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_SUCCESS: "FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_SUCCESS",

  FETCH_ONE_TENDER_SUBMISSION_CHECKLIST: "FETCH_ONE_TENDER_SUBMISSION_CHECKLIST",
  FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_RESET: "FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_RESET",
  FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_FAILURE: "FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_FAILURE",
  FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_SUCCESS: "FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_SUCCESS",
};
