import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type AllureSupportingDocument= {
  id: number;
  allure_resource_approval_id:number;
  description:number;
  document:Document;
  user_id: number;
};

export type AllureSupportingDocumentStateTypes = {
  fetchAll: ApiCallState<AllureSupportingDocument[]>;
  fetchOne: ApiCallState<AllureSupportingDocument | {}>;
};

export const AllureSupportingDocumentActionTypes = {
  FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT: "FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT",
  FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_RESET: "FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_RESET",
  FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_FAILURE: "FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_FAILURE",
  FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_SUCCESS: "FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_SUCCESS",

  FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT: "FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT",
  FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_RESET: "FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_RESET",
  FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_FAILURE: "FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_FAILURE",
  FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_SUCCESS: "FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_SUCCESS",
};
