import { ApiCallState } from "../Utils";

export type MasterScheduleMaterials= {
  id: number;
  master_schedule_updated_id:number;
  material:string;
  unit:string;
  submission:string;
  approval:string;
  ordering:string;
  total:number;
  month:string;
  month_value:number;
  is_title:boolean;
  user_id: number;
};

export type MasterScheduleMaterialsStateTypes = {
  fetchAll: ApiCallState<MasterScheduleMaterials[]>;
  fetchOne: ApiCallState<MasterScheduleMaterials | {}>;
};

export const MasterScheduleMaterialsActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_MATERIALS: "FETCH_ALL_MASTER_SCHEDULE_MATERIALS",
  FETCH_ALL_MASTER_SCHEDULE_MATERIALS_RESET: "FETCH_ALL_MASTER_SCHEDULE_MATERIALS_RESET",
  FETCH_ALL_MASTER_SCHEDULE_MATERIALS_FAILURE: "FETCH_ALL_MASTER_SCHEDULE_MATERIALS_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_MATERIALS_SUCCESS: "FETCH_ALL_MASTER_SCHEDULE_MATERIALS_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_MATERIALS: "FETCH_ONE_MASTER_SCHEDULE_MATERIALS",
  FETCH_ONE_MASTER_SCHEDULE_MATERIALS_RESET: "FETCH_ONE_MASTER_SCHEDULE_MATERIALS_RESET",
  FETCH_ONE_MASTER_SCHEDULE_MATERIALS_FAILURE: "FETCH_ONE_MASTER_SCHEDULE_MATERIALS_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_MATERIALS_SUCCESS: "FETCH_ONE_MASTER_SCHEDULE_MATERIALS_SUCCESS",
};
