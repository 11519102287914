import { isString, isEmpty, isNumber, toNumber } from "lodash";

class BuildingMasterScheduleCashflow {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    let month_data: any[] = [];
    // Iterate Through Each Row
    this.data.forEach((col: any[], index) => {
      //Check if its The Start of the Excel File and set the index of the Start Row
      if (this.isTableState(col)) {
        start = index;
        month_data.push(
          { month: col[4] },
          { month: col[5] },
          { month: col[6] },
          { month: col[7] },
          { month: col[8] }
        );
      }
      // If its the Start of Excel Data
      else if (start !== -1) {
        // Identify the Row Type
        if (col[0] && col[1]) {
          if (col[4]) {
            this.parsed.push({
              key: this.parsed.length,
              description: col[1],
              total: col[2],
              percentage: col[3] * 100,
              month: month_data[0].month,
              month_value: col[4],
              is_super_title: false,
            });
          }
          if (col[5]) {
            this.parsed.push({
              key: this.parsed.length,
              description: col[1],
              total: col[2],
              percentage: col[3] * 100,
              month: month_data[1].month,
              month_value: col[5],
              is_super_title: false,
            });
          }
          if (col[6]) {
            this.parsed.push({
              key: this.parsed.length,
              description: col[1],
              total: col[2],
              percentage: col[3] * 100,
              month: month_data[2].month,
              month_value: col[6],
              is_super_title: false,
            });
          }
          if (col[7]) {
            this.parsed.push({
              key: this.parsed.length,
              description: col[1],
              total: col[2],
              percentage: col[3] * 100,
              month: month_data[3].month,
              month_value: col[7],
              is_super_title: false,
            });
          }
          if (col[8]) {
            this.parsed.push({
              key: this.parsed.length,
              description: col[1],
              total: col[2],
              percentage: col[3] * 100,
              month: month_data[4].month,
              month_value: col[8],
              is_super_title: false,
            });
          }
        }
       else if (
          (
            isString(col[1]) &&
            this.isSuperTitle(col)
          )
        ) {
          this.parsed.push({
            key: this.parsed.length,
            description: col[1],
            is_super_title: true,
          });
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    //check if col 0 to col 5 is a string
    return (
      isString(col[0]) &&
      isString(col[1]) &&
      isString(col[2]) &&
      isString(col[4]) &&
      isString(col[5]) &&
      isString(col[6]) &&
      isString(col[7]) &&
      isString(col[8])
    );
  }

  private isSuperTitle(col: any[]) {
    if (col[1]) {
      let split = col[1].trim().split(".")[0];
      if (split && split.length === 1) {
        return true;
      } else return false;
    } else return false;
  }
}
export default BuildingMasterScheduleCashflow;
