import { SubcontractorRequestStateTypes, SubcontractorRequestActionTypes } from "./SubcontractorRequest.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubcontractorRequestStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubcontractorRequestReducer = (
  state: SubcontractorRequestStateTypes = INITIAL_STATE,
  action: any
): SubcontractorRequestStateTypes => {
  switch (action.type) {
    case SubcontractorRequestActionTypes.FETCH_ALL_SUBCONTRACTOR_REQUEST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorRequestActionTypes.FETCH_ALL_SUBCONTRACTOR_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorRequestActionTypes.FETCH_ALL_SUBCONTRACTOR_REQUEST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorRequestActionTypes.FETCH_ALL_SUBCONTRACTOR_REQUEST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubcontractorRequestActionTypes.FETCH_ONE_SUBCONTRACTOR_REQUEST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorRequestActionTypes.FETCH_ONE_SUBCONTRACTOR_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorRequestActionTypes.FETCH_ONE_SUBCONTRACTOR_REQUEST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorRequestActionTypes.FETCH_ONE_SUBCONTRACTOR_REQUEST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubcontractorRequestReducer;
