import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterScheduleMachineryActionTypes } from "./MasterScheduleMachinery.type";

export function* fetchAllMasterScheduleMachinerys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/master-schedule-machinery?master_schedule_updated_id=${action.payload?.master_schedule_updated_id}`);
    yield put({
      type: MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterScheduleMachinerys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-machinery/${action.payload}`
    );
    yield put({
      type: MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterScheduleMachinerys() {
  yield takeLatest(MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY, fetchAllMasterScheduleMachinerys);
}

export function* watcherFetchOneMasterScheduleMachinerys() {
  yield takeLatest(MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY, fetchOneMasterScheduleMachinerys);
}
