import { Department } from "../Department/Department.type";
import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PurchaseRequisition = {
  material_requisition_id: number;
  requested_to: string;
  department_id?: number;
  project_id?: number;
  project?: Project;
  department?: Department;
  date: string;
  id: number;
  remark: string;
  purchase_place: string;
  purchase_requisition_items: PurchaseRequisitionItem[];
  requested_by: string;
  pr_prepared_by?: User;
  purchase_requisition_statuses: PurchaseRequisitionStatus[];
  is_done: boolean;
};

export type PurchaseRequisitionStatus = {
  purchase_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PurchaseRequisitionItem = {
  id: any;
  material_id: number;
  material: Material;
  quantity: number;
};

export type PurchaseRequisitionStateTypes = {
  fetchAll: ApiCallState<PurchaseRequisition[]>;
  fetchOne: ApiCallState<PurchaseRequisition | {}>;
};

export const PurchaseRequisitionActionTypes = {
  FETCH_ALL_PURCHASE_REQUISITION: "FETCH_ALL_PURCHASE_REQUISITION",
  FETCH_ALL_PURCHASE_REQUISITION_RESET: "FETCH_ALL_PURCHASE_REQUISITION_RESET",
  FETCH_ALL_PURCHASE_REQUISITION_FAILURE:
    "FETCH_ALL_PURCHASE_REQUISITION_FAILURE",
  FETCH_ALL_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_ALL_PURCHASE_REQUISITION_SUCCESS",

  FETCH_ONE_PURCHASE_REQUISITION: "FETCH_ONE_PURCHASE_REQUISITION",
  FETCH_ONE_PURCHASE_REQUISITION_RESET: "FETCH_ONE_PURCHASE_REQUISITION_RESET",
  FETCH_ONE_PURCHASE_REQUISITION_FAILURE:
    "FETCH_ONE_PURCHASE_REQUISITION_FAILURE",
  FETCH_ONE_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_ONE_PURCHASE_REQUISITION_SUCCESS",
};
