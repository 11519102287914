import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type SubcontractorRequest= {
    id: number;
    project_id:number;
    date:string;
    subcontractor_request_views:SubcontractorRequestView[];
    subcontractor_request_approveds:SubcontractorRequestApproved[];
    subcontractor_request_checkeds:SubcontractorRequestChecked[];
    subcontractor_request_items:SubcontractorRequestItem[];
    subcontractor_request_documents:SubcontractorRequestDocument[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type SubcontractorRequestView ={
    id:number;
    subcontractor_request_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SubcontractorRequestChecked ={
    id:number;
    subcontractor_request_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SubcontractorRequestApproved ={
    id:number;
    subcontractor_request_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SubcontractorRequestItem ={
    id:number,
    subcontractor_request_id:number;
    work_category:string;
    license_grade:string;
    work_quantity:number;
    expected_duration:number;
    recommendation:string;
    experience:string;
    remark:string;
    createdAt: any;
    updatedAt: any;
  }

  export type SubcontractorRequestDocument ={
    id:number,
    subcontractor_request_id:number;
    document_id:number;
    document:Document;
    createdAt: any;
    updatedAt: any;
  }

export type SubcontractorRequestStateTypes = {
  fetchAll: ApiCallState<SubcontractorRequest[]>;
  fetchOne: ApiCallState<SubcontractorRequest | {}>;
};

export const SubcontractorRequestActionTypes = {
  FETCH_ALL_SUBCONTRACTOR_REQUEST: "FETCH_ALL_SUBCONTRACTOR_REQUEST",
  FETCH_ALL_SUBCONTRACTOR_REQUEST_RESET: "FETCH_ALL_SUBCONTRACTOR_REQUEST_RESET",
  FETCH_ALL_SUBCONTRACTOR_REQUEST_FAILURE: "FETCH_ALL_SUBCONTRACTOR_REQUEST_FAILURE",
  FETCH_ALL_SUBCONTRACTOR_REQUEST_SUCCESS: "FETCH_ALL_SUBCONTRACTOR_REQUEST_SUCCESS",

  FETCH_ONE_SUBCONTRACTOR_REQUEST: "FETCH_ONE_SUBCONTRACTOR_REQUEST",
  FETCH_ONE_SUBCONTRACTOR_REQUEST_RESET: "FETCH_ONE_SUBCONTRACTOR_REQUEST_RESET",
  FETCH_ONE_SUBCONTRACTOR_REQUEST_FAILURE: "FETCH_ONE_SUBCONTRACTOR_REQUEST_FAILURE",
  FETCH_ONE_SUBCONTRACTOR_REQUEST_SUCCESS: "FETCH_ONE_SUBCONTRACTOR_REQUEST_SUCCESS",
};
