import { AllureMaterialRequestItem } from "../AllureMaterialRequest/AllureMaterialRequest.type";
import { ApiCallState } from "../Utils";

export type AllureResourceApproval= {
  id: number;
  project_id:number;
  allure_material_request_id:number;
  allure_material_request_item_id:number;
  production:number;
  delivered:number;
  remark:string;
  user_id: number;
  allure_material_request_item:AllureMaterialRequestItem;
};

export type AllureResourceApprovalStateTypes = {
  fetchAll: ApiCallState<AllureResourceApproval[]>;
  fetchOne: ApiCallState<AllureResourceApproval | {}>;
};

export const AllureResourceApprovalActionTypes = {
  FETCH_ALL_ALLURE_RESOURCE_APPROVAL: "FETCH_ALL_ALLURE_RESOURCE_APPROVAL",
  FETCH_ALL_ALLURE_RESOURCE_APPROVAL_RESET: "FETCH_ALL_ALLURE_RESOURCE_APPROVAL_RESET",
  FETCH_ALL_ALLURE_RESOURCE_APPROVAL_FAILURE: "FETCH_ALL_ALLURE_RESOURCE_APPROVAL_FAILURE",
  FETCH_ALL_ALLURE_RESOURCE_APPROVAL_SUCCESS: "FETCH_ALL_ALLURE_RESOURCE_APPROVAL_SUCCESS",

  FETCH_ONE_ALLURE_RESOURCE_APPROVAL: "FETCH_ONE_ALLURE_RESOURCE_APPROVAL",
  FETCH_ONE_ALLURE_RESOURCE_APPROVAL_RESET: "FETCH_ONE_ALLURE_RESOURCE_APPROVAL_RESET",
  FETCH_ONE_ALLURE_RESOURCE_APPROVAL_FAILURE: "FETCH_ONE_ALLURE_RESOURCE_APPROVAL_FAILURE",
  FETCH_ONE_ALLURE_RESOURCE_APPROVAL_SUCCESS: "FETCH_ONE_ALLURE_RESOURCE_APPROVAL_SUCCESS",
};
