import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TenderOpeningActionTypes } from "./TenderOpening.type";

export function* fetchAllTenderOpenings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/tender-opening?tender_id=${action.payload?.tender_id}`);
    yield put({
      type: TenderOpeningActionTypes.FETCH_ALL_TENDER_OPENING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TenderOpeningActionTypes.FETCH_ALL_TENDER_OPENING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTenderOpenings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/tender-opening/${action.payload}`
    );
    yield put({
      type: TenderOpeningActionTypes.FETCH_ONE_TENDER_OPENING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TenderOpeningActionTypes.FETCH_ONE_TENDER_OPENING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTenderOpenings() {
  yield takeLatest(TenderOpeningActionTypes.FETCH_ALL_TENDER_OPENING, fetchAllTenderOpenings);
}

export function* watcherFetchOneTenderOpenings() {
  yield takeLatest(TenderOpeningActionTypes.FETCH_ONE_TENDER_OPENING, fetchOneTenderOpenings);
}
