import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialConsumptionActionTypes } from "./MaterialConsumption.type";

export function* fetchAllMaterialConsumptions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-consumption?project_id=${action.payload?.project_id}`);
    yield put({
      type: MaterialConsumptionActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialConsumptionActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialConsumptions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-consumption/${action.payload}`
    );
    yield put({
      type: MaterialConsumptionActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialConsumptionActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialConsumptions() {
  yield takeLatest(MaterialConsumptionActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION, fetchAllMaterialConsumptions);
}

export function* watcherFetchOneMaterialConsumptions() {
  yield takeLatest(MaterialConsumptionActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION, fetchOneMaterialConsumptions);
}
