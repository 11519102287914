import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialDatabaseItemActionTypes } from "./MaterialDatabaseItem.type";

export function* fetchAllMaterialDatabaseItems(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/md-material-item?mdcategory_id=${action.payload?.mdcategory_id}&year=${action.payload?.year}`);
    yield put({
      type: MaterialDatabaseItemActionTypes.FETCH_ALL_MATERIAL_DATABASE_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDatabaseItemActionTypes.FETCH_ALL_MATERIAL_DATABASE_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialDatabaseItems(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/md-material-item/${action.payload}`
    );
    yield put({
      type: MaterialDatabaseItemActionTypes.FETCH_ONE_MATERIAL_DATABASE_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDatabaseItemActionTypes.FETCH_ONE_MATERIAL_DATABASE_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialDatabaseItems() {
  yield takeLatest(MaterialDatabaseItemActionTypes.FETCH_ALL_MATERIAL_DATABASE_ITEM, fetchAllMaterialDatabaseItems);
}

export function* watcherFetchOneMaterialDatabaseItems() {
  yield takeLatest(MaterialDatabaseItemActionTypes.FETCH_ONE_MATERIAL_DATABASE_ITEM, fetchOneMaterialDatabaseItems);
}
