import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialCatagoryActionTypes } from "./MaterialCatagory.type";

export function* fetchAllMaterialCatagorys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-catagory`);
    yield put({
      type: MaterialCatagoryActionTypes.FETCH_ALL_MATERIAL_CATAGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialCatagoryActionTypes.FETCH_ALL_MATERIAL_CATAGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialCatagorys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-catagory/${action.payload}`
    );
    yield put({
      type: MaterialCatagoryActionTypes.FETCH_ONE_MATERIAL_CATAGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialCatagoryActionTypes.FETCH_ONE_MATERIAL_CATAGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialCatagorys() {
  yield takeLatest(
    MaterialCatagoryActionTypes.FETCH_ALL_MATERIAL_CATAGORY,
    fetchAllMaterialCatagorys
  );
}

export function* watcherFetchOneMaterialCatagorys() {
  yield takeLatest(
    MaterialCatagoryActionTypes.FETCH_ONE_MATERIAL_CATAGORY,
    fetchOneMaterialCatagorys
  );
}
