import { ApiCallState } from "../Utils";
import { Moment } from "moment";
import { HumanResourceMobilizationItem, ResourceMobilizationItem } from "../ResourceMobilization/ResourceMobilization.type";

export type EquipmentDemobiliation= {
    id: number | null;
    project_id: number | null;
    date:Moment;
    report_no:string;
    to:number;
    note:string;
    user_id:number;
    equipment_demobiliation_details:EquipmentDemobiliationDetail[];
    human_resource_demobilization_items: HumanResourceDemobilizationItem[];
    equipment_demobiliation_views: EquipmentDemobiliationView[];
    equipment_demobiliation_approveds: EquipmentDemobiliationApproved[];
    equipment_demobiliation_checkeds: EquipmentDemobiliationChecked[];
};
export type EquipmentDemobiliationDetail= {
    id: number | null;
    equipment_demobiliation_id:number | null;
    resource_mobilization_item_id:number;
    type_of_equipment:string;
    unit:string;
    quantity:number;
    reason:string;
    reporting_date:Moment;
    demobiliation_date:Moment;
    request_for_replacement:string;
    voucher_no:string;
    resource_mobilization_item: ResourceMobilizationItem;
}

export type EquipmentDemobiliationView = {
  id: number;
  equipment_demobiliation_id:number;
  user_id: number;
  sender_id: number;
  createdAt: any;
  updatedAt: any;
};

export type EquipmentDemobiliationChecked = {
  id: number;
  equipment_demobiliation_id:number;
  user_id: number;
  sender_id: number;
  is_checked: boolean;
  is_revised: boolean;
  createdAt: any;
  updatedAt: any;
};

export type EquipmentDemobiliationApproved = {
  id: number;
  equipment_demobiliation_id:number;
  user_id: number;
  sender_id: number;
  is_approved: boolean;
  is_revised: boolean;
  createdAt: any;
  updatedAt: any;
};

export type HumanResourceDemobilizationItem={
  id: number;
  equipment_demobiliation_id:number;
  human_resource_mobilization_item_id:number;
  no:number;
  date:string;
  human_resource_mobilization_item: HumanResourceMobilizationItem;
}

export type EquipmentDemobiliationStateTypes = {
  fetchAll: ApiCallState<EquipmentDemobiliation[]>;
  fetchOne: ApiCallState<EquipmentDemobiliation | {}>;
};

export const EquipmentDemobiliationActionTypes = {
  FETCH_ALL_EQUIPMENT_DEMOBILIATION: "FETCH_ALL_EQUIPMENT_DEMOBILIATION",
  FETCH_ALL_EQUIPMENT_DEMOBILIATION_RESET: "FETCH_ALL_EQUIPMENT_DEMOBILIATION_RESET",
  FETCH_ALL_EQUIPMENT_DEMOBILIATION_FAILURE: "FETCH_ALL_EQUIPMENT_DEMOBILIATION_FAILURE",
  FETCH_ALL_EQUIPMENT_DEMOBILIATION_SUCCESS: "FETCH_ALL_EQUIPMENT_DEMOBILIATION_SUCCESS",

  FETCH_ONE_EQUIPMENT_DEMOBILIATION: "FETCH_ONE_EQUIPMENT_DEMOBILIATION",
  FETCH_ONE_EQUIPMENT_DEMOBILIATION_RESET: "FETCH_ONE_EQUIPMENT_DEMOBILIATION_RESET",
  FETCH_ONE_EQUIPMENT_DEMOBILIATION_FAILURE: "FETCH_ONE_EQUIPMENT_DEMOBILIATION_FAILURE",
  FETCH_ONE_EQUIPMENT_DEMOBILIATION_SUCCESS: "FETCH_ONE_EQUIPMENT_DEMOBILIATION_SUCCESS",
};
