import { ApiCallState } from "../Utils";

export type MasterScheduleSummarys = {
  id: number;
  master_schedule_updated_id: number;
  description: string;
  total: number;
  percentage: number;
  month: string;
  month_value: number;
  is_title: boolean;
  user_id: number;
};

export type MasterScheduleSummarysStateTypes = {
  fetchAll: ApiCallState<MasterScheduleSummarys[]>;
  fetchOne: ApiCallState<MasterScheduleSummarys | {}>;
};

export const MasterScheduleSummarysActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_SUMMARYS: "FETCH_ALL_MASTER_SCHEDULE_SUMMARYS",
  FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_RESET:
    "FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_RESET",
  FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_FAILURE:
    "FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_SUCCESS:
    "FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_SUMMARYS: "FETCH_ONE_MASTER_SCHEDULE_SUMMARYS",
  FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_RESET:
    "FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_RESET",
  FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_FAILURE:
    "FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_SUCCESS:
    "FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_SUCCESS",
};
