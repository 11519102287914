// export const APP_URL = "http://localhost:3000";
// export const BASE_URI = "http://localhost:4000";
// export const API_BASE_URI = "http://localhost:4000/api";

// export const BASE_URI = "https://project.condigital.cloud:3000";

export const APP_URL = "https://allure.condigital.cloud";
export const BASE_URI = "https://allure.condigital.cloud:3000";
export const API_BASE_URI = "https://allure.condigital.cloud:3000/api";

// export const APP_URL = "https://workspace.condigital.cloud";
// export const BASE_URI = "https://workspace.condigital.cloud:3000";
// export const API_BASE_URI = "https://workspace.condigital.cloud:3000/api";
