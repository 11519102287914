import { SiteVisitChecklistStateTypes, SiteVisitChecklistActionTypes } from "./SiteVisitChecklist.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SiteVisitChecklistStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SiteVisitChecklistReducer = (
  state: SiteVisitChecklistStateTypes = INITIAL_STATE,
  action: any
): SiteVisitChecklistStateTypes => {
  switch (action.type) {
    case SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SiteVisitChecklistReducer;
