import { MaterialCatagory } from "../MaterialCatagory/MaterialCatagory.type";
// import { MaterialStore } from "../MaterialStore/MaterialStore.type";
import { ApiCallState } from "../Utils";

export type MDMaterial = {
  id: number;
  description: string;
  mdcatagory: MaterialCatagory;
  mdmaterial_items: MDMaterialItem[];
};

export type MDMaterialItem = {
  id: number;
  is_subtitle: boolean;
  description: string;
  unit: string;
  mdmaterialItemPrices: MDMaterialItemPrice[];
 // material_store?: MaterialStore;
};

type MDMaterialItemPrice = {
  year: number;
  month: number;
  price: number;
  attachement: string;
};

export type MDMaterialStateTypes = {
  fetchAll: ApiCallState<MDMaterial[]>;
  fetchOne: ApiCallState<MDMaterial | {}>;
};

export const MDMaterialActionTypes = {
  FETCH_ALL_MDMATERIAL: "FETCH_ALL_MDMATERIAL",
  FETCH_ALL_MDMATERIAL_RESET: "FETCH_ALL_MDMATERIAL_RESET",
  FETCH_ALL_MDMATERIAL_FAILURE: "FETCH_ALL_MDMATERIAL_FAILURE",
  FETCH_ALL_MDMATERIAL_SUCCESS: "FETCH_ALL_MDMATERIAL_SUCCESS",

  FETCH_ONE_MDMATERIAL: "FETCH_ONE_MDMATERIAL",
  FETCH_ONE_MDMATERIAL_RESET: "FETCH_ONE_MDMATERIAL_RESET",
  FETCH_ONE_MDMATERIAL_FAILURE: "FETCH_ONE_MDMATERIAL_FAILURE",
  FETCH_ONE_MDMATERIAL_SUCCESS: "FETCH_ONE_MDMATERIAL_SUCCESS",
};
