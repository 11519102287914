import { ApiCallState } from "../Utils";

export type IndirectCost= {
    id: number;
    date:string;
    project_id:number;
    indirect_cost_views:IndirectCostView[];
    indirect_cost_approveds:IndirectCostApproved[];
    indirect_cost_checkeds:IndirectCostChecked[];
    indirect_cost_items:IndirectCostItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};


export type IndirectCostView ={
    id:number;
    indirect_cost_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type IndirectCostChecked ={
    id:number;
    indirect_cost_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type IndirectCostApproved ={
    id:number;
    indirect_cost_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type IndirectCostItem ={
    id:number,
    indirect_cost_id:number;
    date:string;
    description:string;
    unit:string;
    quantity: number;
    unit_price:number;
    createdAt: any;
    updatedAt: any;
  }

export type IndirectCostStateTypes = {
  fetchAll: ApiCallState<IndirectCost[]>;
  fetchOne: ApiCallState<IndirectCost | {}>;
};

export const IndirectCostActionTypes = {
  FETCH_ALL_INDIRECT_COST: "FETCH_ALL_INDIRECT_COST",
  FETCH_ALL_INDIRECT_COST_RESET: "FETCH_ALL_INDIRECT_COST_RESET",
  FETCH_ALL_INDIRECT_COST_FAILURE: "FETCH_ALL_INDIRECT_COST_FAILURE",
  FETCH_ALL_INDIRECT_COST_SUCCESS: "FETCH_ALL_INDIRECT_COST_SUCCESS",

  FETCH_ONE_INDIRECT_COST: "FETCH_ONE_INDIRECT_COST",
  FETCH_ONE_INDIRECT_COST_RESET: "FETCH_ONE_INDIRECT_COST_RESET",
  FETCH_ONE_INDIRECT_COST_FAILURE: "FETCH_ONE_INDIRECT_COST_FAILURE",
  FETCH_ONE_INDIRECT_COST_SUCCESS: "FETCH_ONE_INDIRECT_COST_SUCCESS",
};
