import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TenderActionTypes } from "./Tender.type";

export function* fetchAllTenders(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/tender?date=${action.payload?.date}`);
    yield put({
      type: TenderActionTypes.FETCH_ALL_TENDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TenderActionTypes.FETCH_ALL_TENDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTenders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/tender/${action.payload}`
    );
    yield put({
      type: TenderActionTypes.FETCH_ONE_TENDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TenderActionTypes.FETCH_ONE_TENDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTenders() {
  yield takeLatest(TenderActionTypes.FETCH_ALL_TENDER, fetchAllTenders);
}

export function* watcherFetchOneTenders() {
  yield takeLatest(TenderActionTypes.FETCH_ONE_TENDER, fetchOneTenders);
}
