import { isString, isEmpty, isNumber, toNumber } from "lodash";

class BuildingMasterScheduleMaterial {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    let month_data: any[] = [];
    // Iterate Through Each Row
    this.data.forEach((col: any[], index) => {
      //Check if its The Start of the Excel File and set the index of the Start Row
      if (this.isTableState(col)) {
        start = index;
        month_data.push(
          { month: col[7] },
          { month: col[8] },
          { month: col[9] },
          { month: col[10] },
          { month: col[11] }
        );
      }
      // If its the Start of Excel Data
      else if (start !== -1) {
        // Identify the Row Type
        if (col[1]) {
          if (col[7]) {
            this.parsed.push({
              key: this.parsed.length,
              material: col[1],
              unit:col[2],
              submission:col[3],
              approval :col[4],
              ordering:col[5],
              total: col[6],
              month: month_data[0].month,
              month_value: col[7],
            });
          }
          if (col[8]) {
            this.parsed.push({
              key: this.parsed.length,
              material: col[1],
              unit:col[2],
              submission:col[3],
              approval :col[4],
              ordering:col[5],
              total: col[6],
              month: month_data[1].month,
              month_value: col[8],
            });
          }
          if (col[9]) {
            this.parsed.push({
              key: this.parsed.length,
              material: col[1],
              unit:col[2],
              submission:col[3],
              approval :col[4],
              ordering:col[5],
              total: col[6],
              month: month_data[2].month,
              month_value: col[9],
            });
          }
          if (col[10]) {
            this.parsed.push({
              key: this.parsed.length,
              material: col[1],
              unit:col[2],
              submission:col[3],
              approval :col[4],
              ordering:col[5],
              total: col[6],
              month: month_data[3].month,
              month_value: col[10],
            });
          }
          if (col[11]) {
            this.parsed.push({
              key: this.parsed.length,
              material: col[1],
              unit:col[2],
              submission:col[3],
              approval :col[4],
              ordering:col[5],
              total: col[6],
              month: month_data[4].month,
              month_value: col[11],
            });
          }
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    //check if col 7 to col 11 is a string
    return (
      isString(col[7]) &&
      isString(col[8]) &&
      isString(col[9]) &&
      isString(col[10]) &&
      isString(col[11]) 
    );
  }
}
export default BuildingMasterScheduleMaterial;
