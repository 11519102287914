import { PlanSummaryOfNextDayStateTypes, PlanSummaryOfNextDayActionTypes } from "./PlanSummaryOfNextDay.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PlanSummaryOfNextDayStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PlanSummaryOfNextDayReducer = (
  state: PlanSummaryOfNextDayStateTypes = INITIAL_STATE,
  action: any
): PlanSummaryOfNextDayStateTypes => {
  switch (action.type) {
    case PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PlanSummaryOfNextDayReducer;
