import { Boq } from "../Boq/Boq.type";
import { FinanceDailyReportItem } from "../FinanceDailyReport/FinanceDailyReport.type";
import { ApiCallState } from "../Utils";

export type AllureLabourUtilization = {
  id: number;
  project_id: number;
  week_start: string;
  week_end: string;
  man_hr: number;
  allure_labour_utilization_views: AllureLabourUtilizationView[];
  allure_labour_utilization_approveds: AllureLabourUtilizationApproved[];
  allure_labour_utilization_checkeds: AllureLabourUtilizationChecked[];
  allure_labour_utilization_items: AllureLabourUtilizationItem[];
  user_id: number;
  createdAt: any;
  updatedAt: any;
};

export type AllureLabourUtilizationView = {
  id: number;
  allure_labour_utilization_id: number;
  user_id: number;
  sender_id: number;
  createdAt: any;
  updatedAt: any;
};

export type AllureLabourUtilizationChecked = {
  id: number;
  allure_labour_utilization_id: number;
  user_id: number;
  sender_id: number;
  is_checked: boolean;
  is_revised: boolean;
  createdAt: any;
  updatedAt: any;
};

export type AllureLabourUtilizationApproved = {
  id: number;
  allure_labour_utilization_id: number;
  user_id: number;
  sender_id: number;
  is_approved: boolean;
  is_revised: boolean;
  createdAt: any;
  updatedAt: any;
};

export type AllureLabourUtilizationItem = {
  id: number;
  allure_labour_utilization_id: number;
  finance_daily_report_item_id: number;
  boq_id: number;
  boq?: Boq;
  finance_daily_report_item?: FinanceDailyReportItem;
  quantity_performed:number;
  targeted_performance_rate: number;
  standard_skilled: number;
  standard_unskilled: number;
  actual_utilized_skilled: number;
  actual_utilized_unskilled: number;
  remark: string;
};

export type AllureLabourUtilizationStateTypes = {
  fetchAll: ApiCallState<AllureLabourUtilization[]>;
  fetchOne: ApiCallState<AllureLabourUtilization | {}>;
};

export const AllureLabourUtilizationActionTypes = {
  FETCH_ALL_ALLURE_LABOUR_UTILIZATION: "FETCH_ALL_ALLURE_LABOUR_UTILIZATION",
  FETCH_ALL_ALLURE_LABOUR_UTILIZATION_RESET:
    "FETCH_ALL_ALLURE_LABOUR_UTILIZATION_RESET",
  FETCH_ALL_ALLURE_LABOUR_UTILIZATION_FAILURE:
    "FETCH_ALL_ALLURE_LABOUR_UTILIZATION_FAILURE",
  FETCH_ALL_ALLURE_LABOUR_UTILIZATION_SUCCESS:
    "FETCH_ALL_ALLURE_LABOUR_UTILIZATION_SUCCESS",

  FETCH_ONE_ALLURE_LABOUR_UTILIZATION: "FETCH_ONE_ALLURE_LABOUR_UTILIZATION",
  FETCH_ONE_ALLURE_LABOUR_UTILIZATION_RESET:
    "FETCH_ONE_ALLURE_LABOUR_UTILIZATION_RESET",
  FETCH_ONE_ALLURE_LABOUR_UTILIZATION_FAILURE:
    "FETCH_ONE_ALLURE_LABOUR_UTILIZATION_FAILURE",
  FETCH_ONE_ALLURE_LABOUR_UTILIZATION_SUCCESS:
    "FETCH_ONE_ALLURE_LABOUR_UTILIZATION_SUCCESS",
};
