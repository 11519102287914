import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ContractualIssueActionTypes } from "./ContractualIssue.type";

export function* fetchAllContractualIssues(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/contractual-issue?project_id=${action.payload?.project_id}`);
    yield put({
      type: ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneContractualIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contractual-issue/${action.payload}`
    );
    yield put({
      type: ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllContractualIssues() {
  yield takeLatest(ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE, fetchAllContractualIssues);
}

export function* watcherFetchOneContractualIssues() {
  yield takeLatest(ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE, fetchOneContractualIssues);
}
