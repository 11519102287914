import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type TenderDocuments= {
  id: number;
  tender_id:number;
  document:Document;
  description:string;
  user_id: number;
};

export type TenderDocumentsStateTypes = {
  fetchAll: ApiCallState<TenderDocuments[]>;
  fetchOne: ApiCallState<TenderDocuments | {}>;
};

export const TenderDocumentsActionTypes = {
  FETCH_ALL_TENDER_DOCUMENTS: "FETCH_ALL_TENDER_DOCUMENTS",
  FETCH_ALL_TENDER_DOCUMENTS_RESET: "FETCH_ALL_TENDER_DOCUMENTS_RESET",
  FETCH_ALL_TENDER_DOCUMENTS_FAILURE: "FETCH_ALL_TENDER_DOCUMENTS_FAILURE",
  FETCH_ALL_TENDER_DOCUMENTS_SUCCESS: "FETCH_ALL_TENDER_DOCUMENTS_SUCCESS",

  FETCH_ONE_TENDER_DOCUMENTS: "FETCH_ONE_TENDER_DOCUMENTS",
  FETCH_ONE_TENDER_DOCUMENTS_RESET: "FETCH_ONE_TENDER_DOCUMENTS_RESET",
  FETCH_ONE_TENDER_DOCUMENTS_FAILURE: "FETCH_ONE_TENDER_DOCUMENTS_FAILURE",
  FETCH_ONE_TENDER_DOCUMENTS_SUCCESS: "FETCH_ONE_TENDER_DOCUMENTS_SUCCESS",
};
