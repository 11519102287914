import { MasterScheduleSummarysStateTypes, MasterScheduleSummarysActionTypes } from "./MasterScheduleSummarys.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MasterScheduleSummarysStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MasterScheduleSummarysReducer = (
  state: MasterScheduleSummarysStateTypes = INITIAL_STATE,
  action: any
): MasterScheduleSummarysStateTypes => {
  switch (action.type) {
    case MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MasterScheduleSummarysReducer;
