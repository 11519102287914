import { BankAccount } from "../BankAccount/BankAccount.type";
import { CheckListItem } from "../CheckList/CheckList.type";
import { CompanyLoan } from "../CompanyLoan/CompanyLoan.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type AccountTransaction = {
  id: number;
  user_id: number;
  bank_account_id: number;
  from_bank_account_id: number;
  project_id: number;

  company_loan_id: number; // +|-
 
  payee: string;
  pv_crv_number: string;
  check_number: string;

  csi_number: string;
  crv: string;
  fs_number: string;

  transaction_type: string;
  amount: number;
  code: string;
  description: string;
  date: Date;

  bank_account: BankAccount;
  from_bank_account: BankAccount;
  project: Project;
  company_loan: CompanyLoan;

  check_list_items: CheckListItem[];
  account_transaction_checks: AccountTransactionCheck[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AccountTransactionCheck = {
  id: number;
  user_id: number;
  account_transaction_id: number;

  receipt_number: string;
  receipt_url: string;

  grv_number: string;

  with_hold_number: string;
  with_hold_amount: number;

  customer_name: string;

  vat: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AccountTransactionAnalysis = {
  total_duration_deposit: number;
  total_this_year_deposit: number;
  total_last_year_deposit: number;
  total_duration_withdraw: number;
  total_this_year_withdraw: number;
  total_last_year_withdraw: number;
  annual_month_graph_data: {
    month: string;
    total_deposit: number;
    total_withdraw: number;
  }[];
};

export type AccountTransactionStateTypes = {
  fetchAll: ApiCallState<AccountTransaction[]>;
  fetchAllAll: ApiCallState<AccountTransaction[]>;
  fetchAnalysis: ApiCallState<AccountTransactionAnalysis | null>;
};

export const AccountTransactionActionTypes = {
  FETCH_ALL_ACCOUNT_TRANSACTION: "FETCH_ALL_ACCOUNT_TRANSACTION",
  FETCH_ALL_ACCOUNT_TRANSACTION_RESET: "FETCH_ALL_ACCOUNT_TRANSACTION_RESET",
  FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE:
    "FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE",
  FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS",

  FETCH_ALL_ALL_ACCOUNT_TRANSACTION: "FETCH_ALL_ALL_ACCOUNT_TRANSACTION",
  FETCH_ALL_ALL_ACCOUNT_TRANSACTION_RESET:
    "FETCH_ALL_ALL_ACCOUNT_TRANSACTION_RESET",
  FETCH_ALL_ALL_ACCOUNT_TRANSACTION_FAILURE:
    "FETCH_ALL_ALL_ACCOUNT_TRANSACTION_FAILURE",
  FETCH_ALL_ALL_ACCOUNT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ALL_ACCOUNT_TRANSACTION_SUCCESS",

  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS: "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS",
  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_RESET:
    "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_RESET",
  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_FAILURE:
    "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_FAILURE",
  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_SUCCESS:
    "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_SUCCESS",
};
