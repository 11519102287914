import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractorSiteHandoverActionTypes } from "./SubContractorSiteHandover.type";

export function* fetchAllSubContractorSiteHandovers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/subcontractor-site-handover?subcontract_registry_id=${action.payload?.subcontract_registry_id}`);
    yield put({
      type: SubContractorSiteHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractorSiteHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractorSiteHandovers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/subcontractor-site-handover/${action.payload}`
    );
    yield put({
      type: SubContractorSiteHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractorSiteHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractorSiteHandovers() {
  yield takeLatest(SubContractorSiteHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER, fetchAllSubContractorSiteHandovers);
}

export function* watcherFetchOneSubContractorSiteHandovers() {
  yield takeLatest(SubContractorSiteHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER, fetchOneSubContractorSiteHandovers);
}
