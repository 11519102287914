import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureEquipmentRequestActionTypes } from "./AllureEquipmentRequest.type";

export function* fetchAllAllureEquipmentRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-equipment-request?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllureEquipmentRequestActionTypes.FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureEquipmentRequestActionTypes.FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureEquipmentRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-equipment-request/${action.payload}`
    );
    yield put({
      type: AllureEquipmentRequestActionTypes.FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureEquipmentRequestActionTypes.FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureEquipmentRequests() {
  yield takeLatest(AllureEquipmentRequestActionTypes.FETCH_ALL_ALLURE_EQUIPMENT_REQUEST, fetchAllAllureEquipmentRequests);
}

export function* watcherFetchOneAllureEquipmentRequests() {
  yield takeLatest(AllureEquipmentRequestActionTypes.FETCH_ONE_ALLURE_EQUIPMENT_REQUEST, fetchOneAllureEquipmentRequests);
}
