import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeReviewActionTypes } from "./EmployeeReview.type";
import { authHeader } from "../../utilities/utilities";

export function* fetchAllEmployeeReview(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee-review`,
      authHeader()
    );
    yield put({
      type: EmployeeReviewActionTypes.FETCH_ALL_EMPLOYEE_REVIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeReviewActionTypes.FETCH_ALL_EMPLOYEE_REVIEW_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeReview() {
  yield takeLatest(
    EmployeeReviewActionTypes.FETCH_ALL_EMPLOYEE_REVIEW,
    fetchAllEmployeeReview
  );
}
