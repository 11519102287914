import Menu from "antd/lib/menu";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../router/Constants";
import { useEffect, useState } from "react";
import { HRTabs } from "../../../constants/Constants";
import { checkAuthorization } from "../../../utilities/utilities";

const HeaderMenuComponent = () => {
  const history = useHistory();
  const [selected, setSelected]: any = useState();
  const [openKeys, setOpenKeys] = useState(["project"]);
  const rootSubmenuKeys = ["project", "sub_contract"];

  useEffect(() => {
    setSelected(history.location.pathname);
  }, [history.location.pathname]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className="hidden-print header-menu">
      <Menu
        {...(!localStorage.getItem("/")
          ? { openKeys: openKeys, onOpenChange: onOpenChange }
          : null)}
        mode="horizontal"
        selectedKeys={[selected]}
        defaultSelectedKeys={["project"]}
        onSelect={(e: any) => history.replace(e.key.toString())}
        theme="light"
        // className="side_bar"
      >
        {/*checkAuthorization(RouteConstants.DASHBOARDS) ? (
          <Menu.Item
            key={RouteConstants.DASHBOARDS}
            // icon={<HomeOutlined />}
            title="dashboard"
          >
            Dashboard
          </Menu.Item>
        ) : null*/}

        {checkAuthorization(RouteConstants.REGISTER_PROJECT) ? (
          <Menu.Item key={RouteConstants.PROJECTS} title="Project">
            Project
          </Menu.Item>
        ) : checkAuthorization(RouteConstants.PROJECT_LIST) ? (
          <Menu.Item
            key={RouteConstants.PROJECTS}
            // icon={<AppstoreAddOutlined />}
            title="Project"
          >
            Projects
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.HUMAN_RESOURCE) ? (
          <Menu.Item
            key={"/hr/" + HRTabs.DEPARTMENT.toLowerCase()}
            // icon={<UserOutlined />}
            title="Human Resource"
          >
            Human Resource
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.PROCUREMENT) ? (
          <Menu.Item
            key={RouteConstants.PROCUREMENT}
            // icon={<ReconciliationOutlined />}
            title="procurement"
          >
            Procurement
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.INVENTORY) ? (
          <Menu.Item
            key={RouteConstants.INVENTORY}
            // icon={<HddOutlined />}
            title="inventory"
          >
            Inventory
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.TENDER) ? (
          <Menu.Item key={RouteConstants.TENDER} title="Tender">
            Tender
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.DATABASE) ? (
          <Menu.Item
            key={RouteConstants.DATABASE}
            // icon={<DollarOutlined />}
            title="Database"
          >
            Database
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.FINANCE) ? (
          <Menu.Item key={RouteConstants.FINANCE} title="Finance">
            Finance
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.SITE_VISIT) ? (
          <Menu.Item key={RouteConstants.SITE_VISIT} title="Site Visit">
            Site Visit
          </Menu.Item>
        ) : null}

        {/*checkAuthorization(RouteConstants.DOCUMENT) ? (
          <Menu.Item
            key={RouteConstants.DOCUMENT}
            // icon={<FolderAddOutlined />}
            title="Documents"
          >
            Documents
          </Menu.Item>
        ) : null*/}

        {/*checkAuthorization(RouteConstants.SUB_CONTRACT_LIST) ? (
          <Menu.Item
            key={RouteConstants.SUB_CONTRACT_LIST}
            // icon={<FolderAddOutlined />}
            title="Sub Contract"
          >
            Sub Contract
          </Menu.Item>
        ) : null*/}

        {/*checkAuthorization(RouteConstants.REPORT) ? (
          <Menu.Item
            key={RouteConstants.REPORT}
            // icon={<HddOutlined />}
            title="Report"
          >
            Reports
          </Menu.Item>
        ) : null*/}

        {/*checkAuthorization(RouteConstants.INVENTORY) ? (
          <Menu.Item
            key={RouteConstants.INVENTORY}
            // icon={<HddOutlined />}
            title="inventory"
          >
            Inventory
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.EQUIPMENT) ? (
          <Menu.Item
            key={RouteConstants.EQUIPMENT}
            // icon={<VehicleTruckProfile style={{ width: "14px" }} />}
            title="Fixed Asset"
          >
            Fixed Asset
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.FINANCE) ? (
          <Menu.Item key={RouteConstants.FINANCE} title="Finance">
            Finance
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.OPERATION) ? (
          <Menu.Item key={RouteConstants.OPERATION} title="Operation">
            Operation
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.MIRAB_REPORT) ? (
          <Menu.Item key={RouteConstants.MIRAB_REPORT} title="Report">
            Report
          </Menu.Item>
        ) : null}

        {checkAuthorization(RouteConstants.TASK) ? (
          <Menu.Item key={RouteConstants.TASK} title="Task">
            Task
          </Menu.Item>
        ) : null*/}
      </Menu>
    </div>
  );
};
export default HeaderMenuComponent;
