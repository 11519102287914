import { ApiCallState } from "../Utils";

export type ResourceMobilization = {
  id: number;
  project_id: number;
  delivery_period: string;
  resource_mobilization_views: ResourceMobilizationView[];
  resource_mobilization_approveds: ResourceMobilizationApproved[];
  resource_mobilization_checkeds: ResourceMobilizationChecked[];
  resource_mobilization_items: ResourceMobilizationItem[];
  human_resource_mobilization_items: HumanResourceMobilizationItem[];
  user_id: number;
  createdAt: any;
  updatedAt: any;
};

export type ResourceMobilizationView ={
    id:number;
    resource_mobilization_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type ResourceMobilizationChecked ={
    id:number;
    resource_mobilization_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type ResourceMobilizationApproved ={
    id:number;
    resource_mobilization_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type ResourceMobilizationItem ={
    id:number,
    resource_mobilization_id:number;
    category:string;
    material_id:number;
    unit:string;
    quantity:number;
    delivery_date:string;
    transportation_means:string;
    purpose:string;
    responsible_person:string;
    remark:string;
    createdAt: any,
    updatedAt: any
  }

  export type HumanResourceMobilizationItem ={
    id:number,
    resource_mobilization_id:number;
    description:string;
    no:number;
    date:string;
    createdAt: any;
    updatedAt: any;
  }

export type ResourceMobilizationStateTypes = {
  fetchAll: ApiCallState<ResourceMobilization[]>;
  fetchOne: ApiCallState<ResourceMobilization | {}>;
};

export const ResourceMobilizationActionTypes = {
  FETCH_ALL_RESOURCE_MOBILIZATION: "FETCH_ALL_RESOURCE_MOBILIZATION",
  FETCH_ALL_RESOURCE_MOBILIZATION_RESET:
    "FETCH_ALL_RESOURCE_MOBILIZATION_RESET",
  FETCH_ALL_RESOURCE_MOBILIZATION_FAILURE:
    "FETCH_ALL_RESOURCE_MOBILIZATION_FAILURE",
  FETCH_ALL_RESOURCE_MOBILIZATION_SUCCESS:
    "FETCH_ALL_RESOURCE_MOBILIZATION_SUCCESS",

  FETCH_ONE_RESOURCE_MOBILIZATION: "FETCH_ONE_RESOURCE_MOBILIZATION",
  FETCH_ONE_RESOURCE_MOBILIZATION_RESET:
    "FETCH_ONE_RESOURCE_MOBILIZATION_RESET",
  FETCH_ONE_RESOURCE_MOBILIZATION_FAILURE:
    "FETCH_ONE_RESOURCE_MOBILIZATION_FAILURE",
  FETCH_ONE_RESOURCE_MOBILIZATION_SUCCESS:
    "FETCH_ONE_RESOURCE_MOBILIZATION_SUCCESS",
};
