import { ApiCallState } from "../Utils";

export type SiteVisitChecklist= {
    id: number;
    project_id:number;
    date:string;
    purpose_of_visit:string;
    suggestions_by_visitor:string;
    actions_taken:string;
    problem_encountered:string;
    site_visit_checklist_views:SiteVisitChecklistView[];
    site_visit_checklist_approveds:SiteVisitChecklistApproved[];
    site_visit_checklist_checkeds:SiteVisitChecklistChecked[];
    site_visit_checklist_items:SiteVisitChecklistItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};


export type SiteVisitChecklistItem ={
    id:number,
    site_visit_checklist_id:number;
    check_list:boolean;
    category:string;
    area:string;
    suggested_method:string;
    createdAt: any;
    updatedAt: any;
  }

export type SiteVisitChecklistView ={
    id:number;
    site_visit_checklist_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SiteVisitChecklistChecked ={
    id:number;
    site_visit_checklist_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SiteVisitChecklistApproved ={
    id:number;
    site_visit_checklist_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }

export type SiteVisitChecklistStateTypes = {
  fetchAll: ApiCallState<SiteVisitChecklist[]>;
  fetchOne: ApiCallState<SiteVisitChecklist | {}>;
};

export const SiteVisitChecklistActionTypes = {
  FETCH_ALL_SITE_VISIT_CHECKLIST: "FETCH_ALL_SITE_VISIT_CHECKLIST",
  FETCH_ALL_SITE_VISIT_CHECKLIST_RESET: "FETCH_ALL_SITE_VISIT_CHECKLIST_RESET",
  FETCH_ALL_SITE_VISIT_CHECKLIST_FAILURE: "FETCH_ALL_SITE_VISIT_CHECKLIST_FAILURE",
  FETCH_ALL_SITE_VISIT_CHECKLIST_SUCCESS: "FETCH_ALL_SITE_VISIT_CHECKLIST_SUCCESS",

  FETCH_ONE_SITE_VISIT_CHECKLIST: "FETCH_ONE_SITE_VISIT_CHECKLIST",
  FETCH_ONE_SITE_VISIT_CHECKLIST_RESET: "FETCH_ONE_SITE_VISIT_CHECKLIST_RESET",
  FETCH_ONE_SITE_VISIT_CHECKLIST_FAILURE: "FETCH_ONE_SITE_VISIT_CHECKLIST_FAILURE",
  FETCH_ONE_SITE_VISIT_CHECKLIST_SUCCESS: "FETCH_ONE_SITE_VISIT_CHECKLIST_SUCCESS",
};
