import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type SubContractorSiteHandover= {
  id: number;
  subcontract_registry_id:number;
  date:string;
  document:Document;
};

export type SubContractorSiteHandoverStateTypes = {
  fetchAll: ApiCallState<SubContractorSiteHandover[]>;
  fetchOne: ApiCallState<SubContractorSiteHandover | {}>;
};

export const SubContractorSiteHandoverActionTypes = {
  FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER: "FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER",
  FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_RESET: "FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_RESET",
  FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_FAILURE: "FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_FAILURE",
  FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_SUCCESS: "FETCH_ALL_SUBCONTRACTOR_SITE_HANDOVER_SUCCESS",

  FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER: "FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER",
  FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_RESET: "FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_RESET",
  FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_FAILURE: "FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_FAILURE",
  FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_SUCCESS: "FETCH_ONE_SUBCONTRACTOR_SITE_HANDOVER_SUCCESS",
};
