import { ApiCallState } from "../Utils";

export type MasterScheduleCashflow= {
    id: number;
    master_schedule_updated_id: number;
    description: string;
    total: number;
    percentage: number;
    month: string;
    month_value: number;
    is_title: boolean;
    is_super_title: boolean;
    user_id: number;
};

export type MasterScheduleCashflowStateTypes = {
  fetchAll: ApiCallState<MasterScheduleCashflow[]>;
  fetchOne: ApiCallState<MasterScheduleCashflow | {}>;
};

export const MasterScheduleCashflowActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_CASHFLOW: "FETCH_ALL_MASTER_SCHEDULE_CASHFLOW",
  FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_RESET: "FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_RESET",
  FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_FAILURE: "FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_SUCCESS: "FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_CASHFLOW: "FETCH_ONE_MASTER_SCHEDULE_CASHFLOW",
  FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_RESET: "FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_RESET",
  FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_FAILURE: "FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_SUCCESS: "FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_SUCCESS",
};
