import { ApiCallState } from "../Utils";

export type SiteVisitSchedule= {
    id: number;
    date:string;
    site_visit_schedule_views:SiteVisitScheduleView[];
    site_visit_schedule_approveds:SiteVisitScheduleApproved[];
    site_visit_schedule_checkeds:SiteVisitScheduleChecked[];
    site_visit_schedule_items:SiteVisitScheduleItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type SiteVisitScheduleItem ={
    id:number,
    site_visit_schedule_id:number;
    name:string;
    destination:string;
    date:string;
    morning:string;
    afternoon:string;
    remark:string;
    visitors:string;
    createdAt: any;
    updatedAt: any;
  }

export type SiteVisitScheduleView ={
    id:number;
    site_visit_schedule_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SiteVisitScheduleChecked ={
    id:number;
    site_visit_schedule_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type SiteVisitScheduleApproved ={
    id:number;
    site_visit_schedule_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  

export type SiteVisitScheduleStateTypes = {
  fetchAll: ApiCallState<SiteVisitSchedule[]>;
  fetchOne: ApiCallState<SiteVisitSchedule | {}>;
};

export const SiteVisitScheduleActionTypes = {
  FETCH_ALL_SITE_VISIT_SCHEDULE: "FETCH_ALL_SITE_VISIT_SCHEDULE",
  FETCH_ALL_SITE_VISIT_SCHEDULE_RESET: "FETCH_ALL_SITE_VISIT_SCHEDULE_RESET",
  FETCH_ALL_SITE_VISIT_SCHEDULE_FAILURE: "FETCH_ALL_SITE_VISIT_SCHEDULE_FAILURE",
  FETCH_ALL_SITE_VISIT_SCHEDULE_SUCCESS: "FETCH_ALL_SITE_VISIT_SCHEDULE_SUCCESS",

  FETCH_ONE_SITE_VISIT_SCHEDULE: "FETCH_ONE_SITE_VISIT_SCHEDULE",
  FETCH_ONE_SITE_VISIT_SCHEDULE_RESET: "FETCH_ONE_SITE_VISIT_SCHEDULE_RESET",
  FETCH_ONE_SITE_VISIT_SCHEDULE_FAILURE: "FETCH_ONE_SITE_VISIT_SCHEDULE_FAILURE",
  FETCH_ONE_SITE_VISIT_SCHEDULE_SUCCESS: "FETCH_ONE_SITE_VISIT_SCHEDULE_SUCCESS",
};
