import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type AllurePaymentSummary= {
    id: number;
    project_id:number;
    payment_type:string;
    submission_date:string;
    payment_date:string;
    payment_amount:number;
    retention_amount:number;
    repayment_amount:number;
    document:Document;
    allure_payment_summary_views:AllurePaymentSummaryView[];
    allure_payment_summary_approveds:AllurePaymentSummaryApproved[];
    allure_payment_summary_checkeds:AllurePaymentSummaryChecked[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type AllurePaymentSummaryView ={
    id:number;
    allure_payment_summary_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllurePaymentSummaryChecked ={
    id:number;
    allure_payment_summary_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllurePaymentSummaryApproved ={
    id:number;
    allure_payment_summary_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }

export type AllurePaymentSummaryStateTypes = {
  fetchAll: ApiCallState<AllurePaymentSummary[]>;
  fetchOne: ApiCallState<AllurePaymentSummary | {}>;
};

export const AllurePaymentSummaryActionTypes = {
  FETCH_ALL_ALLURE_PAYMENT_SUMMARY: "FETCH_ALL_ALLURE_PAYMENT_SUMMARY",
  FETCH_ALL_ALLURE_PAYMENT_SUMMARY_RESET: "FETCH_ALL_ALLURE_PAYMENT_SUMMARY_RESET",
  FETCH_ALL_ALLURE_PAYMENT_SUMMARY_FAILURE: "FETCH_ALL_ALLURE_PAYMENT_SUMMARY_FAILURE",
  FETCH_ALL_ALLURE_PAYMENT_SUMMARY_SUCCESS: "FETCH_ALL_ALLURE_PAYMENT_SUMMARY_SUCCESS",

  FETCH_ONE_ALLURE_PAYMENT_SUMMARY: "FETCH_ONE_ALLURE_PAYMENT_SUMMARY",
  FETCH_ONE_ALLURE_PAYMENT_SUMMARY_RESET: "FETCH_ONE_ALLURE_PAYMENT_SUMMARY_RESET",
  FETCH_ONE_ALLURE_PAYMENT_SUMMARY_FAILURE: "FETCH_ONE_ALLURE_PAYMENT_SUMMARY_FAILURE",
  FETCH_ONE_ALLURE_PAYMENT_SUMMARY_SUCCESS: "FETCH_ONE_ALLURE_PAYMENT_SUMMARY_SUCCESS",
};
