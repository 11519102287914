import { AllureWeeklyPlanItem } from "../AllureWeeklyPlan/AllureWeeklyPlan.type";
import { ApiCallState } from "../Utils";

export type AllureWeeklySchedule= {
    id: number;
    project_id:number;
    week_start:string;
    week_end:string;
    remark:string;
    allure_weekly_schedule_views:AllureWeeklyScheduleView[];
    allure_weekly_schedule_approveds:AllureWeeklyScheduleApproved[];
    allure_weekly_schedule_checkeds:AllureWeeklyScheduleChecked[];
    allure_weekly_schedule_items:AllureWeeklyScheduleItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type AllureWeeklyScheduleView ={
    id:number;
    allure_weekly_schedule_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureWeeklyScheduleChecked ={
    id:number;
    allure_weekly_schedule_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureWeeklyScheduleApproved ={
    id:number;
    allure_weekly_schedule_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureWeeklyScheduleItem ={
    id:number,
    allure_weekly_schedule_id:number;
    allure_weekly_plan_item_id:number;
    allure_weekly_plan_item:AllureWeeklyPlanItem;
    is_title:boolean,
    resource: string,
    type: string,
    unit: string;
    monday:number;
    tuesday:number;
    wednesday:number;
    thursday:number;
    friday:number;
    saturday:number;
    sunday:number;
    createdAt: any,
    updatedAt: any
  }

export type AllureWeeklyScheduleStateTypes = {
  fetchAll: ApiCallState<AllureWeeklySchedule[]>;
  fetchOne: ApiCallState<AllureWeeklySchedule | {}>;
};

export const AllureWeeklyScheduleActionTypes = {
  FETCH_ALL_ALLURE_WEEKLY_SCHEDULE: "FETCH_ALL_ALLURE_WEEKLY_SCHEDULE",
  FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_RESET: "FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_RESET",
  FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_FAILURE: "FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_FAILURE",
  FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_SUCCESS: "FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_SUCCESS",

  FETCH_ONE_ALLURE_WEEKLY_SCHEDULE: "FETCH_ONE_ALLURE_WEEKLY_SCHEDULE",
  FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_RESET: "FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_RESET",
  FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_FAILURE: "FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_FAILURE",
  FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_SUCCESS: "FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_SUCCESS",
};
