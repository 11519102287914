import { ApiCallState } from "../Utils";

export type UnitBreakDownRate = {
  id: any;
  project_id: number;
  cartaway_cost: number;
  transport_cost: number;
  fluctuation_cost: number;
  overhead_cost: number;
  profit_cost: number;
  type?: string;
};

export type UnitBreakDownRateStateTypes = {
  fetchAll: ApiCallState<UnitBreakDownRate[]>;
  fetchOne: ApiCallState<UnitBreakDownRate | {}>;
};

export const UnitBreakDownRateActionTypes = {
  FETCH_ALL_UNIT_BREAKDOWN_RATE: "FETCH_ALL_UNIT_BREAKDOWN_RATE",
  FETCH_ALL_UNIT_BREAKDOWN_RATE_RESET: "FETCH_ALL_UNIT_BREAKDOWN_RATE_RESET",
  FETCH_ALL_UNIT_BREAKDOWN_RATE_FAILURE:
    "FETCH_ALL_UNIT_BREAKDOWN_RATE_FAILURE",
  FETCH_ALL_UNIT_BREAKDOWN_RATE_SUCCESS:
    "FETCH_ALL_UNIT_BREAKDOWN_RATE_SUCCESS",

  FETCH_ONE_UNIT_BREAKDOWN_RATE: "FETCH_ONE_UNIT_BREAKDOWN_RATE",
  FETCH_ONE_UNIT_BREAKDOWN_RATE_RESET: "FETCH_ONE_UNIT_BREAKDOWN_RATE_RESET",
  FETCH_ONE_UNIT_BREAKDOWN_RATE_FAILURE:
    "FETCH_ONE_UNIT_BREAKDOWN_RATE_FAILURE",
  FETCH_ONE_UNIT_BREAKDOWN_RATE_SUCCESS:
    "FETCH_ONE_UNIT_BREAKDOWN_RATE_SUCCESS",
};
