import { AllureWeeklyScheduleStateTypes, AllureWeeklyScheduleActionTypes } from "./AllureWeeklySchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AllureWeeklyScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AllureWeeklyScheduleReducer = (
  state: AllureWeeklyScheduleStateTypes = INITIAL_STATE,
  action: any
): AllureWeeklyScheduleStateTypes => {
  switch (action.type) {
    case AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllureWeeklyScheduleActionTypes.FETCH_ALL_ALLURE_WEEKLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllureWeeklyScheduleActionTypes.FETCH_ONE_ALLURE_WEEKLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AllureWeeklyScheduleReducer;
