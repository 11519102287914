import { ApiCallState } from "../Utils";
import { AllureMonthlyPlanItem } from "../AllureMonthlyPlan/AllureMonthlyPlan.type";

export type BudgetRequest = {
  id: number;
  project_id: number;
  date: string;
  request_no:string;
  justification:string;
  budget_month:string;
  budget_request_items: BudgetRequestItem[];
  budget_request_views:BudgetRequestView[];
  budget_request_approveds:BudgetRequestApproved[];
  budget_request_checkeds:BudgetRequestChecked[];
  user_id:number;
  createdAt: any;
  updatedAt: any;
};

export type BudgetRequestItem = {
  id: number;
  budget_request_id: number;
  allure_monthly_plan_item_id:number;
  is_title:boolean;
  resource: string;
  type: string;
  unit: string;
  unit_rate:number;
  quantity: number;
  no_of_days_elapsed: number;
  allure_monthly_plan_item:AllureMonthlyPlanItem;
  createdAt: any;
  updatedAt: any;
};

export type BudgetRequestChecked ={
  id:number;
  budget_request_id: number;
  user_id: number;
  sender_id:number;
  is_checked:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type BudgetRequestApproved ={
  id:number;
  budget_request_id:number;
  user_id: number;
  sender_id:number;
  is_approved:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type BudgetRequestView ={
  id:number;
  budget_request_id:number;
  user_id: number;
  sender_id:number;
  createdAt: any;
  updatedAt: any;
}

export type BudgetRequestStateTypes = {
  fetchAll: ApiCallState<BudgetRequest[]>;
  fetchOne: ApiCallState<BudgetRequest | {}>;
};

export const BudgetRequestActionTypes = {
  FETCH_ALL_BUDGET_REQUEST: "FETCH_ALL_BUDGET_REQUEST",
  FETCH_ALL_BUDGET_REQUEST_RESET: "FETCH_ALL_BUDGET_REQUEST_RESET",
  FETCH_ALL_BUDGET_REQUEST_FAILURE: "FETCH_ALL_BUDGET_REQUEST_FAILURE",
  FETCH_ALL_BUDGET_REQUEST_SUCCESS: "FETCH_ALL_BUDGET_REQUEST_SUCCESS",

  FETCH_ONE_BUDGET_REQUEST: "FETCH_ONE_BUDGET_REQUEST",
  FETCH_ONE_BUDGET_REQUEST_RESET: "FETCH_ONE_BUDGET_REQUEST_RESET",
  FETCH_ONE_BUDGET_REQUEST_FAILURE: "FETCH_ONE_BUDGET_REQUEST_FAILURE",
  FETCH_ONE_BUDGET_REQUEST_SUCCESS: "FETCH_ONE_BUDGET_REQUEST_SUCCESS",
};
