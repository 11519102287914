import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PlanSummaryOfNextDayActionTypes } from "./PlanSummaryOfNextDay.type";

export function* fetchAllPlanSummaryOfNextDays(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/plan-summary-of-next-day?date=${action.payload?.date}&project_id=${action.payload?.project_id}`);
    yield put({
      type: PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePlanSummaryOfNextDays(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/plan-summary-of-next-day/one?date=${action.payload?.date}&project_id=${action.payload?.project_id}`
    );
    yield put({
      type: PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPlanSummaryOfNextDays() {
  yield takeLatest(PlanSummaryOfNextDayActionTypes.FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY, fetchAllPlanSummaryOfNextDays);
}

export function* watcherFetchOnePlanSummaryOfNextDays() {
  yield takeLatest(PlanSummaryOfNextDayActionTypes.FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY, fetchOnePlanSummaryOfNextDays);
}
