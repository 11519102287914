import { ApiCallState } from "../Utils";

export type PlanSummaryOfNextDay= {
  id: number;
  project_id:number;
  date:string;
  remark:string;
  user_id:number;
  ps_material_consumption_items:PSMaterialConsumptionItem[],
  ps_material_balance_items:PSMaterialBalanceItem[],
  ps_labour_utilized_items:PSLabourUtilizedItem[],
  ps_equipment_utilized_items: PSEquipmentUtilizedItem[],
  ps_fuel_utilized_items: PSFuelUtilizedItem[],
  ps_permanent_manpower_items:PSPermanentManpowerItem[],
  ps_subcontract_utilized_items:PSSubcontractUtilizedItem[],
  ps_plan_for_next_day_items:PSPlanForNextDayItem[],
};

export type PSEquipmentUtilizedItem = {
    id?: number;
    plan_summary_of_next_day_id?:number;
    description: string;
    ownership: string;
    total_working_hour: number;
    down: number;
    no_of_equipment: number;
    rate: number;
  };
  
  export type PSFuelUtilizedItem = {
    id?: number;
    plan_summary_of_next_day_id?:number;
    description: string;
    unit: string;
    quantity: number;
    rate: number;
  };

  export type PSLabourUtilizedItem ={
    id?: number;
    plan_summary_of_next_day_id?:number;
    position:string;
    no_labour:number;
    rate:number;
  }
 
  export type PSMaterialBalanceItem = {
    id?: number;
    plan_summary_of_next_day_id?:number;
    description: string;
    unit: string;
    quantity: number;
    rate: number;
  };

  export type PSMaterialConsumptionItem ={
    id?: number;
    plan_summary_of_next_day_id?:number;
    description: string;
    unit: string;
    quantity: number;
    rate: number;
  }

  export type PSPermanentManpowerItem = {
    id?: number;
    plan_summary_of_next_day_id?:number;
    position:string;
    no_labour:number;
    rate:number;
  }
  
  export type PSPlanForNextDayItem ={
    id?: number;
    plan_summary_of_next_day_id?:number;
    description:string;
    unit:string;
    quantity:number;
    rate:number
    remark:string;
  }

  export type PSSubcontractUtilizedItem ={
    id?: number;
    plan_summary_of_next_day_id?:number;
    description:string;
    name_of_subcontractor:string;
    unit:string;
    quantity:number;
    rate:number;
  }

export type PlanSummaryOfNextDayStateTypes = {
  fetchAll: ApiCallState<PlanSummaryOfNextDay[]>;
  fetchOne: ApiCallState<PlanSummaryOfNextDay | {}>;
};

export const PlanSummaryOfNextDayActionTypes = {
  FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY: "FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY",
  FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_RESET: "FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_RESET",
  FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE: "FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE",
  FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS: "FETCH_ALL_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS",

  FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY: "FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY",
  FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_RESET: "FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_RESET",
  FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE: "FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_FAILURE",
  FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS: "FETCH_ONE_PLAN_SUMMARY_OF_NEXT_DAY_SUCCESS",
};
