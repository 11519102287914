import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GoodReturnActionTypes } from "./GoodReturn.type";

export function* fetchAllGoodReturn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-return?inventory_type=${action.payload.inventory_type}`
    );
    yield put({
      type: GoodReturnActionTypes.FETCH_ALL_GOOD_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReturnActionTypes.FETCH_ALL_GOOD_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodReturn() {
  yield takeLatest(
    GoodReturnActionTypes.FETCH_ALL_GOOD_RETURN,
    fetchAllGoodReturn
  );
}
