import { ApiCallState } from "../Utils";

export type Tender= {
  id: number;
  date:string;
  project_name:string;
  name_of_client:string;
  invited_category:string;
  type_of_press:string;
  purchase_date:string;
  submission_date:string;
  deadline_date:string;
  type_of_bond:string;
  bond_amount:number;
  status:string;
  remark:string;
  user_id: number;
};

export type TenderStateTypes = {
  fetchAll: ApiCallState<Tender[]>;
  fetchOne: ApiCallState<Tender | {}>;
};

export const TenderActionTypes = {
  FETCH_ALL_TENDER: "FETCH_ALL_TENDER",
  FETCH_ALL_TENDER_RESET: "FETCH_ALL_TENDER_RESET",
  FETCH_ALL_TENDER_FAILURE: "FETCH_ALL_TENDER_FAILURE",
  FETCH_ALL_TENDER_SUCCESS: "FETCH_ALL_TENDER_SUCCESS",

  FETCH_ONE_TENDER: "FETCH_ONE_TENDER",
  FETCH_ONE_TENDER_RESET: "FETCH_ONE_TENDER_RESET",
  FETCH_ONE_TENDER_FAILURE: "FETCH_ONE_TENDER_FAILURE",
  FETCH_ONE_TENDER_SUCCESS: "FETCH_ONE_TENDER_SUCCESS",
};
