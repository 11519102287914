import { MasterScheduleCashflowStateTypes, MasterScheduleCashflowActionTypes } from "./MasterScheduleCashflow.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MasterScheduleCashflowStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MasterScheduleCashflowReducer = (
  state: MasterScheduleCashflowStateTypes = INITIAL_STATE,
  action: any
): MasterScheduleCashflowStateTypes => {
  switch (action.type) {
    case MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MasterScheduleCashflowReducer;
