import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { UnitBreakDownRateActionTypes } from "./UnitBreakDownRate.type";

export function* fetchAllUnitBreakDownRates(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/unit-breakdown-rate`);
    yield put({
      type: UnitBreakDownRateActionTypes.FETCH_ALL_UNIT_BREAKDOWN_RATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UnitBreakDownRateActionTypes.FETCH_ALL_UNIT_BREAKDOWN_RATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneUnitBreakDownRates(action: any): any {
  try {
    let query = {};
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/unit-breakdown-rate/one?${query}`
    );
    yield put({
      type: UnitBreakDownRateActionTypes.FETCH_ONE_UNIT_BREAKDOWN_RATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UnitBreakDownRateActionTypes.FETCH_ONE_UNIT_BREAKDOWN_RATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllUnitBreakDownRates() {
  yield takeLatest(
    UnitBreakDownRateActionTypes.FETCH_ALL_UNIT_BREAKDOWN_RATE,
    fetchAllUnitBreakDownRates
  );
}

export function* watcherFetchOneUnitBreakDownRates() {
  yield takeLatest(
    UnitBreakDownRateActionTypes.FETCH_ONE_UNIT_BREAKDOWN_RATE,
    fetchOneUnitBreakDownRates
  );
}
