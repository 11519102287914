import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqActionTypes } from "./Boq.type";

export function* fetchAllBoqs(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_ALL_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_ALL_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoq(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/boq/${action.payload}`);
    yield put({
      type: BoqActionTypes.FETCH_ONE_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_ONE_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchDetailBoq(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq/detail?project_id=${action.payload}`
    );
    yield put({
      type: BoqActionTypes.FETCH_DETAIL_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_DETAIL_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchUnitBoq(action: any): any {
  let query = "";
  if (action.payload) {
    const keys = Object.keys(action.payload);
    query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
  }
  try {
    const response = yield axios.get(`${API_BASE_URI}/boq/unit?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_UNIT_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_UNIT_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchSheetNames(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq/sheet-name?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_SHEET_NAME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_SHEET_NAME_FAILURE,
      payload: error,
    });
  }
}

export function* fetchBoqTotal(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq/total?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_BOQ_TOTAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_BOQ_TOTAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchBoqPaged(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq/paged?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_PAGED_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_PAGED_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoq() {
  yield takeLatest(BoqActionTypes.FETCH_ALL_BOQ, fetchAllBoqs);
}

export function* watcherFetchOneBoq() {
  yield takeLatest(BoqActionTypes.FETCH_ONE_BOQ, fetchOneBoq);
}

export function* watcherFetchDetailBoq() {
  yield takeLatest(BoqActionTypes.FETCH_DETAIL_BOQ, fetchDetailBoq);
}

export function* watcherFetchUnitBoq() {
  yield takeLatest(BoqActionTypes.FETCH_UNIT_BOQ, fetchUnitBoq);
}

export function* watcherFetchSheetNames() {
  yield takeLatest(BoqActionTypes.FETCH_SHEET_NAME, fetchSheetNames);
}

export function* watcherFetchBoqTotal() {
  yield takeLatest(BoqActionTypes.FETCH_BOQ_TOTAL, fetchBoqTotal);
}

export function* watcherFetchPagedBoq() {
  yield takeLatest(BoqActionTypes.FETCH_PAGED_BOQ, fetchBoqPaged);
}
