import { MaterialConsumptionReportStateTypes, MaterialConsumptionReportActionTypes } from "./MaterialConsumptionReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialConsumptionReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MaterialConsumptionReportReducer = (
  state: MaterialConsumptionReportStateTypes = INITIAL_STATE,
  action: any
): MaterialConsumptionReportStateTypes => {
  switch (action.type) {
    case MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialConsumptionReportReducer;
