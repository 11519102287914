import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SiteVisitChecklistActionTypes } from "./SiteVisitChecklist.type";

export function* fetchAllSiteVisitChecklists(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/site-visit-checklist`);
    yield put({
      type: SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSiteVisitChecklists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/site-visit-checklist/${action.payload}`
    );
    yield put({
      type: SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSiteVisitChecklists() {
  yield takeLatest(SiteVisitChecklistActionTypes.FETCH_ALL_SITE_VISIT_CHECKLIST, fetchAllSiteVisitChecklists);
}

export function* watcherFetchOneSiteVisitChecklists() {
  yield takeLatest(SiteVisitChecklistActionTypes.FETCH_ONE_SITE_VISIT_CHECKLIST, fetchOneSiteVisitChecklists);
}
