import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterScheduleSummarysActionTypes } from "./MasterScheduleSummarys.type";

export function* fetchAllMasterScheduleSummaryss(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/master-schedule-summarys?master_schedule_updated_id=${action.payload?.master_schedule_updated_id}`);
    yield put({
      type: MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterScheduleSummaryss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-summarys/${action.payload}`
    );
    yield put({
      type: MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterScheduleSummaryss() {
  yield takeLatest(MasterScheduleSummarysActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUMMARYS, fetchAllMasterScheduleSummaryss);
}

export function* watcherFetchOneMasterScheduleSummaryss() {
  yield takeLatest(MasterScheduleSummarysActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUMMARYS, fetchOneMasterScheduleSummaryss);
}
