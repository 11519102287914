import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureResourceApprovalActionTypes } from "./AllureResourceApproval.type";

export function* fetchAllAllureResourceApprovals(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-resource-approval?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureResourceApprovals(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-resource-approval/${action.payload}`
    );
    yield put({
      type: AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureResourceApprovals() {
  yield takeLatest(AllureResourceApprovalActionTypes.FETCH_ALL_ALLURE_RESOURCE_APPROVAL, fetchAllAllureResourceApprovals);
}

export function* watcherFetchOneAllureResourceApprovals() {
  yield takeLatest(AllureResourceApprovalActionTypes.FETCH_ONE_ALLURE_RESOURCE_APPROVAL, fetchOneAllureResourceApprovals);
}
