import { ApiCallState } from "../Utils";

export type TemporaryAcceptance = {
  id: number;

  user_id: number;
  project_id: number;

  type: "Provisional" | "Final";

  location: string;
  date: string;

  dash_1: string;
  dash_2: string;
  dash_3: string;

  total_executed_supplied: string;
  final_acceptance_date: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  temporary_acceptance_contracts: TemporaryAcceptanceContract[];
  temporary_acceptance_representatives: TemporaryAcceptanceRepresentative[];
  temporary_acceptance_examined_items: TemporaryAcceptanceExaminedItem[];
  temporary_acceptance_informations: TemporaryAcceptanceInformation[];
  temporary_acceptance_deduction: TemporaryAcceptanceDeduction;
  temporary_acceptance_remarks: TemporaryAcceptanceRemark[];

  temporary_acceptance_views: TemporaryAcceptanceView[];
  temporary_acceptance_approveds: TemporaryAcceptanceApproved[];
  temporary_acceptance_checkeds: TemporaryAcceptanceChecked[];
};

export type TemporaryAcceptanceContract = {
  id: number;

  temporary_acceptance_id: number;

  contract: string;
  contract_number: string;
  date: string;
  amount: number;
  basis: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TemporaryAcceptanceRepresentative = {
  id: number;

  temporary_acceptance_id: number;

  representor: string;
  representee: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TemporaryAcceptanceInformation = {
  id: number;

  temporary_acceptance_id: number;

  title: string;
  information: string;
  type: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TemporaryAcceptanceExaminedItem = {
  id: number;

  temporary_acceptance_id: number;

  examined: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TemporaryAcceptanceDeduction = {
  id: number;

  temporary_acceptance_id: number;

  previous_payment: number;
  penalty_payment: number;
  rebate: number;
  total_deduction: number;
  retention: number;
  executed_payable: number;
  net_sum: number;
  performance_bond: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TemporaryAcceptanceRemark = {
  id: number;

  temporary_acceptance_id: number;

  remark: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TemporaryAcceptanceView = {
  id: number;
  temporary_acceptance_id: number;
  user_id: number;
  sender_id: number;
  createdAt: any;
  updatedAt: any;
};

export type TemporaryAcceptanceChecked = {
  id: number;
  temporary_acceptance_id: number;
  user_id: number;
  sender_id: number;
  is_checked: boolean;
  is_revised: boolean;
  createdAt: any;
  updatedAt: any;
};

export type TemporaryAcceptanceApproved = {
  id: number;
  temporary_acceptance_id: number;
  user_id: number;
  sender_id: number;
  is_approved: boolean;
  is_revised: boolean;
  createdAt: any;
  updatedAt: any;
};

export type TemporaryAcceptanceStateTypes = {
  fetchAll: ApiCallState<TemporaryAcceptance[]>;
  fetchOne: ApiCallState<TemporaryAcceptance | {}>;
};

export const TemporaryAcceptanceActionTypes = {
  FETCH_ALL_TEMPORARY_ACCEPTANCE: "FETCH_ALL_TEMPORARY_ACCEPTANCE",
  FETCH_ALL_TEMPORARY_ACCEPTANCE_RESET: "FETCH_ALL_TEMPORARY_ACCEPTANCE_RESET",
  FETCH_ALL_TEMPORARY_ACCEPTANCE_FAILURE:
    "FETCH_ALL_TEMPORARY_ACCEPTANCE_FAILURE",
  FETCH_ALL_TEMPORARY_ACCEPTANCE_SUCCESS:
    "FETCH_ALL_TEMPORARY_ACCEPTANCE_SUCCESS",

  FETCH_ONE_TEMPORARY_ACCEPTANCE: "FETCH_ONE_TEMPORARY_ACCEPTANCE",
  FETCH_ONE_TEMPORARY_ACCEPTANCE_RESET: "FETCH_ONE_TEMPORARY_ACCEPTANCE_RESET",
  FETCH_ONE_TEMPORARY_ACCEPTANCE_FAILURE:
    "FETCH_ONE_TEMPORARY_ACCEPTANCE_FAILURE",
  FETCH_ONE_TEMPORARY_ACCEPTANCE_SUCCESS:
    "FETCH_ONE_TEMPORARY_ACCEPTANCE_SUCCESS",
};
