import { ApiCallState } from "../Utils";

export type AllureWeeklyPlan= {
    id: number;
    project_id:number;
    week_start:string;
    week_end:string;
    remark:string;
    allure_weekly_plan_views:AllureWeeklyPlanView[];
    allure_weekly_plan_approveds:AllureWeeklyPlanApproved[];
    allure_weekly_plan_checkeds:AllureWeeklyPlanChecked[];
    allure_weekly_plan_items:AllureWeeklyPlanItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type AllureWeeklyPlanView ={
    id:number;
    allure_weekly_plan_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureWeeklyPlanChecked ={
    id:number;
    allure_weekly_plan_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureWeeklyPlanApproved ={
    id:number;
    allure_weekly_plan_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureWeeklyPlanItem ={
    id:number,
    allure_weekly_plan_id:number;
    item_no:string;
    description: string,
    contract_quantity: number,
    quantity: number,
    unit_price: number,
    monday:number;
    tuesday:number;
    wednesday:number;
    thursday:number;
    friday:number;
    saturday:number;
    sunday:number;
    createdAt: any,
    updatedAt: any
  }

export type AllureWeeklyPlanStateTypes = {
  fetchAll: ApiCallState<AllureWeeklyPlan[]>;
  fetchOne: ApiCallState<AllureWeeklyPlan | {}>;
};

export const AllureWeeklyPlanActionTypes = {
  FETCH_ALL_ALLURE_WEEKLY_PLAN: "FETCH_ALL_ALLURE_WEEKLY_PLAN",
  FETCH_ALL_ALLURE_WEEKLY_PLAN_RESET: "FETCH_ALL_ALLURE_WEEKLY_PLAN_RESET",
  FETCH_ALL_ALLURE_WEEKLY_PLAN_FAILURE: "FETCH_ALL_ALLURE_WEEKLY_PLAN_FAILURE",
  FETCH_ALL_ALLURE_WEEKLY_PLAN_SUCCESS: "FETCH_ALL_ALLURE_WEEKLY_PLAN_SUCCESS",

  FETCH_ONE_ALLURE_WEEKLY_PLAN: "FETCH_ONE_ALLURE_WEEKLY_PLAN",
  FETCH_ONE_ALLURE_WEEKLY_PLAN_RESET: "FETCH_ONE_ALLURE_WEEKLY_PLAN_RESET",
  FETCH_ONE_ALLURE_WEEKLY_PLAN_FAILURE: "FETCH_ONE_ALLURE_WEEKLY_PLAN_FAILURE",
  FETCH_ONE_ALLURE_WEEKLY_PLAN_SUCCESS: "FETCH_ONE_ALLURE_WEEKLY_PLAN_SUCCESS",
};
