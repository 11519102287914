import { SubcontractorRequest } from "../SubcontractorRequest/SubcontractorRequest.type";
import { SubcontractorSelectionItem } from "../SubcontractorSelection/SubcontractorSelection.type";
import { ApiCallState } from "../Utils";

export type SubContractRegistry= {
  id: number;
  project_id:number;
  date:string;
  subcontractor_selection_item_id:number;
  subcontractor_request_id:number;
  work_title:string;
  user_id:number;
  subcontractor_selection_item: SubcontractorSelectionItem;
  subcontractor_request:SubcontractorRequest;
  sub_contract_registry_items:SubContractRegistryItem[];
  sub_contract_registry_statuses:SubContractRegistryStatus[];
};

export type SubContractRegistryStatus = {
  sub_contract_registry_id:number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type SubContractRegistryItem= {
    id: number;
    sub_contract_registry_id:number;
    description:string;
    unit:string;
    contract_quantity:number;
    unit_price:number;
  };

export type SubContractRegistryStateTypes = {
  fetchAll: ApiCallState<SubContractRegistry[]>;
  fetchOne: ApiCallState<SubContractRegistry | {}>;
};

export const SubContractRegistryActionTypes = {
  FETCH_ALL_SUB_CONTRACT_REGISTRY: "FETCH_ALL_SUB_CONTRACT_REGISTRY",
  FETCH_ALL_SUB_CONTRACT_REGISTRY_RESET: "FETCH_ALL_SUB_CONTRACT_REGISTRY_RESET",
  FETCH_ALL_SUB_CONTRACT_REGISTRY_FAILURE: "FETCH_ALL_SUB_CONTRACT_REGISTRY_FAILURE",
  FETCH_ALL_SUB_CONTRACT_REGISTRY_SUCCESS: "FETCH_ALL_SUB_CONTRACT_REGISTRY_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_REGISTRY: "FETCH_ONE_SUB_CONTRACT_REGISTRY",
  FETCH_ONE_SUB_CONTRACT_REGISTRY_RESET: "FETCH_ONE_SUB_CONTRACT_REGISTRY_RESET",
  FETCH_ONE_SUB_CONTRACT_REGISTRY_FAILURE: "FETCH_ONE_SUB_CONTRACT_REGISTRY_FAILURE",
  FETCH_ONE_SUB_CONTRACT_REGISTRY_SUCCESS: "FETCH_ONE_SUB_CONTRACT_REGISTRY_SUCCESS",
};
