import { IndirectCostStateTypes, IndirectCostActionTypes } from "./IndirectCost.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: IndirectCostStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const IndirectCostReducer = (
  state: IndirectCostStateTypes = INITIAL_STATE,
  action: any
): IndirectCostStateTypes => {
  switch (action.type) {
    case IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default IndirectCostReducer;
