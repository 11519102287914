import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterScheduleMaterialsActionTypes } from "./MasterScheduleMaterials.type";

export function* fetchAllMasterScheduleMaterialss(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/master-schedule-materials?master_schedule_updated_id=${action.payload?.master_schedule_updated_id}`);
    yield put({
      type: MasterScheduleMaterialsActionTypes.FETCH_ALL_MASTER_SCHEDULE_MATERIALS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleMaterialsActionTypes.FETCH_ALL_MASTER_SCHEDULE_MATERIALS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterScheduleMaterialss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-materials/${action.payload}`
    );
    yield put({
      type: MasterScheduleMaterialsActionTypes.FETCH_ONE_MASTER_SCHEDULE_MATERIALS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleMaterialsActionTypes.FETCH_ONE_MASTER_SCHEDULE_MATERIALS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterScheduleMaterialss() {
  yield takeLatest(MasterScheduleMaterialsActionTypes.FETCH_ALL_MASTER_SCHEDULE_MATERIALS, fetchAllMasterScheduleMaterialss);
}

export function* watcherFetchOneMasterScheduleMaterialss() {
  yield takeLatest(MasterScheduleMaterialsActionTypes.FETCH_ONE_MASTER_SCHEDULE_MATERIALS, fetchOneMasterScheduleMaterialss);
}
