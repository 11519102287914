import { ApiCallState } from "../Utils";

export type MasterScheduleMachinery= {
    id: number;
    master_schedule_updated_id:number;
    type_of_equipment:string;
    max_qty:number;
    month:string;
    month_value:number;
    is_title:boolean;
    user_id: number;
};

export type MasterScheduleMachineryStateTypes = {
  fetchAll: ApiCallState<MasterScheduleMachinery[]>;
  fetchOne: ApiCallState<MasterScheduleMachinery | {}>;
};

export const MasterScheduleMachineryActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_MACHINERY: "FETCH_ALL_MASTER_SCHEDULE_MACHINERY",
  FETCH_ALL_MASTER_SCHEDULE_MACHINERY_RESET: "FETCH_ALL_MASTER_SCHEDULE_MACHINERY_RESET",
  FETCH_ALL_MASTER_SCHEDULE_MACHINERY_FAILURE: "FETCH_ALL_MASTER_SCHEDULE_MACHINERY_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_MACHINERY_SUCCESS: "FETCH_ALL_MASTER_SCHEDULE_MACHINERY_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_MACHINERY: "FETCH_ONE_MASTER_SCHEDULE_MACHINERY",
  FETCH_ONE_MASTER_SCHEDULE_MACHINERY_RESET: "FETCH_ONE_MASTER_SCHEDULE_MACHINERY_RESET",
  FETCH_ONE_MASTER_SCHEDULE_MACHINERY_FAILURE: "FETCH_ONE_MASTER_SCHEDULE_MACHINERY_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_MACHINERY_SUCCESS: "FETCH_ONE_MASTER_SCHEDULE_MACHINERY_SUCCESS",
};
