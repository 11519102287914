import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { InventoryActionTypes } from "./Inventory.type";

export function* fetchAllInventories(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/inventory?${query}`);
    yield put({
      type: InventoryActionTypes.FETCH_ALL_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ALL_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllQueriedInventories(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const material_ids = action?.payload?.material_ids || [];
      delete action.payload?.material_ids;

      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");

      if (material_ids.length > 0)
        query +=
          "&" +
          material_ids
            .map((material_id: any) => `material_ids=${material_id}`)
            .join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/queried?${query}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneInventory(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/${action.payload}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ONE_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ONE_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInventories() {
  yield takeLatest(
    InventoryActionTypes.FETCH_ALL_INVENTORY,
    fetchAllInventories
  );
}

export function* watcherFetchAllQueriedInventories() {
  yield takeLatest(
    InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY,
    fetchAllQueriedInventories
  );
}

export function* watcherFetchOneInventory() {
  yield takeLatest(InventoryActionTypes.FETCH_ONE_INVENTORY, fetchOneInventory);
}
