import {
  TemporaryAcceptanceStateTypes,
  TemporaryAcceptanceActionTypes,
} from "./TemporaryAcceptance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TemporaryAcceptanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const TemporaryAcceptanceReducer = (
  state: TemporaryAcceptanceStateTypes = INITIAL_STATE,
  action: any
): TemporaryAcceptanceStateTypes => {
  switch (action.type) {
    case TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TemporaryAcceptanceActionTypes.FETCH_ALL_TEMPORARY_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TemporaryAcceptanceActionTypes.FETCH_ONE_TEMPORARY_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TemporaryAcceptanceReducer;
