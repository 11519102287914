import { ApiCallState } from "../Utils";

export type AllureMaterialRequest= {
    id: number;
    project_id:number;
    date:string;
    allure_material_request_views:AllureMaterialRequestView[];
    allure_material_request_approveds:AllureMaterialRequestApproved[];
    allure_material_request_checkeds:AllureMaterialRequestChecked[];
    allure_material_request_items:AllureMaterialRequestItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type AllureMaterialRequestView ={
    id:number;
    allure_material_request_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureMaterialRequestChecked ={
    id:number;
    allure_material_request_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureMaterialRequestApproved ={
    id:number;
    allure_material_request_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureMaterialRequestItem ={
    id:number,
    allure_material_request_id:number;
    material_id:number;
    unit:string;
    request:number;
    approved:number;
    work_specification:string;
    time_to_deliver:string;
    work_volume:string;
    remark:string;
  }

export type AllureMaterialRequestStateTypes = {
  fetchAll: ApiCallState<AllureMaterialRequest[]>;
  fetchOne: ApiCallState<AllureMaterialRequest | {}>;
};

export const AllureMaterialRequestActionTypes = {
  FETCH_ALL_ALLURE_MATERIAL_REQUEST: "FETCH_ALL_ALLURE_MATERIAL_REQUEST",
  FETCH_ALL_ALLURE_MATERIAL_REQUEST_RESET: "FETCH_ALL_ALLURE_MATERIAL_REQUEST_RESET",
  FETCH_ALL_ALLURE_MATERIAL_REQUEST_FAILURE: "FETCH_ALL_ALLURE_MATERIAL_REQUEST_FAILURE",
  FETCH_ALL_ALLURE_MATERIAL_REQUEST_SUCCESS: "FETCH_ALL_ALLURE_MATERIAL_REQUEST_SUCCESS",

  FETCH_ONE_ALLURE_MATERIAL_REQUEST: "FETCH_ONE_ALLURE_MATERIAL_REQUEST",
  FETCH_ONE_ALLURE_MATERIAL_REQUEST_RESET: "FETCH_ONE_ALLURE_MATERIAL_REQUEST_RESET",
  FETCH_ONE_ALLURE_MATERIAL_REQUEST_FAILURE: "FETCH_ONE_ALLURE_MATERIAL_REQUEST_FAILURE",
  FETCH_ONE_ALLURE_MATERIAL_REQUEST_SUCCESS: "FETCH_ONE_ALLURE_MATERIAL_REQUEST_SUCCESS",
};
