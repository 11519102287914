import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractEvaluationActionTypes } from "./SubContractEvaluation.type";

export function* fetchAllSubContractEvaluations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sub-contract-evaluation?project_id=${action.payload?.project_id}`);
    yield put({
      type: SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractEvaluations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-evaluation/${action.payload}`
    );
    yield put({
      type: SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractEvaluations() {
  yield takeLatest(SubContractEvaluationActionTypes.FETCH_ALL_SUB_CONTRACT_EVALUATION, fetchAllSubContractEvaluations);
}

export function* watcherFetchOneSubContractEvaluations() {
  yield takeLatest(SubContractEvaluationActionTypes.FETCH_ONE_SUB_CONTRACT_EVALUATION, fetchOneSubContractEvaluations);
}
