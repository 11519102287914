import { MasterScheduleMachineryStateTypes, MasterScheduleMachineryActionTypes } from "./MasterScheduleMachinery.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MasterScheduleMachineryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MasterScheduleMachineryReducer = (
  state: MasterScheduleMachineryStateTypes = INITIAL_STATE,
  action: any
): MasterScheduleMachineryStateTypes => {
  switch (action.type) {
    case MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleMachineryActionTypes.FETCH_ALL_MASTER_SCHEDULE_MACHINERY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleMachineryActionTypes.FETCH_ONE_MASTER_SCHEDULE_MACHINERY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MasterScheduleMachineryReducer;
