import { ApiCallState } from "../Utils";

export type AllureEquipmentRequest= {
    id: number;
    project_id:number;
    date:string;
    allure_equipment_request_views:AllureEquipmentRequestView[];
    allure_equipment_request_approveds:AllureEquipmentRequestApproved[];
    allure_equipment_request_checkeds:AllureEquipmentRequestChecked[];
    allure_equipment_request_items:AllureEquipmentRequestItem[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type AllureEquipmentRequestView ={
    id:number;
    allure_equipment_request_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureEquipmentRequestChecked ={
    id:number;
    allure_equipment_request_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureEquipmentRequestApproved ={
    id:number;
    allure_equipment_request_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureEquipmentRequestItem ={
    id:number,
    allure_equipment_request_id:number;
    description:string;
    unit:string;
    required:number;
    approved:number;
    day:string;
    from:string;
    to:string;
    work_type:string;
    work_unit:string;
    work_quantity:number;
    remark:string;
  }


export type AllureEquipmentRequestStateTypes = {
  fetchAll: ApiCallState<AllureEquipmentRequest[]>;
  fetchOne: ApiCallState<AllureEquipmentRequest | {}>;
};

export const AllureEquipmentRequestActionTypes = {
  FETCH_ALL_ALLURE_EQUIPMENT_REQUEST: "FETCH_ALL_ALLURE_EQUIPMENT_REQUEST",
  FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_RESET: "FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_RESET",
  FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_FAILURE: "FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_FAILURE",
  FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_SUCCESS: "FETCH_ALL_ALLURE_EQUIPMENT_REQUEST_SUCCESS",

  FETCH_ONE_ALLURE_EQUIPMENT_REQUEST: "FETCH_ONE_ALLURE_EQUIPMENT_REQUEST",
  FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_RESET: "FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_RESET",
  FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_FAILURE: "FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_FAILURE",
  FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_SUCCESS: "FETCH_ONE_ALLURE_EQUIPMENT_REQUEST_SUCCESS",
};
