import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { IndirectCostActionTypes } from "./IndirectCost.type";

export function* fetchAllIndirectCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/indirect-cost`);
    yield put({
      type: IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneIndirectCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/indirect-cost/item?project_id=${action?.payload?.project_id}`
    );
    yield put({
      type: IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllIndirectCosts() {
  yield takeLatest(IndirectCostActionTypes.FETCH_ALL_INDIRECT_COST, fetchAllIndirectCosts);
}

export function* watcherFetchOneIndirectCosts() {
  yield takeLatest(IndirectCostActionTypes.FETCH_ONE_INDIRECT_COST, fetchOneIndirectCosts);
}
