import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureSupportingDocumentActionTypes } from "./AllureSupportingDocument.type";

export function* fetchAllAllureSupportingDocuments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-supporting-document?allure_resource_approval_id=${action.payload?.allure_resource_approval_id}`);
    yield put({
      type: AllureSupportingDocumentActionTypes.FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureSupportingDocumentActionTypes.FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureSupportingDocuments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-supporting-document/${action.payload}`
    );
    yield put({
      type: AllureSupportingDocumentActionTypes.FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureSupportingDocumentActionTypes.FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureSupportingDocuments() {
  yield takeLatest(AllureSupportingDocumentActionTypes.FETCH_ALL_ALLURE_SUPPORTING_DOCUMENT, fetchAllAllureSupportingDocuments);
}

export function* watcherFetchOneAllureSupportingDocuments() {
  yield takeLatest(AllureSupportingDocumentActionTypes.FETCH_ONE_ALLURE_SUPPORTING_DOCUMENT, fetchOneAllureSupportingDocuments);
}
