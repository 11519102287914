import { ApiCallState } from "../Utils";

export type TenderOpening= {
  id: number;
  tender_id:number;
  name_of_contractor:string;
  bid_bond_amount:number;
  bid_bond_type:string;
  service_sum:number;
  rebate_in_per:number;
  rebate_in_birr:number;
  service_sum_after_rebate:number;
  remark:string;
  user_id: number;
};

export type TenderOpeningStateTypes = {
  fetchAll: ApiCallState<TenderOpening[]>;
  fetchOne: ApiCallState<TenderOpening | {}>;
};

export const TenderOpeningActionTypes = {
  FETCH_ALL_TENDER_OPENING: "FETCH_ALL_TENDER_OPENING",
  FETCH_ALL_TENDER_OPENING_RESET: "FETCH_ALL_TENDER_OPENING_RESET",
  FETCH_ALL_TENDER_OPENING_FAILURE: "FETCH_ALL_TENDER_OPENING_FAILURE",
  FETCH_ALL_TENDER_OPENING_SUCCESS: "FETCH_ALL_TENDER_OPENING_SUCCESS",

  FETCH_ONE_TENDER_OPENING: "FETCH_ONE_TENDER_OPENING",
  FETCH_ONE_TENDER_OPENING_RESET: "FETCH_ONE_TENDER_OPENING_RESET",
  FETCH_ONE_TENDER_OPENING_FAILURE: "FETCH_ONE_TENDER_OPENING_FAILURE",
  FETCH_ONE_TENDER_OPENING_SUCCESS: "FETCH_ONE_TENDER_OPENING_SUCCESS",
};
