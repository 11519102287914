import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type MaterialDatabaseItem= {
  id: number;
  is_subtitle: boolean;
  description: string;
  unit: string;
  year:string;
  mdcategory_md:number;
  mdmaterialItemPrices: MaterialDatabaseItemPrice[]
};

type MaterialDatabaseItemPrice = {
    year: number;
    month: number;
    price: number;
    document: Document;
}

export type MaterialDatabaseItemStateTypes = {
  fetchAll: ApiCallState<MaterialDatabaseItem[]>;
  fetchOne: ApiCallState<MaterialDatabaseItem | {}>;
};

export const MaterialDatabaseItemActionTypes = {
  FETCH_ALL_MATERIAL_DATABASE_ITEM: "FETCH_ALL_MATERIAL_DATABASE_ITEM",
  FETCH_ALL_MATERIAL_DATABASE_ITEM_RESET: "FETCH_ALL_MATERIAL_DATABASE_ITEM_RESET",
  FETCH_ALL_MATERIAL_DATABASE_ITEM_FAILURE: "FETCH_ALL_MATERIAL_DATABASE_ITEM_FAILURE",
  FETCH_ALL_MATERIAL_DATABASE_ITEM_SUCCESS: "FETCH_ALL_MATERIAL_DATABASE_ITEM_SUCCESS",

  FETCH_ONE_MATERIAL_DATABASE_ITEM: "FETCH_ONE_MATERIAL_DATABASE_ITEM",
  FETCH_ONE_MATERIAL_DATABASE_ITEM_RESET: "FETCH_ONE_MATERIAL_DATABASE_ITEM_RESET",
  FETCH_ONE_MATERIAL_DATABASE_ITEM_FAILURE: "FETCH_ONE_MATERIAL_DATABASE_ITEM_FAILURE",
  FETCH_ONE_MATERIAL_DATABASE_ITEM_SUCCESS: "FETCH_ONE_MATERIAL_DATABASE_ITEM_SUCCESS",
};
