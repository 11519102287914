import _ from "lodash";
import { Units } from "../../constants/Constants";

class BillSummary {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[1] && col[2] && col[3]) {
          this.parsed.push({
            description: col[1],
            unit: col[2],
            amount:col[3],
            key: this.parsed.length,
          });
        } else if(col[1] && col[2] && _.isEmpty(col[3])){
            this.parsed.push({
                description: col[1],
                unit: "Birr",
                amount:col[2],
                key: this.parsed.length,
              });
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    return (
      _.isString(col[1]) &&
      _.isString(col[2]) 
    );
  }

}
export default BillSummary;
