import { ContractualIssueStateTypes, ContractualIssueActionTypes } from "./ContractualIssue.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ContractualIssueStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ContractualIssueReducer = (
  state: ContractualIssueStateTypes = INITIAL_STATE,
  action: any
): ContractualIssueStateTypes => {
  switch (action.type) {
    case ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ContractualIssueActionTypes.FETCH_ALL_CONTRACTUAL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ContractualIssueActionTypes.FETCH_ONE_CONTRACTUAL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ContractualIssueReducer;
