import { AllureMonthlyPlanStateTypes, AllureMonthlyPlanActionTypes } from "./AllureMonthlyPlan.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AllureMonthlyPlanStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AllureMonthlyPlanReducer = (
  state: AllureMonthlyPlanStateTypes = INITIAL_STATE,
  action: any
): AllureMonthlyPlanStateTypes => {
  switch (action.type) {
    case AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AllureMonthlyPlanReducer;
