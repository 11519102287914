import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllureMonthlyPlanActionTypes } from "./AllureMonthlyPlan.type";

export function* fetchAllAllureMonthlyPlans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-monthly-plan?project_id=${action.payload?.project_id}&date=${action?.payload?.date}`);
    yield put({
      type: AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllureMonthlyPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-monthly-plan/one?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllureMonthlyPlans() {
  yield takeLatest(AllureMonthlyPlanActionTypes.FETCH_ALL_ALLURE_MONTHLY_PLAN, fetchAllAllureMonthlyPlans);
}

export function* watcherFetchOneAllureMonthlyPlans() {
  yield takeLatest(AllureMonthlyPlanActionTypes.FETCH_ONE_ALLURE_MONTHLY_PLAN, fetchOneAllureMonthlyPlans);
}
