import { ApiCallState, PagedData } from "../Utils";

export type Boq = {
  key: number | null;
  project_id: any;
  id: any;
  description: string;
  reference_id: any;
  item_no: string;
  unit: string;
  quantity: number;
  unit_price: number;
  sheet_name: string;
  unit_breakdown?: any;
  is_selected: boolean;
  current_quantity: number;
  previous_quantity: number;
  tender_material_cost: number;
  tender_labour_cost: number;
  tender_equipment_cost: number;
  tender_sub_contract_cost: number;
  tender_direct_cost: number;
  is_non_boq: boolean;
  pre_contract_lump_sum?: PreContractLS;
  sub_contracted: boolean;
  item_group: string;
  offer_id: number;
};

export type PreContractLS = {
  quantity: number;
  unit_price: number;
  overhead_percentage: number;
  profit_percentage: number;
  direct_cost: number;
  id?: number;
};

export type BoqStateTypes = {
  fetchAll: ApiCallState<Boq[]>;
  fetchOne: ApiCallState<Boq | null>;
  fetchDetail: ApiCallState<Boq[]>;
  fetchUnit: ApiCallState<Boq | null>;
  fetchPaged: ApiCallState<PagedData<Boq[]>>;
  fetchSheetName: ApiCallState<String[]>;
  fetchTotal: ApiCallState<{ amount: number }>;
};

export const BoqActionTypes = {
  FETCH_ALL_BOQ: "FETCH_ALL_BOQ",
  FETCH_ALL_BOQ_RESET: "FETCH_ALL_BOQ_RESET",
  FETCH_ALL_BOQ_FAILURE: "FETCH_ALL_BOQ_FAILURE",
  FETCH_ALL_BOQ_SUCCESS: "FETCH_ALL_BOQ_SUCCESS",

  FETCH_ONE_BOQ: "FETCH_ONE_BOQ",
  FETCH_ONE_BOQ_RESET: "FETCH_ONE_BOQ_RESET",
  FETCH_ONE_BOQ_FAILURE: "FETCH_ONE_BOQ_FAILURE",
  FETCH_ONE_BOQ_SUCCESS: "FETCH_ONE_BOQ_SUCCESS",

  FETCH_DETAIL_BOQ: "FETCH_DETAIL_BOQ",
  FETCH_DETAIL_BOQ_RESET: "FETCH_DETAIL_BOQ_RESET",
  FETCH_DETAIL_BOQ_FAILURE: "FETCH_DETAIL_BOQ_FAILURE",
  FETCH_DETAIL_BOQ_SUCCESS: "FETCH_DETAIL_BOQ_SUCCESS",

  FETCH_UNIT_BOQ: "FETCH_UNIT_BOQ",
  FETCH_UNIT_BOQ_RESET: "FETCH_UNIT_BOQ_RESET",
  FETCH_UNIT_BOQ_FAILURE: "FETCH_UNIT_BOQ_FAILURE",
  FETCH_UNIT_BOQ_SUCCESS: "FETCH_UNIT_BOQ_SUCCESS",

  FETCH_SHEET_NAME: "FETCH_SHEET_NAME",
  FETCH_SHEET_NAME_RESET: "FETCH_SHEET_NAME_RESET",
  FETCH_SHEET_NAME_FAILURE: "FETCH_SHEET_NAME_FAILURE",
  FETCH_SHEET_NAME_SUCCESS: "FETCH_SHEET_NAME_SUCCESS",

  FETCH_BOQ_TOTAL: "FETCH_BOQ_TOTAL",
  FETCH_BOQ_TOTAL_RESET: "FETCH_BOQ_TOTAL_RESET",
  FETCH_BOQ_TOTAL_FAILURE: "FETCH_BOQ_TOTAL_FAILURE",
  FETCH_BOQ_TOTAL_SUCCESS: "FETCH_BOQ_TOTAL_SUCCESS",

  FETCH_PAGED_BOQ: "FETCH_PAGED_BOQ",
  FETCH_PAGED_BOQ_RESET: "FETCH_PAGED_BOQ_RESET",
  FETCH_PAGED_BOQ_FAILURE: "FETCH_PAGED_BOQ_FAILURE",
  FETCH_PAGED_BOQ_SUCCESS: "FETCH_PAGED_BOQ_SUCCESS",
};
