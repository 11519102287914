import { ApiCallState } from "../Utils";
import { Moment } from "moment";
import { BudgetRequestItem } from "../BudgetRequest/BudgetRequest.type";

export type BudgetUtilization= {
    project_id: number;
    id: number;
    date: Moment;
    budget_month:string;
    budget_utilization_items: BudgetUtilizationItem[];
    budget_utilization_views:BudgetUtilizationView[];
    budget_utilization_approveds:BudgetUtilizationApproved[];
    budget_utilization_checkeds:BudgetUtilizationChecked[];
    user_id:number;
    createdAt: any;
    updatedAt: any;
};

export type  BudgetUtilizationItem = {
    id: number;
    budget_utilization_id:number;
    budget_request_id:number;
    budget_request_item_id:number;
    budget_request_item:BudgetRequestItem;
    resource: string;
    type: string;
    unit: string;
    utilized_quantity:number;
    justification:string;
    actual_unit_rate:number;
    actual_no_of_days_elapsed: number;
    createdAt: any;
    updatedAt: any;
}

export type BudgetUtilizationChecked ={
  id:number;
  budget_request_id: number;
  user_id: number;
  sender_id:number;
  is_checked:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type BudgetUtilizationApproved ={
  id:number;
  budget_request_id:number;
  user_id: number;
  sender_id:number;
  is_approved:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type BudgetUtilizationView ={
  id:number;
  budget_request_id:number;
  user_id: number;
  sender_id:number;
  createdAt: any;
  updatedAt: any;
}

export type BudgetUtilizationStateTypes = {
  fetchAll: ApiCallState<BudgetUtilization[]>;
  fetchOne: ApiCallState<BudgetUtilization | {}>;
};

export const BudgetUtilizationActionTypes = {
  FETCH_ALL_BUDGET_UTILIZATION: "FETCH_ALL_BUDGET_UTILIZATION",
  FETCH_ALL_BUDGET_UTILIZATION_RESET: "FETCH_ALL_BUDGET_UTILIZATION_RESET",
  FETCH_ALL_BUDGET_UTILIZATION_FAILURE: "FETCH_ALL_BUDGET_UTILIZATION_FAILURE",
  FETCH_ALL_BUDGET_UTILIZATION_SUCCESS: "FETCH_ALL_BUDGET_UTILIZATION_SUCCESS",

  FETCH_ONE_BUDGET_UTILIZATION: "FETCH_ONE_BUDGET_UTILIZATION",
  FETCH_ONE_BUDGET_UTILIZATION_RESET: "FETCH_ONE_BUDGET_UTILIZATION_RESET",
  FETCH_ONE_BUDGET_UTILIZATION_FAILURE: "FETCH_ONE_BUDGET_UTILIZATION_FAILURE",
  FETCH_ONE_BUDGET_UTILIZATION_SUCCESS: "FETCH_ONE_BUDGET_UTILIZATION_SUCCESS",
};
