import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MDMaterialActionTypes } from "./MDMaterial.type";

export function* fetchAllMDMaterials(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/md-material?material=${action.payload?.material}&year=${action.payload?.year}`);
    yield put({
      type: MDMaterialActionTypes.FETCH_ALL_MDMATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MDMaterialActionTypes.FETCH_ALL_MDMATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMDMaterials(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/md-material/${action.payload}`
    );
    yield put({
      type: MDMaterialActionTypes.FETCH_ONE_MDMATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MDMaterialActionTypes.FETCH_ONE_MDMATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMDMaterials() {
  yield takeLatest(MDMaterialActionTypes.FETCH_ALL_MDMATERIAL, fetchAllMDMaterials);
}

export function* watcherFetchOneMDMaterials() {
  yield takeLatest(MDMaterialActionTypes.FETCH_ONE_MDMATERIAL, fetchOneMDMaterials);
}
