import { ApiCallState } from "../Utils";

export type AllureMonthlyPlan= {
  id: number;
  project_id:number;
  date:string;
  allure_monthly_plan_views:AllureMonthlyPlanView[];
  allure_monthly_plan_approveds:AllureMonthlyPlanApproved[];
  allure_monthly_plan_checkeds:AllureMonthlyPlanChecked[];
  allure_monthly_plan_items:AllureMonthlyPlanItem[];
  user_id:number;
  createdAt: any;
  updatedAt: any;
};

export type AllureMonthlyPlanView ={
    id:number;
    allure_monthly_plan_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureMonthlyPlanChecked ={
    id:number;
    allure_monthly_plan_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureMonthlyPlanApproved ={
    id:number;
    allure_monthly_plan_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type AllureMonthlyPlanItem ={
    id:number,
    allure_monthly_plan_id:number;
    item_no:string;
    description: string,
    unit: string,
    quantity: number,
    unit_price: number,
    week_1:number,
    week_2:number,
    week_3:number,
    week_4:number,
    createdAt: any,
    updatedAt: any
  }

export type AllureMonthlyPlanStateTypes = {
  fetchAll: ApiCallState<AllureMonthlyPlan[]>;
  fetchOne: ApiCallState<AllureMonthlyPlan | {}>;
};

export const AllureMonthlyPlanActionTypes = {
  FETCH_ALL_ALLURE_MONTHLY_PLAN: "FETCH_ALL_ALLURE_MONTHLY_PLAN",
  FETCH_ALL_ALLURE_MONTHLY_PLAN_RESET: "FETCH_ALL_ALLURE_MONTHLY_PLAN_RESET",
  FETCH_ALL_ALLURE_MONTHLY_PLAN_FAILURE: "FETCH_ALL_ALLURE_MONTHLY_PLAN_FAILURE",
  FETCH_ALL_ALLURE_MONTHLY_PLAN_SUCCESS: "FETCH_ALL_ALLURE_MONTHLY_PLAN_SUCCESS",

  FETCH_ONE_ALLURE_MONTHLY_PLAN: "FETCH_ONE_ALLURE_MONTHLY_PLAN",
  FETCH_ONE_ALLURE_MONTHLY_PLAN_RESET: "FETCH_ONE_ALLURE_MONTHLY_PLAN_RESET",
  FETCH_ONE_ALLURE_MONTHLY_PLAN_FAILURE: "FETCH_ONE_ALLURE_MONTHLY_PLAN_FAILURE",
  FETCH_ONE_ALLURE_MONTHLY_PLAN_SUCCESS: "FETCH_ONE_ALLURE_MONTHLY_PLAN_SUCCESS",
};
