import { InitPagedData } from "./../Utils";
import { BoqStateTypes, BoqActionTypes } from "./Boq.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BoqStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchDetail: resetApiCallState([]),
  fetchUnit: resetApiCallState([]),
  fetchPaged: resetApiCallState([]),
  fetchSheetName: resetApiCallState([]),
  fetchTotal: resetApiCallState({ amount: 0 }),
};

const BoqReducer = (
  state: BoqStateTypes = INITIAL_STATE,
  action: any
): BoqStateTypes => {
  switch (action.type) {
    case BoqActionTypes.FETCH_ALL_BOQ:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_ALL_BOQ_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BoqActionTypes.FETCH_ALL_BOQ_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_ALL_BOQ_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqActionTypes.FETCH_ONE_BOQ:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_ONE_BOQ_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case BoqActionTypes.FETCH_ONE_BOQ_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_ONE_BOQ_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqActionTypes.FETCH_DETAIL_BOQ:
      return {
        ...state,
        fetchDetail: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_DETAIL_BOQ_RESET:
      return {
        ...state,
        fetchDetail: resetApiCallState([]),
      };
    case BoqActionTypes.FETCH_DETAIL_BOQ_FAILURE:
      return {
        ...state,
        fetchDetail: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_DETAIL_BOQ_SUCCESS:
      return {
        ...state,
        fetchDetail: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqActionTypes.FETCH_UNIT_BOQ:
      return {
        ...state,
        fetchUnit: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_UNIT_BOQ_RESET:
      return {
        ...state,
        fetchUnit: resetApiCallState([]),
      };
    case BoqActionTypes.FETCH_UNIT_BOQ_FAILURE:
      return {
        ...state,
        fetchUnit: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_UNIT_BOQ_SUCCESS:
      return {
        ...state,
        fetchUnit: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqActionTypes.FETCH_SHEET_NAME:
      return {
        ...state,
        fetchSheetName: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_SHEET_NAME_RESET:
      return {
        ...state,
        fetchSheetName: resetApiCallState([]),
      };
    case BoqActionTypes.FETCH_SHEET_NAME_FAILURE:
      return {
        ...state,
        fetchSheetName: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_SHEET_NAME_SUCCESS:
      return {
        ...state,
        fetchSheetName: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqActionTypes.FETCH_BOQ_TOTAL:
      return {
        ...state,
        fetchTotal: {
          error: null,
          payload: { amount: 0 },
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_BOQ_TOTAL_RESET:
      return {
        ...state,
        fetchSheetName: resetApiCallState(0),
      };
    case BoqActionTypes.FETCH_BOQ_TOTAL_FAILURE:
      return {
        ...state,
        fetchTotal: {
          payload: { amount: 0 },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_BOQ_TOTAL_SUCCESS:
      return {
        ...state,
        fetchTotal: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqActionTypes.FETCH_PAGED_BOQ:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqActionTypes.FETCH_PAGED_BOQ_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState([]),
      };
    case BoqActionTypes.FETCH_PAGED_BOQ_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqActionTypes.FETCH_PAGED_BOQ_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BoqReducer;
