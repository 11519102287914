import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialConsumptionReportActionTypes } from "./MaterialConsumptionReport.type";

export function* fetchAllMaterialConsumptionReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-consumption-report?project_id=${action.payload?.project_id}`);
    yield put({
      type: MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialConsumptionReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-consumption-report/${action.payload}`
    );
    yield put({
      type: MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialConsumptionReports() {
  yield takeLatest(MaterialConsumptionReportActionTypes.FETCH_ALL_MATERIAL_CONSUMPTION_REPORT, fetchAllMaterialConsumptionReports);
}

export function* watcherFetchOneMaterialConsumptionReports() {
  yield takeLatest(MaterialConsumptionReportActionTypes.FETCH_ONE_MATERIAL_CONSUMPTION_REPORT, fetchOneMaterialConsumptionReports);
}
