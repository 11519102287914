import { SiteVisitScheduleStateTypes, SiteVisitScheduleActionTypes } from "./SiteVisitSchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SiteVisitScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SiteVisitScheduleReducer = (
  state: SiteVisitScheduleStateTypes = INITIAL_STATE,
  action: any
): SiteVisitScheduleStateTypes => {
  switch (action.type) {
    case SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteVisitScheduleActionTypes.FETCH_ALL_SITE_VISIT_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteVisitScheduleActionTypes.FETCH_ONE_SITE_VISIT_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SiteVisitScheduleReducer;
