import { isString } from "lodash";

class BuildingMasterScheduleManpower {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    let month_data: any[] = [];
    // Iterate Through Each Row
    this.data.forEach((col: any[], index) => {
      //Check if its The Start of the Excel File and set the index of the Start Row
      if (this.isTableState(col)) {
        start = index;
        month_data.push(
          { month: col[3] },
          { month: col[4] },
          { month: col[5] },
          { month: col[6] },
          { month: col[7] }
        );
      }
      // If its the Start of Excel Data
      else if (start !== -1) {
        // Identify the Row Type
        if (col[1]) {
          if (col[3]) {
            this.parsed.push({
              key: this.parsed.length,
              man_power: col[1],
              max_qty:col[2],
              month: month_data[0].month,
              month_value: col[3],
              is_super_title: false,
            });
          }
          if (col[4]) {
            this.parsed.push({
              key: this.parsed.length,
              man_power: col[1],
              max_qty:col[2],
              month: month_data[1].month,
              month_value: col[4],
              is_super_title: false,
            });
          }
          if (col[5]) {
            this.parsed.push({
              key: this.parsed.length,
              man_power: col[1],
              max_qty:col[2],
              month: month_data[2].month,
              month_value: col[5],
              is_super_title: false,
            });
          }
          if (col[6]) {
            this.parsed.push({
              key: this.parsed.length,
              man_power: col[1],
              max_qty:col[2],
              month: month_data[3].month,
              month_value: col[6],
              is_super_title: false,
            });
          }
          if (col[7]) {
            this.parsed.push({
              key: this.parsed.length,
              man_power: col[1],
              max_qty:col[2],
              month: month_data[4].month,
              month_value: col[7],
              is_super_title: false,
            });
          }

          if (
            (
              isString(col[1]) &&
              this.isSuperTitle(col)
            )
          ) {
            this.parsed.push({
              key: this.parsed.length,
              man_power: col[1],
              is_super_title: true,
            });
          }
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    //check if col 7 to col 11 is a string
    return (
      isString(col[1]) &&
      isString(col[2]) &&
      isString(col[3]) &&
      isString(col[4]) &&
      isString(col[5]) &&
      isString(col[6]) &&
      isString(col[7]) 
    );
  }

  private isSuperTitle(col: any[]) {
    if (col[1]) {
      let split = col[1].trim().split(")")[1];
      if (split && split.length >= 1) {
        return true;
      } else return false;
    } else return false;
  }
}
export default BuildingMasterScheduleManpower;
