import { Boq } from "../Boq/Boq.type";
import { FinanceDailyReportItem, ReportMaterialItem } from "../FinanceDailyReport/FinanceDailyReport.type";
import { ApiCallState } from "../Utils";

export type MaterialConsumption= {
  id: number;
  project_id:number;
  week_start:string;
  week_end:string;
  material_consumption_views:MaterialConsumptionView[];
  material_consumption_approveds:MaterialConsumptionApproved[];
  material_consumption_checkeds:MaterialConsumptionChecked[];
  material_consumption_items:MaterialConsumptionItem[];
  user_id:number;
  createdAt: any;
  updatedAt: any;
};

export type MaterialConsumptionView ={
  id:number;
  material_consumption_id:number;
  user_id: number;
  sender_id:number;
  createdAt: any;
  updatedAt: any;
}

export type MaterialConsumptionChecked ={
  id:number;
  material_consumption_id:number;
  user_id: number;
  sender_id:number;
  is_checked:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type MaterialConsumptionApproved ={
  id:number;
  material_consumption_id:number;
  user_id: number;
  sender_id:number;
  is_approved:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type MaterialConsumptionItem ={
  id:number,
  material_consumption_id:number;
  report_material_item_id:number;
  finance_daily_report_item_id:number;
  is_title:boolean;
  boq_id: number;
  report_material_item: ReportMaterialItem;
  boq?: Boq;
  finance_daily_report_item?:FinanceDailyReportItem;
  standard_consumption:number;
  quantity_performed: number,
  remark:string;
}

export type MaterialConsumptionStateTypes = {
  fetchAll: ApiCallState<MaterialConsumption[]>;
  fetchOne: ApiCallState<MaterialConsumption | {}>;
};

export const MaterialConsumptionActionTypes = {
  FETCH_ALL_MATERIAL_CONSUMPTION: "FETCH_ALL_MATERIAL_CONSUMPTION",
  FETCH_ALL_MATERIAL_CONSUMPTION_RESET: "FETCH_ALL_MATERIAL_CONSUMPTION_RESET",
  FETCH_ALL_MATERIAL_CONSUMPTION_FAILURE: "FETCH_ALL_MATERIAL_CONSUMPTION_FAILURE",
  FETCH_ALL_MATERIAL_CONSUMPTION_SUCCESS: "FETCH_ALL_MATERIAL_CONSUMPTION_SUCCESS",

  FETCH_ONE_MATERIAL_CONSUMPTION: "FETCH_ONE_MATERIAL_CONSUMPTION",
  FETCH_ONE_MATERIAL_CONSUMPTION_RESET: "FETCH_ONE_MATERIAL_CONSUMPTION_RESET",
  FETCH_ONE_MATERIAL_CONSUMPTION_FAILURE: "FETCH_ONE_MATERIAL_CONSUMPTION_FAILURE",
  FETCH_ONE_MATERIAL_CONSUMPTION_SUCCESS: "FETCH_ONE_MATERIAL_CONSUMPTION_SUCCESS",
};
