import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TenderSubmissionChecklistActionTypes } from "./TenderSubmissionChecklist.type";

export function* fetchAllTenderSubmissionChecklists(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/tender-submission-checklist?tender_id=${action.payload?.tender_id}`);
    yield put({
      type: TenderSubmissionChecklistActionTypes.FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TenderSubmissionChecklistActionTypes.FETCH_ALL_TENDER_SUBMISSION_CHECKLIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTenderSubmissionChecklists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/tender-submission-checklist/${action.payload}`
    );
    yield put({
      type: TenderSubmissionChecklistActionTypes.FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TenderSubmissionChecklistActionTypes.FETCH_ONE_TENDER_SUBMISSION_CHECKLIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTenderSubmissionChecklists() {
  yield takeLatest(TenderSubmissionChecklistActionTypes.FETCH_ALL_TENDER_SUBMISSION_CHECKLIST, fetchAllTenderSubmissionChecklists);
}

export function* watcherFetchOneTenderSubmissionChecklists() {
  yield takeLatest(TenderSubmissionChecklistActionTypes.FETCH_ONE_TENDER_SUBMISSION_CHECKLIST, fetchOneTenderSubmissionChecklists);
}
