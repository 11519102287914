import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllurePaymentSummaryActionTypes } from "./AllurePaymentSummary.type";

export function* fetchAllAllurePaymentSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allure-payment-summary?project_id=${action.payload?.project_id}`);
    yield put({
      type: AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllurePaymentSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allure-payment-summary/${action.payload}`
    );
    yield put({
      type: AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllurePaymentSummarys() {
  yield takeLatest(AllurePaymentSummaryActionTypes.FETCH_ALL_ALLURE_PAYMENT_SUMMARY, fetchAllAllurePaymentSummarys);
}

export function* watcherFetchOneAllurePaymentSummarys() {
  yield takeLatest(AllurePaymentSummaryActionTypes.FETCH_ONE_ALLURE_PAYMENT_SUMMARY, fetchOneAllurePaymentSummarys);
}
