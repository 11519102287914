import { ApiCallState } from "../Utils";

export type ContractualIssue= {
  id: number;
  project_id:number;
  date:string;
  contractual_issue_views:ContractualIssueView[];
  contractual_issue_approveds:ContractualIssueApproved[];
  contractual_issue_checkeds:ContractualIssueChecked[];
  contractual_issue_items:ContractualIssueItem[];
  user_id:number;
  createdAt: any;
  updatedAt: any;
};

export type ContractualIssueView ={
    id:number;
    contractual_issue_id:number;
    user_id: number;
    sender_id:number;
    createdAt: any;
    updatedAt: any;
  }
  
  export type ContractualIssueChecked ={
    id:number;
    contractual_issue_id:number;
    user_id: number;
    sender_id:number;
    is_checked:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type ContractualIssueApproved ={
    id:number;
    contractual_issue_id:number;
    user_id: number;
    sender_id:number;
    is_approved:boolean;
    is_revised:boolean;
    createdAt: any;
    updatedAt: any;
  }
  
  export type ContractualIssueItem ={
    id:number,
    contractual_issue_id:number;
    description_of_problem:string;
    area_of_problem:string;
    measures_taken:string;
    recommended_solution:string;
    createdAt: any;
    updatedAt: any;
  }

export type ContractualIssueStateTypes = {
  fetchAll: ApiCallState<ContractualIssue[]>;
  fetchOne: ApiCallState<ContractualIssue | {}>;
};

export const ContractualIssueActionTypes = {
  FETCH_ALL_CONTRACTUAL_ISSUE: "FETCH_ALL_CONTRACTUAL_ISSUE",
  FETCH_ALL_CONTRACTUAL_ISSUE_RESET: "FETCH_ALL_CONTRACTUAL_ISSUE_RESET",
  FETCH_ALL_CONTRACTUAL_ISSUE_FAILURE: "FETCH_ALL_CONTRACTUAL_ISSUE_FAILURE",
  FETCH_ALL_CONTRACTUAL_ISSUE_SUCCESS: "FETCH_ALL_CONTRACTUAL_ISSUE_SUCCESS",

  FETCH_ONE_CONTRACTUAL_ISSUE: "FETCH_ONE_CONTRACTUAL_ISSUE",
  FETCH_ONE_CONTRACTUAL_ISSUE_RESET: "FETCH_ONE_CONTRACTUAL_ISSUE_RESET",
  FETCH_ONE_CONTRACTUAL_ISSUE_FAILURE: "FETCH_ONE_CONTRACTUAL_ISSUE_FAILURE",
  FETCH_ONE_CONTRACTUAL_ISSUE_SUCCESS: "FETCH_ONE_CONTRACTUAL_ISSUE_SUCCESS",
};
