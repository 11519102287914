import { MDMaterialItem } from "../MDMaterial/MDMaterial.type";
import { ApiCallState } from "../Utils";

export type MaterialCatagory = {
  id?: number;
  name: string;
  mdmaterial_items: MDMaterialItem[];
  key: number;
};

export type MaterialCatagoryStateTypes = {
  fetchAll: ApiCallState<MaterialCatagory[]>;
  fetchOne: ApiCallState<MaterialCatagory | {}>;
};

export const MaterialCatagoryActionTypes = {
  FETCH_ALL_MATERIAL_CATAGORY: "FETCH_ALL_MATERIAL_CATAGORY",
  FETCH_ALL_MATERIAL_CATAGORY_RESET: "FETCH_ALL_MATERIAL_CATAGORY_RESET",
  FETCH_ALL_MATERIAL_CATAGORY_FAILURE: "FETCH_ALL_MATERIAL_CATAGORY_FAILURE",
  FETCH_ALL_MATERIAL_CATAGORY_SUCCESS: "FETCH_ALL_MATERIAL_CATAGORY_SUCCESS",

  FETCH_ONE_MATERIAL_CATAGORY: "FETCH_ONE_MATERIAL_CATAGORY",
  FETCH_ONE_MATERIAL_CATAGORY_RESET: "FETCH_ONE_MATERIAL_CATAGORY_RESET",
  FETCH_ONE_MATERIAL_CATAGORY_FAILURE: "FETCH_ONE_MATERIAL_CATAGORY_FAILURE",
  FETCH_ONE_MATERIAL_CATAGORY_SUCCESS: "FETCH_ONE_MATERIAL_CATAGORY_SUCCESS",
};
