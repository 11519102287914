import { ApiCallState } from "../Utils";

export type FurnitureMasterList = {
  id: number;
  user_id: number;

  location: string;

  furniture_type: string;
  plate_number: string;
  make: string;
  model: string;

  capacity: string;
  manufacture_year: string;
  purchase_year: string;
  purchase_price: number;
  purchase_currency: string;
  depreciation_percent: number;
  status: string;
  employee_name: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type FurnitureMasterListStateTypes = {
  fetchAll: ApiCallState<FurnitureMasterList[]>;
  fetchOne: ApiCallState<FurnitureMasterList | {}>;
};

export const FurnitureMasterListActionTypes = {
  FETCH_ALL_FURNITURE_MASTER_LIST: "FETCH_ALL_FURNITURE_MASTER_LIST",
  FETCH_ALL_FURNITURE_MASTER_LIST_RESET:
    "FETCH_ALL_FURNITURE_MASTER_LIST_RESET",
  FETCH_ALL_FURNITURE_MASTER_LIST_FAILURE:
    "FETCH_ALL_FURNITURE_MASTER_LIST_FAILURE",
  FETCH_ALL_FURNITURE_MASTER_LIST_SUCCESS:
    "FETCH_ALL_FURNITURE_MASTER_LIST_SUCCESS",

  FETCH_ONE_FURNITURE_MASTER_LIST: "FETCH_ONE_FURNITURE_MASTER_LIST",
  FETCH_ONE_FURNITURE_MASTER_LIST_RESET:
    "FETCH_ONE_FURNITURE_MASTER_LIST_RESET",
  FETCH_ONE_FURNITURE_MASTER_LIST_FAILURE:
    "FETCH_ONE_FURNITURE_MASTER_LIST_FAILURE",
  FETCH_ONE_FURNITURE_MASTER_LIST_SUCCESS:
    "FETCH_ONE_FURNITURE_MASTER_LIST_SUCCESS",
};
