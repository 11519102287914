import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ResourceMobilizationActionTypes } from "./ResourceMobilization.type";

export function* fetchAllResourceMobilizations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/resource-mobilization?project_id=${action.payload?.project_id}`);
    yield put({
      type: ResourceMobilizationActionTypes.FETCH_ALL_RESOURCE_MOBILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ResourceMobilizationActionTypes.FETCH_ALL_RESOURCE_MOBILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneResourceMobilizations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/resource-mobilization/${action.payload}`
    );
    yield put({
      type: ResourceMobilizationActionTypes.FETCH_ONE_RESOURCE_MOBILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ResourceMobilizationActionTypes.FETCH_ONE_RESOURCE_MOBILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllResourceMobilizations() {
  yield takeLatest(ResourceMobilizationActionTypes.FETCH_ALL_RESOURCE_MOBILIZATION, fetchAllResourceMobilizations);
}

export function* watcherFetchOneResourceMobilizations() {
  yield takeLatest(ResourceMobilizationActionTypes.FETCH_ONE_RESOURCE_MOBILIZATION, fetchOneResourceMobilizations);
}
