import { ApiCallState } from "../Utils";

export type MasterScheduleManpower= {
  id: number;
  master_schedule_updated_id:number;
  man_power:string;
  max_qty:number;
  month:string;
  month_value:number;
  is_title:boolean;
  is_super_title:boolean;
  user_id: number;
};

export type MasterScheduleManpowerStateTypes = {
  fetchAll: ApiCallState<MasterScheduleManpower[]>;
  fetchOne: ApiCallState<MasterScheduleManpower | {}>;
};

export const MasterScheduleManpowerActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_MANPOWER: "FETCH_ALL_MASTER_SCHEDULE_MANPOWER",
  FETCH_ALL_MASTER_SCHEDULE_MANPOWER_RESET: "FETCH_ALL_MASTER_SCHEDULE_MANPOWER_RESET",
  FETCH_ALL_MASTER_SCHEDULE_MANPOWER_FAILURE: "FETCH_ALL_MASTER_SCHEDULE_MANPOWER_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_MANPOWER_SUCCESS: "FETCH_ALL_MASTER_SCHEDULE_MANPOWER_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_MANPOWER: "FETCH_ONE_MASTER_SCHEDULE_MANPOWER",
  FETCH_ONE_MASTER_SCHEDULE_MANPOWER_RESET: "FETCH_ONE_MASTER_SCHEDULE_MANPOWER_RESET",
  FETCH_ONE_MASTER_SCHEDULE_MANPOWER_FAILURE: "FETCH_ONE_MASTER_SCHEDULE_MANPOWER_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_MANPOWER_SUCCESS: "FETCH_ONE_MASTER_SCHEDULE_MANPOWER_SUCCESS",
};
