import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterScheduleCashflowActionTypes } from "./MasterScheduleCashflow.type";

export function* fetchAllMasterScheduleCashflows(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-cashflow?master_schedule_updated_id=${action.payload?.master_schedule_updated_id}`
    );
    yield put({
      type: MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterScheduleCashflows(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-cashflow/${action.payload}`
    );
    yield put({
      type: MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterScheduleCashflows() {
  yield takeLatest(
    MasterScheduleCashflowActionTypes.FETCH_ALL_MASTER_SCHEDULE_CASHFLOW,
    fetchAllMasterScheduleCashflows
  );
}

export function* watcherFetchOneMasterScheduleCashflows() {
  yield takeLatest(
    MasterScheduleCashflowActionTypes.FETCH_ONE_MASTER_SCHEDULE_CASHFLOW,
    fetchOneMasterScheduleCashflows
  );
}
